import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import DefaultLayout from "../../components/layout/DefaultLayout";
import Flex from "../../components/layout/Flex";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import Button from "../../components/button/Button";
import moment from "moment";
import * as APIS from "../../libs/apis";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { useDispatch } from "react-redux";
import { getter } from "@progress/kendo-react-common";
import esMessages from "../../libs/es.json";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { numberWithCommas } from "../../libs/utils";
import MemberManagementDetail from "../member-management/MemberManagementDetail";
import { formatTime } from "../../libs/utils";
import { saveAs } from "file-saver";

const DETAIL_ID = "userId";
const DATA_ITEM_KEY = "requestAt";
const userIdGetter = getter(DETAIL_ID);
const idGetter = getter(DATA_ITEM_KEY);
loadMessages(esMessages, "es-KR");

const assetCheckBox = [
  { label: "금", key: "GOLD" },
  { label: "은", key: "SILVER" },
  { label: "KRW", key: "KRW" },
  { label: "포인트", key: "POINT" },
];

const tradeCheckBox = [
  { label: "매수", key: "BUY" },
  { label: "매도", key: "SELL" },
  { label: "감정평가", key: "APPRAISAL" },
  { label: "실물인출", key: "GOODS" },
  { label: "안심 구매", key: "SAFE_TRADE_BUY" },
  { label: "안심 판매", key: "SAFE_TRADE_SELL" },
  // { label: "이벤트", key: "EVENT" },
];

const INIT_PAGE = {
  page: 0,
  size: 30,
  totalElements: 0,
};

const INIT = {
  userKeyword: "",
  from: moment().subtract(7, "d").format("YYYY-MM-DD"),
  to: moment(new Date()).format("YYYY-MM-DD"),
  assetType: [],
  tradeType: [],
  sort: "DESC",
};

const Wrap = styled.div`
  min-width: 1112px;
  max-width: 1740px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1050px;

  .k-grid .k-grid-container {
    height: 50px;
  }
`;

const Transaction = () => {
  const [condition, setCondition] = useState({
    userKeyword: "",
    from: moment().subtract(7, "d").format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    assetType: [],
    tradeType: [],
    sort: "DESC",
  });
  const [data, setData] = useState([]);
  const [isCheck, setCheck] = useState(false);
  const [page, setPage] = useState({ ...INIT_PAGE });
  const [selectedState, setSelectedState] = useState({});
  const [detailComponent, setDetailComponent] = useState(null);
  const [detailId, setDetailId] = useState("");

  const dispatch = useDispatch();

  const onChangeValue = (e) => {
    const { value, name } = e.target;
    setCondition({
      ...condition,
      [name]: value,
    });
  };

  const onChangeSort = (e) => {
    const { value } = e;
    setCondition({
      ...condition,
      sort: value,
    });
    if (isCheck) {
      fetchList("", value);
    }
  };

  const fetchList = (pageData, sort = "DESC") => {
    const parseToQuery = (data) => {
      let query = "";
      let key = Object.keys(data);
      key = key.filter((item) => data[item] !== "" || data[item].length > 0);
      key.forEach((item) => {
        if (Array.isArray(data[item]) && data[item].length > 0) {
          if (
            item === "tradeType" &&
            condition.tradeType.length < tradeCheckBox.length
          ) {
            condition.tradeType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          } else if (
            item === "assetType" &&
            condition.assetType.length < assetCheckBox.length
          ) {
            condition.assetType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          }
        } else {
          if (item === "sort") {
            query += `${item}=${sort}&`;
          } else {
            if (data[item] !== "ALL" && !Array.isArray(data[item])) {
              if (item !== "marketName") {
                query += `${item}=${data[item]}&`;
              }
            }
          }
        }
      });

      if (query.endsWith("&")) {
        query = query.substring(0, query.lastIndexOf("&"));
      }
      return `?${query}`;
    };

    const parsePage = pageData
      ? pageData
      : {
          page: page.page,
          size: page.size,
        };
    dispatch(showLoading());

    const queryString = parseToQuery({ ...condition, ...parsePage });
    APIS.getTransactionHistory(queryString)
      .then((res) => {
        const { data } = res;
        const { success, data: historyData } = data;
        const { content, totalElements, number, size } = historyData;
        if (success) {
          setData(content || []);
          setPage({
            ...page,
            totalElements,
            page: number,
            size: size,
          });
        } else {
          dispatch(hideLoading());
        }
      })
      .catch((err) => {
        dispatch(
          showAlert({
            message: err.response.data.message,
          })
        );
      })
      .finally(() => dispatch(hideLoading()));
  };
  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "completedAt" ||
      props.field === "userPhoneNumber" ||
      props.field === "tradeType"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          className="left k-grid-content-sticky"
          style={{ textAlign: "-webkit-center" }}
        >
          {props.dataItem[props.field] || "-"}
        </td>
      );
    } else if (
      props.field === "pricePerGram" ||
      props.field === "pureTradeKrw" ||
      props.field === "userFee" ||
      props.field === "totalPrice" ||
      props.field === "tradeGram" ||
      props.field === "afterKrw" ||
      props.field === "afterPoint" ||
      props.field === "afterGold" ||
      props.field === "afterSilver" ||
      props.field === "recommendShopFee" ||
      props.field === "usingShopFee" ||
      props.field === "outShopFee"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
        >
          <div style={{ float: "right" }}>
            {numberWithCommas(props.dataItem[props.field])}
          </div>
        </td>
      );
    } else if (props.field === "userId" || props.field === "userName") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
        >
          <div
            style={{
              textAlign: "center",
              cursor: "default",
            }}
            // onClick={() => {
            //   handleClickDetail();
            // }}
          >
            {props.dataItem[props.field] || "-"}
          </div>
        </td>
      );
    } else if (props.field === "assetType") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
        >
          <div style={{ textAlign: "center", cursor: "default" }}>
            {props.dataItem[props.field]
              .replace("GOLD", "금")
              .replace("SILVER", "은") || "-"}
          </div>
        </td>
      );
    }
    return cell;
  };

  const onClickDownload = () => {
    const parseToQuery = (data) => {
      let query = "";
      let key = Object.keys(data);
      key = key.filter((item) => data[item] !== "" || data[item].length > 0);
      key.forEach((item) => {
        if (Array.isArray(data[item]) && data[item].length > 0) {
          if (
            item === "tradeType" &&
            condition.tradeType.length < tradeCheckBox.length
          ) {
            condition.tradeType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          } else if (
            item === "assetType" &&
            condition.assetType.length < assetCheckBox.length
          ) {
            condition.assetType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          }
        } else {
          if (item === "sort") {
            query += `${item}=${data[item] ? data[item] : condition.sort}&`;
          } else {
            if (data[item] !== "ALL" && !Array.isArray(data[item])) {
              if (item !== "marketName") {
                query += `${item}=${data[item]}&`;
              }
            }
          }
        }
      });
      if (query.endsWith("&")) {
        query = query.substring(0, query.lastIndexOf("&"));
      }
      return `?${query}`;
    };
    dispatch(showLoading());
    const queryString = parseToQuery({ ...condition });
    APIS.getIntegrationHistoryDownload(queryString)
      .then((res) => {
        const { data } = res;
        const blob = new Blob([data]);
        saveAs(
          blob,
          `통합거래내역_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };

  // const handleClickDetail = () => {
  //   setDetailComponent(() => {
  //     return null;
  //   });

  //   setDetailComponent(() => {
  //     return <MemberManagementDetail id={detailId} />;
  //   });
  // };

  const clearDetail = () => {
    setDetailComponent(null);
  };

  const onClickSearch = () => {
    if (!isCheck) {
      setCheck(true);
    }
    fetchList();
  };

  const onPageChange = (event) => {
    const { page: pageData } = event;
    const { skip, take } = pageData;
    fetchList({ page: skip / take, size: take });
  };

  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setDetailId(
      event.dataItems.filter(
        (item) => String(item.requestAt) === Object.keys(newSelectedState)[0]
      )[0].userId
    );
    setSelectedState(newSelectedState);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setDetailId(
      event.dataItems.filter(
        (item) => String(item.requestAt) === Object.keys(newSelectedState)[0]
      )[0].userId
    );
    setSelectedState(newSelectedState);
  };

  const { userKeyword, from, to, assetType, tradeType } = condition;
  return (
    <DefaultLayout detail={detailComponent} clearDetail={clearDetail}>
      <Wrap>
        <Flex
          style={{
            width: "100%",
            display: "flex",
          }}
        >
          <PanelBar style={{ width: "100%", border: "1px solid #9e9e9e" }}>
            <PanelBarItem expanded={true} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}
                >
                  <Flex
                    style={{
                      display: "flex",
                      width: "33%",
                    }}
                  >
                    <FiltersLabel>회원검색</FiltersLabel>
                    <Input
                      autoFocus={true}
                      style={{ width: 350 }}
                      placeholder="이름, 고객번호, 전화번호"
                      name="userKeyword"
                      type="search"
                      value={userKeyword}
                      onChange={onChangeValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onClickSearch();
                        }
                      }}
                    />
                  </Flex>
                  <Flex
                    style={{
                      display: "flex",
                      width: "33%",
                    }}
                  >
                    <FiltersLabel>검색기간</FiltersLabel>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Input
                        style={{ width: 150 }}
                        type="date"
                        value={from ? from : ""}
                        onChange={(e) => {
                          setCondition({
                            ...condition,
                            from: e.target.value
                              ? moment(e.target.value).format("YYYY-MM-DD")
                              : "",
                          });
                        }}
                      />
                      &nbsp;~&nbsp;
                      <Input
                        style={{ width: 150 }}
                        type="date"
                        value={to ? to : ""}
                        onChange={(e) => {
                          setCondition({
                            ...condition,
                            to: e.target.value
                              ? moment(e.target.value).format("YYYY-MM-DD")
                              : "",
                          });
                        }}
                      />
                    </div>
                  </Flex>
                  <Flex
                    style={{
                      display: "flex",
                      width: "33%",
                    }}
                  >
                    <FiltersLabel>자산유형</FiltersLabel>
                    <div
                      style={{
                        marginRight: 17,
                        fontSize: 15,
                        fontFamily: "NotoSansKR-Bold",
                        alignSelf: "center",
                      }}
                    >
                      <Checkbox
                        label={"전체"}
                        name={"ALL"}
                        checked={
                          assetType.length === assetCheckBox.length
                            ? true
                            : false
                        }
                        onChange={({ target }) => {
                          const { name } = target;
                          let arr = [...assetType];
                          if (name === "ALL") {
                            if (arr.length === assetCheckBox.length) {
                              arr = [];
                            } else {
                              arr = [
                                ...assetCheckBox.map((item) => {
                                  return item.key;
                                }),
                              ];
                            }

                            setCondition({
                              ...condition,
                              assetType: arr,
                            });
                          }
                        }}
                      />
                    </div>
                    {assetCheckBox.map((item) => {
                      return (
                        <div
                          style={{
                            marginRight: 17,
                            fontSize: 15,
                            fontFamily: "NotoSansKR-Bold",
                            alignSelf: "center",
                          }}
                        >
                          <Checkbox
                            label={item.label}
                            name={item.key}
                            checked={assetType.includes(item.key)}
                            onChange={({ target }) => {
                              const { name } = target;
                              let arr = [...assetType];
                              if (name === "ALL") {
                                if (arr.length === 4) {
                                  arr = [];
                                } else {
                                  arr = [
                                    ...assetCheckBox.map((item) => {
                                      return item.key;
                                    }),
                                  ];
                                }
                                setCondition({
                                  ...condition,
                                  assetType: arr,
                                });
                              } else {
                                if (arr.includes(name)) {
                                  arr = arr.filter((item) => item !== name);
                                } else {
                                  arr.push(name);
                                }
                                setCondition({
                                  ...condition,
                                  assetType: arr,
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </Flex>
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <FiltersLabel>거래형태</FiltersLabel>
                  <div
                    style={{
                      marginRight: 17,
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      alignSelf: "center",
                    }}
                  >
                    <Checkbox
                      label={"전체"}
                      checked={
                        tradeType.length === tradeCheckBox.length ? true : false
                      }
                      name={"ALL"}
                      onChange={({ target }) => {
                        const { name } = target;
                        let arr = [...tradeType];
                        if (name === "ALL") {
                          if (arr.length === tradeCheckBox.length) {
                            arr = [];
                          } else {
                            arr = [
                              ...tradeCheckBox.map((item) => {
                                return item.key;
                              }),
                            ];
                          }
                          setCondition({
                            ...condition,
                            tradeType: arr,
                          });
                        }
                      }}
                    />
                  </div>
                  {tradeCheckBox.map((item) => {
                    return (
                      <div
                        style={{
                          marginRight: 17,
                          fontSize: 15,
                          fontFamily: "NotoSansKR-Bold",
                          alignSelf: "center",
                        }}
                      >
                        <Checkbox
                          label={item.label}
                          name={item.key}
                          checked={tradeType?.includes(item.key) ? true : false}
                          onChange={({ target }) => {
                            const { name } = target;
                            let arr = [...tradeType];
                            if (name === "ALL") {
                              if (arr.length === tradeCheckBox.length) {
                                arr = [];
                              } else {
                                arr = [
                                  ...tradeCheckBox.map((item) => {
                                    return item.key;
                                  }),
                                ];
                              }
                              setCondition({
                                ...condition,
                                tradeType: arr,
                              });
                            } else {
                              if (arr.includes(name)) {
                                arr = arr.filter((item) => item !== name);
                              } else {
                                arr.push(name);
                              }
                              setCondition({
                                ...condition,
                                tradeType: arr,
                              });
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Button
                  className="buttons-container-button"
                  icon="search"
                  style={{ marginRight: 5 }}
                  onClick={onClickSearch}
                >
                  검색
                </Button>
                <Button
                  className="buttons-container-button"
                  icon="refresh"
                  style={{ marginRight: 5 }}
                  onClick={() => {
                    setCondition({ ...INIT });
                  }}
                >
                  초기화
                </Button>
                <Button
                  icon="excel"
                  className="buttons-container-button"
                  onClick={onClickDownload}
                >
                  Excel
                </Button>
              </div>
            </PanelBarItem>
          </PanelBar>
        </Flex>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "10px",
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
          }}
        >
          <div style={{ marginTop: "6px" }}>
            <RadioButton
              label="최근 순서로"
              value="DESC"
              onChange={onChangeSort}
              checked={condition.sort === "DESC" ? true : false}
            />
            <RadioButton
              label="오래된 순서로"
              value="ASC"
              style={{ marginLeft: "6px" }}
              onChange={onChangeSort}
              checked={condition.sort === "ASC" ? true : false}
            />
          </div>
        </div>
        <LocalizationProvider language="es-KR">
          <IntlProvider locale="es">
            <Grid
              style={{
                cursor: "pointer",
                fontSize: "16px",
                height: "100%",
                maxHeight: "1050px",
                marginTop: "6px",
              }}
              data={
                data.length !== 0
                  ? data?.map((item) => ({
                      ...item,
                      selected: selectedState[idGetter(item)],
                    }))
                  : []
              }
              cellRender={cellRender}
              resizable={true}
              skip={page.page * page.size}
              take={page.size}
              total={page.totalElements}
              pageable={{
                pageSizes: [10, 30, 50, 100],
                previousNext: true,
                info: true,
              }}
              navigatable={true}
              selectable={{
                enabled: true,
              }}
              onSelectionChange={onSelectionChange}
              onKeyDown={onKeyDown}
              selectedField="selected"
              onPageChange={onPageChange}
            >
              <Column
                title="거래일시"
                width={200}
                locked={true}
                cell={(props) => {
                  const { dataItem } = props;
                  const navigationAttributes = useTableKeyboardNavigation(
                    props.id
                  );
                  return (
                    <td
                      className="left k-grid-content-sticky"
                      style={{ textAlign: "center", left: "0px", right: "0px" }}
                      colSpan={props.colSpan}
                      role={"gridcell"}
                      aria-colindex={props.ariaColumnIndex}
                      aria-selected={props.isSelected}
                      {...{
                        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                      }}
                      {...navigationAttributes}
                    >
                      {dataItem?.["completedAt"] || "-"}
                    </td>
                  );
                }}
                field="completedAt"
              />
              <Column
                title="고객번호"
                width={100}
                field="userId"
                cell={(props) => {
                  const { dataItem, id } = props;
                  const navigationAttributes = useTableKeyboardNavigation(id);
                  return (
                    <td
                      style={{
                        textAlign: "center",
                        cursor: "default",
                      }}
                      colSpan={props.colSpan}
                      role={"gridcell"}
                      aria-colindex={props.ariaColumnIndex}
                      aria-selected={props.isSelected}
                      {...{
                        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                      }}
                      {...navigationAttributes}
                    >
                      {dataItem?.["userId"] || "-"}
                    </td>
                  );
                }}
              />
              <Column
                title="이름"
                width={70}
                field="userName"
                cell={(props) => {
                  const { dataItem, id } = props;
                  const navigationAttributes = useTableKeyboardNavigation(id);
                  return (
                    <td
                      style={{
                        textAlign: "center",
                        cursor: "default",
                      }}
                      colSpan={props.colSpan}
                      role={"gridcell"}
                      aria-colindex={props.ariaColumnIndex}
                      aria-selected={props.isSelected}
                      {...{
                        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                      }}
                      {...navigationAttributes}
                    >
                      {dataItem?.["userName"] || "-"}
                    </td>
                  );
                }}
              />
              <Column
                title="휴대폰"
                width={150}
                field="userPhoneNumber"
                cell={(props) => {
                  const { dataItem, id } = props;
                  const navigationAttributes = useTableKeyboardNavigation(id);
                  return (
                    <td
                      style={{
                        textAlign: "center",
                        cursor: "default",
                      }}
                      colSpan={props.colSpan}
                      role={"gridcell"}
                      aria-colindex={props.ariaColumnIndex}
                      aria-selected={props.isSelected}
                      {...{
                        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                      }}
                      {...navigationAttributes}
                    >
                      {dataItem?.["userPhoneNumber"] || "-"}
                    </td>
                  );
                }}
              />
              <Column
                title="거래형태"
                width={140}
                field="tradeType"
                cell={(props) => {
                  const { dataItem, id } = props;
                  const navigationAttributes = useTableKeyboardNavigation(id);
                  return (
                    <td
                      style={{
                        textAlign: "center",
                        cursor: "default",
                      }}
                      colSpan={props.colSpan}
                      role={"gridcell"}
                      aria-colindex={props.ariaColumnIndex}
                      aria-selected={props.isSelected}
                      {...{
                        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                      }}
                      {...navigationAttributes}
                    >
                      {dataItem?.["tradeType"] || "-"}
                    </td>
                  );
                }}
              />
              <Column
                title="자산"
                width={70}
                field="assetType"
                cell={(props) => {
                  const { dataItem, id } = props;
                  const navigationAttributes = useTableKeyboardNavigation(id);
                  return (
                    <td
                      style={{
                        textAlign: "center",
                        cursor: "default",
                      }}
                      colSpan={props.colSpan}
                      role={"gridcell"}
                      aria-colindex={props.ariaColumnIndex}
                      aria-selected={props.isSelected}
                      {...{
                        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                      }}
                      {...navigationAttributes}
                    >
                      {dataItem?.["assetType"] || "-"}
                    </td>
                  );
                }}
              />
              <Column title="주문금액" field="pureTradeKrw" width={150} />
              <Column title="수수료" width={120} field="userFee" />
              <Column title="결제총액" width={150} field="totalPrice" />
              <Column title="체결단가" width={120} field="pricePerGram" />
              <Column title="결제중량" width={100} field="tradeGram" />
              <Column title="대리점 수수료">
                <Column
                  width={120}
                  //title="추천 수수료"
                  reorderable={false}
                  resizable={false}
                  headerCell={() => {
                    return <span>추천</span>;
                  }}
                  field="recommendShopFee"
                />
                <Column
                  width={120}
                  //title="추천 수수료"
                  reorderable={false}
                  resizable={false}
                  headerCell={() => {
                    return <span>이용</span>;
                  }}
                  field="usingShopFee"
                />
                <Column
                  width={120}
                  //title="입출고 수수료"
                  reorderable={false}
                  resizable={false}
                  headerCell={() => {
                    return <span>입출고</span>;
                  }}
                  field="outShopFee"
                />
              </Column>
              <Column title="거래번호" width={200} field="tradeNumber" />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </Wrap>
    </DefaultLayout>
  );
};

export default Transaction;

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: 100px;
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
  align-self: center;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;
