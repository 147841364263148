// import { InputBase } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import AdminConfirmButton from "../../components/button/AdminConfirmButton";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../libs/apis";
import { formatTime } from "../../libs/utils";
import Flex from "../../components/layout/Flex";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";

const ProductAdminMemo = ({ id }) => {
  const dispatch = useDispatch();
  const [adminMemoList, setAdminMemoList] = useState([]);
  const messagesEndRef = useRef(null);
  const [adminMemo, setAdminMemo] = useState("");

  //관리자 비밀번호 저장
  const onSaveAdmin = async (adminPassword) => {
    if (adminMemo === "" || adminMemo === null) {
      dispatch(showAlert({ message: "관리자 메모를 입력하여 주세요." }));
    } else {
      const data = {
        memo: adminMemo,
        managerPassword: adminPassword,
      };
      dispatch(showLoading());
      APIS.postRegisterAdminMemo(id, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              showAlert({
                message: "메모가 저장되었습니다.",
                onClick: () => {
                  setAdminMemo("");
                },
              })
            );
            setAdminMemoList(data);
          } else {
            dispatch(showAlert({ message: message }));
          }
        })
        .catch((error) =>
          dispatch(showAlert({ message: error.response.data.message }))
        )
        .finally(() => dispatch(hideLoading()));
    }
  };

  const scrollToBottom = () => {
    // console.log(messagesEndRef.current, "messagesEndRef.current");
    //   messagesEndRef.current.scrollIntoView();
  };

  useEffect(scrollToBottom, [adminMemoList]);

  useEffect(() => {
    dispatch(showLoading());
    const getAdminMemoHistory = APIS.getAdminMemoHistory(id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAdminMemoList(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      );

    Promise.all([getAdminMemoHistory]).finally(() => dispatch(hideLoading()));
  }, []);

  return (
    <PanelBar
      style={{ width: "100%", marginBottom: 20, border: "1px solid #9e9e9e" }}
    >
      <PanelBarItem expanded={false} title={"관리자 메모"}>
        <Flex style={{ width: "100%", flexDirection: "column" }}>
          <Flex
            style={{
              maxHeight: 300,
              padding: "10px 0px 10px 0px",
              overflowY: "scroll",
              flexDirection: "column",
              "&::-webkit-scrollbar": { width: 10 },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#2f3542",
                borderRadius: 10,
                backgroundClip: "padding-box",
                border: "2px solid transparent",
              },
            }}
          >
            {adminMemoList.length
              ? adminMemoList?.map((memo, index) => {
                  return (
                    <Flex
                      style={{
                        justifyContent: "space-between",
                        marginBottom: 20,
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div>
                        {memo.content.split("").map((x, i) => {
                          if (x === "\n") {
                            return <br key={i.toString()} />;
                          } else {
                            return x;
                          }
                        })}
                      </div>
                      <div style={{ alignSelf: "end" }}>
                        {memo.managerName +
                          " / " +
                          formatTime(memo.createdAt, "YYYY-MM-DD HH:mm:ss")}
                      </div>
                    </Flex>
                  );
                })
              : ""}
            <div ref={messagesEndRef} />
          </Flex>
          <Flex style={{ flexDirection: "row" }}>
            <Flex style={{ width: "100%" }}>
              <input
                multiline
                style={{
                  flex: 1,
                  border: "1px solid black",
                }}
                inputProps={{
                  style: {
                    maxHeight: "70px",
                  },
                }}
                value={adminMemo}
                onChange={(e) => {
                  setAdminMemo(e.target.value);
                }}
              />
            </Flex>
            <Flex>
              <AdminConfirmButton
                title="관리자 메모 등록"
                callback={onSaveAdmin}
              />
            </Flex>
          </Flex>
        </Flex>
      </PanelBarItem>
    </PanelBar>
  );
};

export default ProductAdminMemo;
