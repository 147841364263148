import { configureStore } from "@reduxjs/toolkit";
import alertReducer from "./alertReducer";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import imageViewerReducer from "./imageViewerReducer";

export default configureStore({
  reducer: {
    loading: loadingReducer,
    alert: alertReducer,
    auth: authReducer,
    imageViewer: imageViewerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
