import React, { useState } from "react";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { signIn } from "../../store/authReducer";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { useDispatch } from "react-redux";
import Button from "../../components/button/Button";
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo_new.png";
import base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";
import styled from "styled-components";
import { LoginLayout } from "../../components/layout/LoginLayout";
import { useNavigate } from "react-router-dom";

const Container = styled(LoginLayout)`
  min-width: 600px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  input {
    color: black;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-top: 10px;
    height: 35px;
    padding: 0px 12px;
    // border: none;
    // background-color: #f2f2f2;
  }

  button {
    height: 35px;
    margin-top: 10px;
    font-weight: 700;
  }
`;
const Login = ({ onCheckPassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (!account || !password) {
      dispatch(showAlert({ message: "아이디와 비밀번호를 입력해 주세요." }));
    } else {
      dispatch(showLoading());
      const param = {
        account,
        password: base64.stringify(sha256(`${password}`)),
      };
      // APIS.postSignIn({ account, password: parsePassword })
      //   .then(({ data: { success, message, data } }) => {
      //     const { authToken, passwordVerification, id } = data || {};
      //     if (success) {
      //       localStorage.setItem(STORAGE_KEY.accessToken, authToken);
      //       onCheckPassword(passwordVerification);
      //       // localStorage.setItem("passwordVerification", passwordVerification);
      //       localStorage.setItem("id", id);
      //       localStorage.setItem("userAccount", account);
      //       dispatch(signIn(authToken));
      //     } else {
      //       dispatch(showAlert({ message: message }));
      //     }
      //   })
      //   .catch((err) => {
      //     dispatch(showAlert({ message: err.response.data.message }));
      //   })
      //   .finally(() => dispatch(hideLoading()));

      APIS.postLoginCheck(param)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            if (!data?.isAuthentication) {
              navigate("/login/two-factor/register", { state: param });
            } else {
              navigate("/login/two-factor", { state: param });
            }
          } else {
            dispatch(showAlert({ message }));
          }
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  const handleClickReset = () => {
    navigate("/reset-account");
  };

  return (
    <Container title="대리점 관리시스템">
      <div className="input-wrapper">
        <input
          value={account}
          onChange={(e) => {
            setAccount(e.target.value);
          }}
          placeholder="ID"
          autoFocus
        />
        <input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          type="password"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        <Button onClick={handleLogin}>로그인</Button>
        <Button onClick={handleClickReset}>OTP/비밀번호 초기화</Button>
      </div>
    </Container>
  );
};

export default Login;
