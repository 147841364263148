import { createSlice } from "@reduxjs/toolkit";

const INIT = {
  visible: false,
  title: "",
  message: "",
  button: "확인",
  cancel: false,
  print: false,
  componentRef: "",
  onClick: () => {},
};
export const alertSlice = createSlice({
  name: "alert",
  initialState: INIT,
  reducers: {
    show: (state, { payload }) => {
      return { ...state, visible: true, ...payload };
    },
    hide: () => {
      return {
        ...INIT,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { show: showAlert, hide: hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
