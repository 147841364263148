import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import Modal from "components/common/Modal";
import styled from "styled-components";
import Flex from "components/layout/Flex";
import { MdOutlineCancel } from "react-icons/md";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "store/loadingReducer";
import { showAlert } from "store/alertReducer";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "libs/utils";

const AccountRegistrationStep = ({ onNext, userData, userId, setUserData }) => {
  const dispatch = useDispatch();
  const [changeAccountVisible, setChangeAccountVisible] = useState(false);
  const [selectBank, setSelectBank] = useState({});
  const [accountNumber, setAccountNumber] = useState("");

  //사용자 계좌 조회 API 추가
  useEffect(() => {
    dispatch(showLoading());
    APIS.getBizBankSearch(objToQueryStr({ userId }))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setUserData({
            ...userData,
            bankName: data.bankName,
            accountNumber: data.number,
          });
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  }, []);

  const handleClickNext = () => {
    if (userData.accountNumber && userData.bankName) {
      onNext();
    } else {
      dispatch(showAlert({ message: "은행명, 계좌번호를 등록해주세요." }));
    }
  };

  //계좌번호 화면 변경
  const handleChangeAccount = () => {
    setChangeAccountVisible(true);
  };

  return (
    <div>
      <Row>
        <RowKey>은행명</RowKey>
        <div>
          <input value={userData.bankName} readOnly />
        </div>
      </Row>
      <Row>
        <RowKey>계좌번호</RowKey>
        <div>
          <input value={userData.accountNumber} readOnly />
          <Button onClick={handleChangeAccount}>등록 및 변경</Button>
        </div>
      </Row>
      <div>
        <Button
          disabled={userData.bankName !== "" ? false : true}
          onClick={handleClickNext}>
          다음
        </Button>
      </div>
      {changeAccountVisible && (
        <AccountChangeModal
          changeAccountVisible={changeAccountVisible}
          setChangeAccountVisible={setChangeAccountVisible}
          setSelectBank={setSelectBank}
          selectBank={selectBank}
          setAccountNumber={setAccountNumber}
          accountNumber={accountNumber}
          userId={userId}
          userData={userData}
          setUserData={setUserData}
        />
      )}
    </div>
  );
};

export default AccountRegistrationStep;

const AccountChangeModal = ({
  changeAccountVisible,
  setChangeAccountVisible,
  setSelectBank,
  selectBank,
  setAccountNumber,
  accountNumber,
  userId,
  userData,
  setUserData,
}) => {
  const dispatch = useDispatch();
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    dispatch(showLoading());
    APIS.getBizBankList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setBankList(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        showAlert({ message: e.response.data.message });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  }, []);

  const handleChangeBank = (event) => {
    setSelectBank(event.target.value);
  };

  //계좌 등록 및 변경
  const handleClickRegister = () => {
    const data = {
      userId: userId,
      bankCode: selectBank.code,
      number: accountNumber,
    };
    dispatch(showLoading());
    APIS.postBizBankRegister(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          APIS.getBizBankSearch(objToQueryStr({ userId }))
            .then(({ data: { success, data, message } }) => {
              if (success) {
                setUserData({
                  ...userData, // 기존 userData 상태 복사
                  bankName: data.bankName,
                  accountNumber: data.bankAccountNumber,
                });
              } else {
                dispatch(showAlert({ message: message }));
              }
            })
            .finally(() => {
              handleClickClose();
            });
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        showAlert({ message: e.response.data.message });
      })
      .finally(() => {
        setSelectBank({});
        setAccountNumber("");
        dispatch(hideLoading());
      });
  };

  const handleClickClose = () => {
    setChangeAccountVisible(false);
  };
  return (
    <Modal visible={changeAccountVisible}>
      <Wrapper>
        <Header onClick={handleClickClose}>
          <MdOutlineCancel />
        </Header>
        <Row>
          <RowKey>은행명</RowKey>
          <div>
            <DropDownList
              style={{
                width: "300px",
              }}
              textField="name"
              dataItemKey="code"
              value={selectBank}
              onChange={handleChangeBank}
              data={bankList}
            />
          </div>
        </Row>
        <Row>
          <RowKey>계좌번호</RowKey>
          <div>
            <input
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </div>
        </Row>
        <div>
          <Button onClick={handleClickRegister}>등록 및 변경</Button>
          <Button onClick={handleClickClose}>취소</Button>
        </div>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  cursor: pointer;
`;

const Row = styled(Flex)`
margin-top: 5px;
`;

const RowKey = styled.div`
font-weight : bold;
display: flex;
align-items: center;
margin-right: 10px;
`;
