import React, { useState, useEffect } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import utf8 from "crypto-js/enc-utf8";
import { showAlert } from "../../store/alertReducer";
import { useDispatch } from "react-redux";

const AdminConfirmButton = (props) => {
  const {
    callback = () => {},
    title = "확인",
    description,
    style,
    label = "저장",
    disabled = false,
    validationCheck = "",
  } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [descriptionContent, setDescriptionContent] = useState("");
  const [offset, setOffset] = useState({
    left: 0,
    top: 0,
  });

  useEffect(() => {
    document
      .getElementById("assetMint")
      ?.addEventListener("click", onClickInit, false);
    return () => {
      setPassword("");
      document
        .getElementById("assetMint")
        ?.removeEventListener("click", onClickInit, false);
    };
  }, []);

  const onClickInit = () => {
    setPassword("");
    setShow(false);
  };

  const onClickShow = () => {
    setShow(!show);
  };

  const onClickSave = () => {
    if (!password) {
      alert("패스워드를 입력해주세요.");
    } else {
      const parsePassword = base64.stringify(sha256(`${password}`));
      setPassword("");
      setShow(false);
      callback(parsePassword, descriptionContent);
    }
  };

  return (
    <div>
      <Button
        size="large"
        style={{ ...style }}
        onClick={(e) => {
          if (validationCheck !== "") {
            dispatch(showAlert({ message: validationCheck }));
          } else {
            setOffset({
              left: e.view.innerWidth / 2 - 400 / 2,
              top: e.view.innerHeight / 2 - 280 / 2,
            });
            onClickShow();
          }
        }}
        disabled={disabled || show ? true : false}
      >
        {label}
      </Button>
      <Popup
        offset={offset}
        show={show}
        popupClass={"popup-content"}
        style={{ width: "400px", height: "280px" }}
      >
        <div
          style={{
            width: "400px",
            height: "270px !important",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            id="title"
            style={{
              width: "400px",
              height: "50px",
              textAlign: "center",
              lineHeight: "50px",
              background: "#fe6e0e",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>
              {title}
            </span>
            <div
              style={{
                right: "15px",
                fontSize: "20px",
                fontWeight: "bold",
                top: "0px",
                position: "absolute",
                cursor: "default",
              }}
              onClick={() => {
                setPassword("");
                setShow(false);
              }}
            >
              X
            </div>
          </div>
          <div
            style={{
              width: "400px",
              height: "200px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {description && (
              <div style={{ display: "flex" }}>
                <div style={{ alignSelf: "center" }}>{description}</div>
                <div>
                  <input
                    style={{ width: 300, height: 30 }}
                    placeholder="취소 사유를 입력하세요."
                    onChange={(e) => setDescriptionContent(e.target.value)}
                  />
                  <input
                    id="fakeCancelReason"
                    name="fakeCancelReason"
                    autoComplete="new-password"
                    style={{
                      display: "absolute",
                      width: 0,
                      height: 0,
                      zIndex: -1,
                    }}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                padding: "3px 3px 3px 3px",
                display: "flex",
                flexDirection: "column",
                fontFamily: "NotoSansKR-Bold",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  marginRight: "10px",
                  alignSelf: "center",
                  fontWeight: "bold",
                }}
              >
                관리자 비밀번호
              </span>
              <form autoComplete="new-password">
                <input
                  type="text"
                  autoComplete="new-password"
                  style={{ display: "none" }}
                />
              </form>
              <input
                type="password"
                style={{ display: "none" }}
                aria-hidden={true}
              />
              <Input
                type="password"
                value={password}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onClickSave();
                  }
                }}
                style={{ width: "200px" }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              width: "400px",
              height: "80px",
              textAlign: "center",
              lineHeight: "50px",
            }}
          >
            <Button
              onClick={onClickSave}
              style={{
                width: "100px",
                height: "40px",
                background: "#fe6e0e",
                color: "#fff",
                fontWeight: "bold",
              }}
              disabled={!password ? true : false}
            >
              저장
            </Button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default AdminConfirmButton;
