import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { NumericTextBox, RadioButton } from "@progress/kendo-react-inputs";
import * as APIS from "../../libs/apis";
import { showAlert } from "store/alertReducer";
import { hideLoading, showLoading } from "store/loadingReducer";
import { useDispatch } from "react-redux";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import cloneDeep from "lodash.clonedeep";
import { numberWithCommas } from "libs/utils";

const AppraisalStep = () => {
  const dispatch = useDispatch();
  const [assetType, setAssetType] = useState("GOLD");
  const [productList, setProductList] = useState([]);
  const [totalGram, setTotalGram] = useState(0);

  useEffect(() => {
    dispatch(showLoading());
    APIS.getPurchaseProductList()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          if (assetType === "GOLD") {
            setProductList(data.goldProduct);
          } else {
            setProductList(data.silverProduct);
          }
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        showAlert({ message: e.response.data.message });
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  }, []);

  const NumberInputCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const onChangeValue = (e) => {
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: e.value,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          <NumericTextBox
            onChange={onChangeValue}
            id={`productWeightGram-${dataIndex}`}
            name={"productWeightGram"}
            width={100}
            size="small"
            spinners={false}
            min={0}
            fillMode={"outline"}
            inputStyle={{ backgroundColor: "white" }}
          />
        </tr>
      </td>
    );
  }, []);

  const basicPctCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const onChangeValue = (e) => {
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: e.value,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          {productList?.[dataIndex]?.isWeightRateChange === true ? (
            <div style={{ display: "flex" }}>
              <NumericTextBox
                value={dataItem.basicPct}
                onChange={onChangeValue}
                id={`basicPct-${dataIndex}`}
                name={"basicPct"}
                width={100}
                size="small"
                spinners={false}
                min={0}
                fillMode={"outline"}
                inputStyle={{ backgroundColor: "white" }}
              />
              <div
                style={{
                  alignSelf: "center",
                  fontSize: "medium",
                }}>
                %
              </div>
            </div>
          ) : productList?.[dataIndex]?.basicPct ? (
            `${numberWithCommas(productList?.[dataIndex]?.basicPct)}%`
          ) : (
            "-"
          )}
        </tr>
      </td>
    );
  }, []);

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "createdAt" ||
      props.field === "status" ||
      props.field === "managerName" ||
      props.field === "assetType" ||
      props.field === "purityTypeName" ||
      props.field === "name"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "appraiseGram" ||
      props.field === "basicPct" ||
      props.field === "productWeightGram" ||
      props.field === "appraisalWeightGram"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    }
    return cell;
  };

  const onItemChange = useCallback(
    (props) => {
      const { value, dataItem, field } = props;
      let copyData;
      if (dataItem.assetType === "SILVER") {
        copyData = cloneDeep(productList.silverProduct);
      } else {
        copyData = cloneDeep(productList.goldProduct);
      }
      let newCopy = copyData.map((item) => {
        const basicPctValue = field === "basicPct" ? value : item.basicPct;
        const productWeightGramValue =
          field === "productWeightGram" ? value : item.productWeightGram;
        if (item.id === dataItem.id) {
          return {
            ...item,
            basicPct: basicPctValue,
            productWeightGram: productWeightGramValue,
            appraisalWeightGram:
              dataItem.assetType === "GOLD"
                ? changeAppraisalWeightGram(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (basicPctValue / 100) *
                            productWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 10
                    ) / 1000
                  )
                : changeAppraisalWeightGram(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (basicPctValue / 100) *
                            productWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 1000
                    ) / 10
                  ),
          };
        } else {
          return item;
        }
      });

      //환산중량 총 합계
      const appraisalWeightGramSum = newCopy.reduce(
        (accumulator, currentObject) => {
          return Number(
            (
              Number(accumulator) + Number(currentObject.appraisalWeightGram)
            ).toFixed(13)
          );
        },
        0
      );
      setTotalGram(appraisalWeightGramSum);
      if (dataItem.assetType === "SILVER") {
        // setSilverProductList(newCopy);
        setProductList(newCopy);
      } else {
        setProductList(newCopy);
        // setGoldProductList(newCopy);
      }
    },
    [
      productList,
      setProductList,
      //   setGoldProductList,
      //   setSilverProductList,
      //   goldProductList,
      //   silverProductList,
    ]
  );

  return (
    <div>
      <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
        <span
          style={{
            fontWeight: "bold",
            marginRight: 10,
          }}>
          자산타입
        </span>
        <RadioButton
          style={{
            color: "black",
          }}
          value={"GOLD"}
          //   disabled={assetType === "GOLD" || assetType === null ? false : true}
          label="금"
          checked={assetType === "GOLD"}
          onChange={(e) => {
            setAssetType(e.value);
          }}
        />
        <RadioButton
          style={{
            color: "black",
            marginLeft: 10,
          }}
          value={"SILVER"}
          label="은"
          checked={assetType === "SILVER"}
          onChange={(e) => {
            setAssetType(e.value);
          }}
        />
      </div>
      <div
        style={{
          fontWeight: "bold",
          alignSelf: "center",
          marginRight: 20,
        }}>
        환산 중량 총 합계:
        {`${totalGram && numberWithCommas(totalGram)}g`}
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          //  onClick={handleSaveInventory}
        >
          저장
        </button>
      </div>
      <Grid
        style={{
          width: "100%",
          marginTop: 5,
        }}
        scrollable={"none"}
        data={productList}
        cellRender={cellRender}
        onItemChange={onItemChange}>
        <GridColumn field="purityTypeName" title="구분" width={130} />
        <GridColumn field="name" title="품목" width={140} />
        <GridColumn
          field="basicPct"
          title="기준비율"
          cell={basicPctCell}
          width={120}
        />
        <GridColumn
          field="productWeightGram"
          title="측정 실중량(g)"
          cell={NumberInputCell}
        />
        <GridColumn
          field="appraisalWeightGram"
          title="환산 중량(g)"
          cell={(props) => {
            const { dataIndex } = props;
            return (
              <td style={{ width: 80, textAlign: "right" }}>
                {productList?.[dataIndex]?.appraisalWeightGram !== null &&
                productList?.[dataIndex]?.productWeightGram !== "" &&
                productList?.[dataIndex]?.basicPct !== "" &&
                productList?.[dataIndex]?.productWeightGram !== null
                  ? numberWithCommas(
                      productList?.[dataIndex]?.appraisalWeightGram
                    )
                  : "-"}
              </td>
            );
          }}
        />
        <GridColumn field="" title="추가차감(돈)" cell={NumberInputCell} />
        <GridColumn
          field=""
          title="정제료(돈)"
          cell={(props) => {
            const { dataIndex } = props;
            return (
              <td style={{ width: 80, textAlign: "right" }}>
                {productList?.[dataIndex]?.appraisalWeightGram !== null &&
                productList?.[dataIndex]?.productWeightGram !== "" &&
                productList?.[dataIndex]?.basicPct !== "" &&
                productList?.[dataIndex]?.productWeightGram !== null
                  ? numberWithCommas(
                      productList?.[dataIndex]?.appraisalWeightGram
                    )
                  : "-"}
              </td>
            );
          }}
        />
        <GridColumn
          field=""
          title="적용단가(돈)"
          cell={(props) => {
            const { dataIndex } = props;
            return (
              <td style={{ width: 80, textAlign: "right" }}>
                {productList?.[dataIndex]?.appraisalWeightGram !== null &&
                productList?.[dataIndex]?.productWeightGram !== "" &&
                productList?.[dataIndex]?.basicPct !== "" &&
                productList?.[dataIndex]?.productWeightGram !== null
                  ? numberWithCommas(
                      productList?.[dataIndex]?.appraisalWeightGram
                    )
                  : "-"}
              </td>
            );
          }}
        />
        {/* <GridColumn
            field="inspectProductWeightGram"
            title="검품 측정 실중량(g)"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td style={{ width: 60, textAlign: "right" }}>
                  {dataItem.inspectProductWeightGram || "-"}
                </td>
              );
            }}
          />
          <GridColumn
            field="inspectWeightGram"
            title="검품 환산 중량(g)"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td style={{ width: 60, textAlign: "right" }}>
                  {dataItem.inspectWeightGram || "-"}
                </td>
              );
            }}
          />
          <GridColumn
            field="status"
            title="검품 상태"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td style={{ width: 80, textAlign: "center" }}>
                  {dataItem.status
                    ?.replace("WAIT", "대기")
                    ?.replace("CANCEL", "취소")
                    ?.replace("COMPLETE", "완료") || "-"}
                </td>
              );
            }}
          /> */}
      </Grid>
    </div>
  );
};

export default AppraisalStep;
