import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { RadioButton } from "@progress/kendo-react-inputs";
import * as APIS from "../../libs/apis";
import { objToQueryStr } from "../../libs/utils";

const Flex = styled.div`
  display: flex;
`;

const StatusRadioGroup = ({
  status,
  shipmentStatus,
  setShipmentStatus,
  statussave,
  shipmentStatusSave,
  setStatus,
  histories = [],
}) => {
  const dispatch = useDispatch();
  const [confirmDay, setConfirmDay] = useState("");

  useEffect(() => {
    dispatch(showLoading());
    APIS.getTradeConfig(objToQueryStr({ config: "CAN_GOODS_CONFIRM_DAY" }))
      .then(({ data: { data, success, message } }) => {
        if (success) {
          setConfirmDay(data.value);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  }, []);
  return (
    // <RadioGroup row style={{ alignSelf: "flex-end", marginRight: 50 }}>
    <div>
      <Flex
        style={{
          flexDirection: "row",
          fontFamily: "NotoSansKR-Bold",
          fontSize: 16,
        }}
      >
        <Flex
          style={{
            alignSelf: "center",
            fontFamily: "NotoSansKR-Bold",
            fontSize: 16,
            marginRight: 20,
            fontWeight: "bold",
          }}
        >
          인출 상태
        </Flex>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"CANCEL"}
            disabled={
              statussave === "APPROVAL" || statussave === "CANCEL"
                ? false
                : true
            }
            label="결제취소"
            checked={status === "CANCEL"}
          />
        </div>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"APPROVAL"}
            disabled={statussave === "APPROVAL" ? false : true}
            label="결제"
            checked={status === "APPROVAL"}
          />
        </div>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"TAKEOUT"}
            disabled={
              statussave === "CANCEL" || statussave === "CONFIRM" ? true : false
            }
            label="인출완료"
            checked={status === "TAKEOUT"}
            onChange={(e) => {
              if (statussave === "APPROVAL") {
                setStatus(e.value);
              }
            }}
          />
        </div>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"CONFIRM"}
            disabled={statussave === "CANCEL" ? true : false}
            label="인출확정"
            checked={status === "CONFIRM"}
          />
        </div>
      </Flex>
      <Flex
        style={{
          flexDirection: "row",
          fontFamily: "NotoSansKR-Bold",
          fontSize: 16,
        }}
      >
        <Flex
          style={{
            alignSelf: "center",
            fontFamily: "NotoSansKR-Bold",
            fontSize: 16,
            marginRight: 20,
            fontWeight: "bold",
          }}
        >
          출고 상태
        </Flex>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"MAKE"}
            disabled={statussave === "CANCEL" ? true : false}
            label="제작중"
            checked={shipmentStatus === "MAKE"}
          />
        </div>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"SHIPMENT"}
            disabled={statussave === "CANCEL" ? true : false}
            label="출고중"
            checked={shipmentStatus === "SHIPMENT"}
          />
        </div>
        <div style={{ marginRight: 8 }}>
          <RadioButton
            style={{
              color: "black",
            }}
            value={"DELIVERY"}
            disabled={statussave === "CANCEL" ? true : false}
            label="출고완료"
            checked={shipmentStatus === "DELIVERY"}
            onChange={(e) => {
              if (
                shipmentStatusSave === "SHIPMENT" &&
                (statussave === "APPROVAL" ||
                  statussave === "TAKEOUT" ||
                  statussave === "CONFIRM")
              ) {
                setShipmentStatus(e.value);
              }
            }}
          />
        </div>
      </Flex>
    </div>
  );
};

export default StatusRadioGroup;
