import React, { useState } from "react";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../components/button/Button";
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo_new.png";

const PasswordChange = ({ onCheckPassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [pwdCheckSwitch, setpwdCheckSwitch] = useState(true);

  const onChangeValue = ({ target }) => {
    const { value } = target;
    setPasswordCheck(value);
    if (password !== value) {
      setpwdCheckSwitch(false);
    } else {
      setpwdCheckSwitch(true);
      setPasswordCheck(value);
    }
  };

  const handleModifyPassword = () => {
    if (!password) {
      dispatch(showAlert({ message: "새 비밀번호를 입력해 주세요." }));
    } else if (!passwordCheck) {
      dispatch(showAlert({ message: "비밀번호 확인을 입력해 주세요." }));
    } else if (pwdCheckSwitch === false) {
      dispatch(
        showAlert({
          message: "새 비밀번호와 비밀번호 확인이 \n 일치하지 않습니다.",
        })
      );
    } else {
      dispatch(showLoading());
      APIS.putModifyConfirmPassword({
        newPassword: password,
        confirmPassword: passwordCheck,
      })
        .then(({ data: { success, message } }) => {
          if (success) {
            onCheckPassword("true");
            localStorage.removeItem(STORAGE_KEY.accessToken);
            dispatch(
              showAlert({
                message: "비밀번호가 변경되었습니다.",
                onClick: () => {
                  navigate("/login");
                },
              })
            );
          } else {
            dispatch(showAlert({ message: message }));
          }
        })
        .catch(() => {
          dispatch(showAlert({ message: "비밀번호 변경에 실패하셨습니다." }));
        })
        .finally(() => dispatch(hideLoading()));
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(239,243,247)",
        backgroundColor: "white",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img
        src={banner}
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      />
      <div
        style={{
          backgroundColor: "#fff",
          padding: "60px 50px",
          boxSizing: "border-box",
          zIndex: 10,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: 600,
        }}
      >
        <img
          src={logo}
          style={{ width: 240, height: 40, objectFit: "contain" }}
        />
        <label
          style={{
            fontFamily: "NotoSansKR-Bold",
            color: "black",
            fontSize: "24px",
            marginTop: 10,
            textAlign: "center",
          }}
        >
          비밀번호 변경
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "0px 30px",
            marginTop: "20px",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              autoComplete="new-password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="새 비밀번호"
              style={{
                color: "black",
                background: "white",
                alignSelf: "stretch",
                borderRadius: "3px",
                border: "1px solid rgba(0,0,0,0.23)",
                padding: "0px 12px",
                marginTop: "10px",
                border: "none",
                height: 35,
                backgroundColor: "#f2f2f2",
              }}
            />
            <input
              autoComplete="new-password"
              onChange={(e) => {
                onChangeValue(e);
              }}
              placeholder="비밀번호 확인"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleModifyPassword();
                }
              }}
              type="password"
              style={{
                color: "black",
                background: "white",
                alignSelf: "stretch",
                borderRadius: "3px",
                border: "1px solid rgba(0,0,0,0.23)",
                padding: "0px 12px",
                marginTop: "10px",
                border: "none",
                height: 35,
                backgroundColor: "#f2f2f2",
              }}
            />
          </div>
        </div>
        {pwdCheckSwitch ? null : (
          <div style={{ color: "#e52528", justifySelf: "left" }}>
            비밀번호가 일치하지 않습니다. 다시 확인해주세요.
          </div>
        )}
        <Button
          onClick={handleModifyPassword}
          style={{
            alignSelf: "stretch",
            margin: "10px 30px",
          }}
        >
          비밀번호 변경하기
        </Button>
      </div>
    </div>
  );
};

export default PasswordChange;
