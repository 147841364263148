import React, { useEffect, useMemo, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { signOut, updateScreen } from "../../store/authReducer";
import logo_new from "../../assets/images/logo_new.png";
import { AiFillGold } from "react-icons/ai";
import { Tooltip } from "@progress/kendo-react-tooltip";

import {
  FcBullish,
  FcViewDetails,
  FcPortraitMode,
  FcCalculator,
  FcShop,
  FcSurvey,
  FcPaid,
  FcDownload,
  FcReadingEbook,
  FcNext,
  FcMoneyTransfer,
} from "react-icons/fc";

const ExpandButton = styled(Button)`
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  // border: 1px solid #ff9d5b;
  z-index: 1;
  box-shadow: -1px 1px 1px 1px lightgrey;

  width: 40px;
  height: 40px;
  top: 60px;
  transition: all 200ms ease 0s;
  span {
    font-size: 25px;
  }
  position: absolute;
  bottom: 0px;
`;

const DrawerWrapper = styled(Drawer)`
  height: 100%;
  width: 100%;
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91) !important;
  overflow-x: auto;
  ${ExpandButton} {
    left: ${(props) => (props.expanded ? props.width : props.miniWidth) - 20}px;
    transform: rotateY(${(props) => (props.expanded ? 0.5 : 0)}turn);
  }
`;

/**
 * SHOP : 대리점
 * ASSOCIATE : 제휴사
 * BIZ : 위탁매입
 */

const items = [
  { route: "/" },
  {
    text: "회원 관리",
    selected: true,
    route: "/member",
    icon: <FcPortraitMode />,
    role: "SHOP",
  },
  {
    text: "통합거래내역",
    route: "/transaction",
    icon: <FcViewDetails />,
    role: "SHOP",
  },
  {
    text: "매수/매도",
    route: "/buysell",
    icon: <FcBullish />,
    role: "SHOP",
  },
  {
    text: "감정평가",
    route: "/appraisal",
    icon: <FcSurvey />,
    role: "SHOP,ASSOCIATE,BIZ",
  },
  {
    text: "지체상금",
    route: "/penalty",
    icon: <FcMoneyTransfer />,
    role: "SHOP,BIZ",
  },
  {
    text: "실물인출",
    route: "/goodswithdrawal",
    icon: <AiFillGold style={{ fill: "#ffeb3b" }} />,
    role: "SHOP,ASSOCIATE",
  },
  {
    text: "안심직거래",
    route: "/safe-trade",
    icon: <FcPaid />,
    role: "SHOP",
  },
  {
    text: "대리점관리",
    route: "/shop",
    icon: <FcShop />,
    role: "SHOP,BIZ",
  },
  {
    text: "정산관리",
    route: "/adjustment",
    icon: <FcCalculator />,
    role: "SHOP,ASSOCIATE,BIZ",
  },
  {
    separator: true,
  },
  {
    text: "내 정보",
    route: "/manager",
    icon: <FcReadingEbook style={{ fontSize: "20px" }} />,
    role: "SHOP,ASSOCIATE,BIZ",
  },
  {
    text: "로그아웃",
    route: "/logout",
    icon: <FcDownload style={{ transform: "rotate(270deg)" }} />,
    role: "SHOP,ASSOCIATE,BIZ",
  },
];

const getMenuByRole = (role, screenType) => {
  if (!role) return [];

  const validRoles = ["SHOP", "ASSOCIATE", "BIZ"];
  const filteredRole = validRoles.find((item) => role.includes(item));
  let menus = [];

  if (filteredRole) {
    menus = [
      { route: "/" },
      ...items.filter((menu) => menu.role && menu.role.includes(filteredRole)),
    ];
  }

  if (role === "ROLE_MANAGER_SHOP_GBGB_BIZ") {
    menus = [
      { route: "/" },
      ...items.filter(
        (menu) =>
          menu.role &&
          (screenType === "SHOP"
            ? menu.role.includes("SHOP")
            : menu.role.includes("BIZ"))
      ),
    ];
    menus.push({
      text: "화면 전환",
      route: "/switch",
      icon: <FcDownload style={{ transform: "rotate(270deg)" }} />,
    });
  }
  return menus;
};

const DrawerLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const authReducer = useSelector((s) => s.auth);
  const { role, screenType } = authReducer;
  const menus = useMemo(
    () => getMenuByRole(role, screenType),
    [role, screenType]
  );

  useEffect(() => {
    if (
      screenType === "BIZ" ||
      (role !== "ROLE_MANAGER_SHOP_GBGB_BIZ" && role?.includes("BIZ"))
    ) {
      document.documentElement.style.setProperty(
        "--kendo-background-color",
        "#005f99"
      ); // BIZ 색상
    } else {
      document.documentElement.style.setProperty(
        "--kendo-background-color",
        "#ff9d5b"
      ); // 기본 색상
    }
  }, [screenType]);

  const onSelect = (e) => {
    if (e.itemTarget.props.route === "/logout") {
      dispatch(signOut());
    } else if (e.itemTarget.props.route === "/switch") {
      if (role === "ROLE_MANAGER_SHOP_GBGB_BIZ") {
        const newScreen = screenType === "SHOP" ? "BIZ" : "SHOP";
        dispatch(updateScreen(newScreen));
        navigate(newScreen === "SHOP" ? "/member" : "/appraisal", {
          replace: true,
          state: { ...location.state },
        });
      }
    } else {
      navigate(e.itemTarget.props.route, {
        replace: false,
        state: { ...location.state },
      });
    }
  };

  const CustomItem = (props) => {
    if (props.route === "/") {
      return expanded ? (
        <>
          <LogoItem {...props}>
            <img src={logo_new} alt="logo" style={{ width: "100%" }} />
          </LogoItem>
          <div
            style={{
              width: expanded ? "139px" : "48px",
              transition: "0.15s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
              height: "50px",
              position: "absolute",
              bottom: "0px",
              borderTop: "1px solid #ededed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}>
            <FcNext
              style={{ transform: `rotateY(${expanded ? 0.5 : 0}turn)` }}
            />
          </div>
        </>
      ) : (
        <>
          <span
            style={{
              fontSize: "13px",
              fontWeight: "900",
              color: "#df5830",
              textAlign: "center",
            }}>
            금방금방
          </span>
          <div
            style={{
              width: expanded ? "139px" : "48px",
              transition: "0.15s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
              height: "50px",
              position: "absolute",
              bottom: "0px",
              borderTop: "1px solid #ededed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}>
            <FcNext
              style={{ transform: `rotateY(${expanded ? 0.5 : 0}turn)` }}
            />
          </div>
        </>
      );
    }

    const iconItem = items.find((item) => item.route === props.route)?.icon || (
      <FcBullish />
    );

    let style = {};

    if (!expanded) {
      style = {
        display: "flex",
        width: "100%",
        justifyContent: "center",
      };
    }

    return (
      <DrawerItem style={{ ...style, position: "relative" }} {...props}>
        {expanded ? (
          <div
            style={{
              transition: "0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}>
            <ItemIcon>{iconItem}</ItemIcon>
            <span>{props.text}</span>
          </div>
        ) : (
          <Tooltip openDelay={100} anchorElement="target" position="right">
            <div
              title={props.text}
              style={{ width: "49px", textAlign: "center" }}>
              <ItemIcon title={props.text}>{iconItem}</ItemIcon>
            </div>
          </Tooltip>
        )}
      </DrawerItem>
    );
  };

  return (
    <>
      <DrawerWrapper
        expanded={expanded}
        position="start"
        mode="push"
        mini={true}
        onSelect={onSelect}
        width={expanded ? 140 : 49}
        miniWidth={49}
        items={menus.map((item) => ({
          ...item,
          selected: item.route === location.pathname,
        }))}
        item={CustomItem}>
        <DrawerContent style={{ height: "100%" }}>
          <Outlet />
        </DrawerContent>
      </DrawerWrapper>
      {/* <ExpandButton
        onClick={() => setExpanded(!expanded)}
        icon="arrow-chevron-right"
      /> */}
    </>
  );
};

const LogoItem = styled(DrawerItem)`
  background: white !important;
  &:focus {
    box-shadow: none;
  }
`;
const ItemIcon = styled.span`
  width: 20px;
  height: 20px;
  align-self: center;
`;

export default DrawerLayout;
