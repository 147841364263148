import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as APIS from "../../libs/apis";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { useSelector, useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import StatusRadioGroup from "./StatusRadioGroup";
import Button from "../../components/button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import AdminConfirmButton from "../../components/button/AdminConfirmButton";
import { showImageViewer } from "../../store/imageViewerReducer";
import { formatTime } from "../../libs/utils";
import consts from "../../libs/consts";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

const Wrap = styled.div`
  .k-animation-container {
    width: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
  margin-top: 5px;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const GoodsWithdrawalDetail = ({ id, transId, fetchList, searchSummary }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state.goodsWithdrawal || {};
  const [dialog, setDialog] = useState(false);
  const [rows, setrows] = useState([]);
  const [product, setproduct] = useState({
    imageIds: [
      {
        path: "",
      },
    ],
  });

  const [status, setStatus] = useState("");
  const [shipmentStatus, setShipmentStatus] = useState("");
  const [statussave, setstatussave] = useState("");
  const [shipmentStatusSave, setShipmentStatusSave] = useState("");
  const [estimateDate, setestimateDate] = useState("");
  const [memo, setmemo] = useState("");
  const [standardPay, setstandardPay] = useState("");
  const [additionalPay, setadditionalPay] = useState(0);
  const [priceSum, setpriceSumData] = useState(0);
  const [transOrderid, settransOrderid] = useState(0);
  const [historyList, setHistoryList] = useState([]);
  const { isKorda, shopId } = useSelector((s) => s.auth);
  const navigate = useNavigate();

  const canModify =
    isKorda &&
    (statussave === "APPROVAL" ||
      statussave === "TAKEOUT" ||
      statussave === "CONFIRM") &&
    (shipmentStatusSave === "NOT_SHIPPED" || shipmentStatusSave === "MAKE");

  // const setpriceSum2 = (v) => {
  //   setadditionalPay(v);
  //   setpriceSumData(standardPay * 1 + v * 1);
  // };
  const week = ["일", "월", "화", "수", "목", "금", "토"];

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "createdAt" ||
      props.field === "status" ||
      props.field === "managerName" ||
      props.field === "userName"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    }
    return cell;
  };

  const onLoad = () => {
    dispatch(showLoading());
    APIS.getOrderDetail(transId ? transId : id)
      .then((res) => {
        const { data, success, message } = res.data;
        if (success) {
          setrows(data);
          setStatus(data.status);
          setShipmentStatus(data.shipmentStatus);
          setstatussave(data.status);
          setShipmentStatusSave(data.shipmentStatus);
          let reformData = [];
          data.goodsTransHistory.forEach((element) => {
            reformData.push({
              ...element,
              status:
                element.status
                  ?.replace("APPROVAL", "결제")
                  ?.replace("MAKE", "제작중")
                  ?.replace("SHIPMENT", "출고중")
                  ?.replace("DELIVERY", "출고완료")
                  ?.replace("TAKEOUT", "인출완료")
                  ?.replace("CONFIRM", "인출확정")
                  ?.replace("CANCEL", "결제취소") || "-",
              createdAt:
                formatTime(element?.createdAt, "YYYY-MM-DD HH:mm") || "-",
              managerName: element?.managerName || "-",
              userName: element?.userName || "-",
              memo: element?.memo || "-",
            });
          });
          setHistoryList(reformData);
          APIS.getProductDetail(data.goodsId).then(({ data }) => {
            setproduct(data.data);

            // 기본 공임
            setstandardPay(data.data.pay);
          });
          APIS.getProductOrderHistory(data.transId).then(({ data }) => {
            setestimateDate(data.data.estimateOutSourcingDate || "");
            setmemo(data.data.memo || "");

            // 추가 공임
            setadditionalPay(data.data.additionalPay);

            // var priceSumData =
            //   data.data.standardPay * 1 + data.data.additionalPay * 1;

            settransOrderid(data.data.transId);
          });
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };
  const onSaveAdmin = async (adminPassword) => {
    let data = {};
    data = {
      status: status,
      shipmentStatus: shipmentStatus,
      transId: transOrderid,
      managerPassword: adminPassword,
    };
    APIS.postProductOrderMod(data)
      .then(({ data }) => {
        if (!data.success) {
          dispatch(
            showAlert({
              message: data.message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    goodsWithdrawal: {
                      ...state,
                    },
                  },
                });
              },
            })
          );
        } else {
          dispatch(
            showAlert({
              message: "제작 주문 정보가 수정되었습니다.",
            })
          );
          if (status === "CANCEL") {
            navigate(-1);
          }
          onLoad();
          fetchList();
          searchSummary();
        }
      })
      .finally(() => {
        dispatch(hideLoading());
      });
    return () => {};
  };

  useEffect(() => {
    onLoad();
  }, [id]);

  return (
    <Wrap style={{ height: "97%" }}>
      <div style={{ height: "100%", marginTop: 10, width: "100%" }}>
        <Table>
          <colgroup>
            <col width="7%" />
            <col width="20%" />
            <col width="7%" />
            <col width="20%" />
          </colgroup>
          <tbody>
            <tr>
              <Th>주문번호</Th>
              <Td>{rows.no || "-"}</Td>
              <Th>요청일</Th>
              <Td>{rows.createdAt || "-"}</Td>
            </tr>
            <tr>
              <Th>수정일</Th>
              <Td>{rows.updatedAt || "-"}</Td>
              <Th>회원번호</Th>
              <Td>{rows.userId || "-"}</Td>
            </tr>
            <tr>
              <Th>회원명</Th>
              <Td>{rows.userName || "-"}</Td>
              <Th>연락처</Th>
              <Td>{rows.phone || "-"}</Td>
            </tr>
            <tr>
              <Th>방문 예약일시</Th>
              <Td>
                {`${rows.visitDate}(${
                  week[new Date(rows.visitDate).getDay()]
                })` || "-"}
              </Td>
              <Th>방문 대리점</Th>
              <Td>{rows.shopName || "-"}</Td>
            </tr>
            <tr>
              <Th>인출 상태</Th>
              <Td>
                {rows.status
                  ? rows.status === "APPROVAL"
                    ? "결제"
                    : rows.status === "CANCEL"
                    ? "취소"
                    : rows.status === "MAKE"
                    ? "제작중"
                    : rows.status === "SHIPMENT"
                    ? "출고중"
                    : rows.status === "DELIVERY"
                    ? "출고완료"
                    : rows.status === "TAKEOUT"
                    ? "인출완료"
                    : rows.status === "CONFIRM"
                    ? "인출확정"
                    : rows.status === "NOT_SHIPPED"
                    ? "미출고"
                    : "-"
                  : "-"}
              </Td>
              <Th>인출 확정일</Th>
              <Td>{rows.confirmDate || "-"}</Td>
            </tr>
            <tr>
              <Th>출고 상태</Th>
              <Td>
                {rows.shipmentStatus
                  ? rows.shipmentStatus === "APPROVAL"
                    ? "결제"
                    : rows.shipmentStatus === "CANCEL"
                    ? "취소"
                    : rows.shipmentStatus === "MAKE"
                    ? "제작중"
                    : rows.shipmentStatus === "SHIPMENT"
                    ? "출고중"
                    : rows.shipmentStatus === "DELIVERY"
                    ? "출고완료"
                    : rows.shipmentStatus === "TAKEOUT"
                    ? "인출완료"
                    : rows.shipmentStatus === "CONFIRM"
                    ? "인출확정"
                    : rows.shipmentStatus === "NOT_SHIPPED"
                    ? "미출고"
                    : "-"
                  : "-"}
              </Td>
              <Th>출고 일시</Th>
              <Td>{rows.shipmentDate || "-"}</Td>
            </tr>
          </tbody>
        </Table>
        <Flex
          style={{
            justifyContent: "right",
            flexDirection: "row",
            marginTop: 7,
          }}>
          <StatusRadioGroup
            status={status}
            shipmentStatus={shipmentStatus}
            setShipmentStatus={setShipmentStatus}
            shipmentStatusSave={shipmentStatusSave}
            statussave={statussave}
            setStatus={setStatus}
          />
          <Flex
            style={{
              marginRight: "3%",
              marginLeft: 50,
              alignItems: "center",
              justifyContent: "flex-end",
              flexDirection: "row",
            }}>
            <Button
              style={{
                backgroundColor: "#fff",
                border: "1px solid black",
                color: "black",
                "& span": { color: "black" },
                marginRight: 10,
              }}
              onClick={() => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    goodsWithdrawal: { ...location.state?.goodsWithdrawal },
                  },
                });
                onLoad();
              }}>
              취소
            </Button>
            <AdminConfirmButton
              title="상태 변경"
              callback={onSaveAdmin}
              validationCheck={
                !isKorda && rows.shopId !== shopId
                  ? "상태 변경은 출고대리점에서만 변경할 수 있습니다."
                  : ""
              }
            />
          </Flex>
        </Flex>
        <div
          style={{
            fontWeight: "bold",
            marginTop: 5,
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
          }}>
          회원 주문 정보
        </div>
        <Table>
          <colgroup>
            <col width="7%" />
            <col width="20%" />
            <col width="7%" />
            <col width="20%" />
          </colgroup>
          <tbody>
            <tr>
              <Th>주문번호</Th>
              <Td colSpan={3}>{rows.no || "-"}</Td>
            </tr>
            <tr>
              <Th>제품번호</Th>
              <Td colSpan={3}>{rows.code || "-"}</Td>
            </tr>
            <tr>
              <Th>제품분류</Th>
              <Td>{rows.categoryName || "-"}</Td>
              <Th>상품명</Th>
              <Td>{rows.name || "-"}</Td>
            </tr>
            <tr>
              <Th>이미지</Th>
              <Td>
                {(product.imageIds.length > 0 && (
                  <img
                    src={
                      product.imageIds[0].path
                        ? consts.s3BaseUrl + "/" + product.imageIds[0].path
                        : ""
                    }
                    width="100"
                    alt="상품이미지"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(
                        showImageViewer({
                          images: [{ src: product.imageIds[0].path }],
                          activeIndex: 0,
                        })
                      );
                    }}
                  />
                )) ||
                  "-"}
              </Td>
              <Th>순도</Th>
              <Td>24k(999)</Td>
            </tr>
            <tr>
              <Th>중량</Th>
              <Td>{`${rows.totalGram} g` || "-"}</Td>
              <Th>수량</Th>
              <Td>{`${rows.qty} 개` || "-"}</Td>
            </tr>
            <tr>
              <Th>선택 옵션</Th>
              <Td colSpan={3}>{rows.optionValue || "-"}</Td>
            </tr>
          </tbody>
        </Table>
        <div
          style={{
            fontWeight: "bold",
            marginTop: 10,
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
          }}>
          제작 주문 정보
        </div>
        <Table>
          <colgroup>
            <col width="2.8%" />
            <col width="20%" />
          </colgroup>
          <tbody>
            <tr>
              <Th>출고 예정일</Th>
              <Td>
                <Input
                  type="date"
                  value={estimateDate}
                  readOnly={!canModify}
                  disabled={!canModify}
                  style={{ width: "auto" }}
                />
              </Td>
            </tr>
            <tr>
              <Th>주문메모</Th>
              <Td>
                <Input
                  style={{
                    border: "1px solid #ddd",
                    minHeight: 200,
                    flex: 1,
                    width: "100%",
                    alignSelf: "stretch",
                    alignItems: "center",
                  }}
                  multiline
                  value={memo}
                  readOnly={!canModify}
                  disabled={!canModify}
                />
              </Td>
            </tr>
          </tbody>
        </Table>
        <div
          style={{
            fontWeight: "bold",
            marginTop: 10,
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
          }}>
          실물인출
        </div>
        <Grid
          style={{
            width: "100%",
            marginTop: 5,
          }}
          scrollable={"none"}
          data={historyList ? historyList : []}
          cellRender={cellRender}>
          <GridColumn field="createdAt" title="일시" width={130} />
          <GridColumn field="status" title="상태" width={140} />
          <GridColumn field="managerName" title="관리자" width={140} />
          <GridColumn field="userName" title="사용자" width={140} />
          <GridColumn field="memo" title="비고" />
        </Grid>
      </div>
    </Wrap>
  );
};

export default GoodsWithdrawalDetail;
