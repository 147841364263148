import React, { useEffect, useState } from "react";
import IconRight from "../../assets/images/ico_arrow_right.png";
import CheckBoxOn from "../../assets/images/checkBox_on.png";
import CheckBoxOff from "../../assets/images/checkBox_off.png";
import * as APIS from "../../libs/apis";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { showAlert } from "store/alertReducer";
import { useLocation } from "react-router-dom";

const AcceptTerms = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));
  const id = params.get("id") || "";
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    APIS.getServiceTerms().then(({ data: { success, data = [], message } }) => {
      if (success) {
        setTerms(
          data.map((item) => {
            const checked = false;
            return { ...item, checked };
          })
        );
      } else {
        dispatch(showAlert({ message: message }));
      }
    });
  }, []);

  const handleClickAll = (event) => {
    const checked = event.target.checked;
    setTerms((prevTerms) => prevTerms.map((term) => ({ ...term, checked })));
  };

  const handleClickCheck = (event, i) => {
    const checked = event.target.checked;
    const item = terms[i];
    if (item.checked !== checked) {
      const copy = [...terms];
      copy.splice(i, 1, { ...item, checked });
      setTerms(copy);
    }
  };

  const canNext =
    terms.findIndex(({ checked, required }) => required && !checked) === -1;
  const allCheck = terms.every(({ checked }) => checked);

  const handleClickSubmit = () => {
    if (canNext) {
      const termsTypes = terms
        .filter(({ checked }) => checked)
        .map(({ type }) => type);
      const data = { requestId: id, termsTypes: termsTypes };
      APIS.postCompleteVerification(data).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            dispatch(
              showAlert({
                message: "제출 완료하였습니다",
                onClick: () => {
                  window.location.href = "kakaotalk://inappbrowser/close";
                },
              })
            );
          } else {
            dispatch(showAlert({ message: `message : ${message}, id: ${id}` }));
          }
        }
      );
    }
  };

  return (
    <div>
      <Wrap className="sub_wrap08_2">
        <Header className="sub_wrap08_2_tt">
          금방금방 Biz 이용을 위해 <br />
          약관에 동의해주세요
        </Header>
        <div>
          <CheckAll className="terms_check check_all">
            <input
              type="checkbox"
              id="all-check"
              name="term_check"
              checked={allCheck}
              onChange={handleClickAll}
            />
            <label htmlFor="all-check">
              <h3>약관 전체 동의 (선택 동의 포함)</h3>
            </label>
          </CheckAll>
          {terms?.map(({ title, required, checked, linkUrl }, i) => {
            return (
              <Check className="terms_check" key={i}>
                <input
                  type="checkbox"
                  id={`check${i}`}
                  name="term_check"
                  checked={checked}
                  onChange={(checked) => handleClickCheck(checked, i)}
                  style={{ cursor: "pointer" }}
                />
                <label
                  htmlFor={`check${i}`}
                  onClick={(event) => {
                    const target = event.target;
                    if (
                      target.tagName !== "INPUT" &&
                      target.tagName !== "LABEL"
                    ) {
                      event.preventDefault(); // 체크박스 상태 변경 방지
                      window.location.href = linkUrl;
                    }
                  }}>
                  <p>
                    <RequiredText required={required}>
                      {`${required ? "[필수]" : "[선택]"}`}{" "}
                    </RequiredText>
                    {title}
                    <img src={IconRight} />
                  </p>
                </label>
              </Check>
            );
          })}
        </div>
      </Wrap>
      <Button>
        <button
          className={`${canNext ? "on" : "off"}`}
          onClick={handleClickSubmit}>
          제출하기
        </button>
      </Button>
    </div>
  );
};

export default AcceptTerms;

const Wrap = styled.div`
background-color: #fff;
height: calc(100vh - 56px);
padding:64px 24px 24px;
box-sizing: border-box;
`;

const Header = styled.h3`
color:#222;
margin: 17px 0px 40px 0px;
font-size: 21px;
font-weight: 700;
line-height: 31.5px;
`;

const CheckAll = styled.div`
  padding: 0;
  margin-bottom: 16px;
  
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    width: 100%;
    height: 56px;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    background: url(${CheckBoxOff}) #fafafa no-repeat;
    background-size: 24px 24px;
    background-position: left 16px center;
  }

  input[type="checkbox"]:checked + label {
    background-color: #f2fdff;
    
  }

  input[type="checkbox"]:checked + label::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 16px;
    left:16px;
    background: url(${CheckBoxOn}) no-repeat;
    background-size: 100% 100%;
  }


  label h3 {
    font-size: 14px;
    color: #222;
    line-height: 56px;
    margin: 0px 0px 0px 52px;
  }

  input[type="checkbox"]:checked + label h3 {
    color: #00baff;
    margin: 0px 0px 0px 52px;
  }
`;

const Check = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  height: 48px;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-block;
    width: 100%;
    height: 48px;
    position: relative;
    background: url(${CheckBoxOff}) no-repeat;
    background-size: 24px 24px;
    background-position: left center;
    cursor: pointer;
  }

  input[type="checkbox"] + label img {
    position: absolute;
    right: 0;
    height: 48px;
    top: 0;
  }

  input[type="checkbox"]:checked + label::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 12px;
    background: url(${CheckBoxOn}) no-repeat;
    background-size: 100% 100%;
  }

  input[type="checkbox"] + label p {
    margin: 0px 0px 0px 36px;
    font-size: 14px;
    color: #000;
    line-height: 48px;
  }

  input[type="checkbox"]:checked + label h3 {
    color: #00baff;
  }
`;

const RequiredText = styled.span`
color: ${(props) => (props.required ? "#00BAFF" : "")};
`;

const Button = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 24px 40px;
  box-sizing: border-box;
  text-align: center;
  z-index: 10;

  button {
    width: 100%;
    padding: 16px 0;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  button.on {
    background-color: #01A3FE;
    color: #fff;
  }

  button.off {
    background-color: #ddd;
    color: #bbb;
  }
`;
