import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import esMessages from "../../libs/es.json";
import { getter } from "@progress/kendo-react-common";
import { createIndex } from "../../libs/utils";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

loadMessages(esMessages, "es-KR");
const DATA_ITEM_KEY = "id";
const idGetter = getter(DATA_ITEM_KEY);
// const initialDataState = {
//   skip: 0,
//   take: 10,
// };
const Grid = ({
  columnData,
  cellRender,
  filterExpanded,
  pageable,
  data = [],
  total = 0,
  onRowClick,
  onPageChange,
  expandedWidth,
  unExpandedWidth,
  selectedState,
  setSelectedState,
}) => {
  // const [page, setPage] = useState(initialDataState);
  const location = useLocation();
  const state = location.state?.appraisal || {};
  // const [selectedState, setSelectedState] = useState({});
  const navigate = useNavigate();

  let defaultProps = {};
  const handlePageChange = ({ page: { skip, take } }) => {
    const pageIndex = skip / take;

    if (typeof onPageChange === "function") {
      onPageChange(pageIndex, take);
    } else {
      navigate(location.pathname, {
        replace: false,
        state: {
          appraisal: {
            ...location.state?.appraisal,
            page: pageIndex,
            size: take,
          },
        },
      });
    }
  };

  if (pageable) {
    defaultProps["pageable"] = {
      pageSizes: pageable.pageSizes ? pageable.pageSizes : [10, 30, 50, 100],
      previousNext: true,
      info: true,
    };

    let RcurrentPage = state.page;
    let RpageSize = state.size;

    defaultProps["skip"] = RcurrentPage * RpageSize;
    defaultProps["take"] = RpageSize;
    defaultProps["total"] = total;
    defaultProps["onPageChange"] = handlePageChange;
  }

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    setSelectedState(newSelectedState);
    // navigate(location.pathname, {
    //   replace: false,
    //   state: {
    //     ...location.state,
    //     selectedState: newSelectedState,
    //   },
    // });
  };

  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    // navigate(location.pathname, {
    //   replace: false,
    //   state: {
    //     ...location.state,
    //     selectedState: newSelectedState,
    //   },
    // });
    setSelectedState(newSelectedState);
  };

  // const cellRender = (cell, props) => {
  //   const navigationAttributes = useTableKeyboardNavigation(props.id);
  //   if (props.field === "no") {
  //     return (
  //       <td
  //         colSpan={props.colSpan}
  //         role={"gridcell"}
  //         aria-colindex={props.ariaColumnIndex}
  //         aria-selected={props.isSelected}
  //         {...{
  //           [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
  //         }}
  //         {...navigationAttributes}
  //         style={{ textAlign: "-webkit-center" }}
  //       >
  //         {props.dataItem[props.field]}
  //       </td>
  //     );
  //   }
  //   return cell;
  // };

  return (
    <>
      <LocalizationProvider language="es-KR">
        <IntlProvider locale="es">
          <KendoGrid
            {...defaultProps}
            scrollable
            cellRender={cellRender}
            style={{
              cursor: "pointer",
              fontSize: "16px",
              height: "100%",
              maxHeight:
                filterExpanded === true ? expandedWidth : unExpandedWidth,
            }}
            data={
              data.length !== 0
                ? data
                    // .slice(state.page, state.size + state.page)
                    .map((item, index) => ({
                      ...item,
                      selected: selectedState[idGetter(item)],
                      no: createIndex(state, index),
                    }))
                : []
            }
            resizable={true}
            navigatable={true}
            dataItemKey={DATA_ITEM_KEY}
            selectable={{
              enabled: true,
            }}
            onSelectionChange={onSelectionChange}
            onKeyDown={onKeyDown}
            selectedField="selected"
            onRowClick={(props) => {
              onRowClick && onRowClick(props);
            }}
          >
            {columnData.map((item) => {
              return (
                <Column
                  field={item.field}
                  title={item.title}
                  width={item.width}
                />
              );
            })}
          </KendoGrid>
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
};

export default Grid;
