import React from "react";
import { Button as KendoButton } from "@progress/kendo-react-buttons";

const Button = ({
  className,
  onClick = () => {},
  disabled = false,
  style = {},
  children,
  icon,
  id = "",
  ref,
}) => {
  return (
    <KendoButton
      className={className}
      fillMode="outline"
      onClick={onClick}
      disabled={disabled}
      style={style}
      size="large"
      icon={icon}
      id={id}
      ref={ref}
    >
      {children}
    </KendoButton>
  );
};

export default Button;
