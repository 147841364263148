import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: { visible: false },
  reducers: {
    show: (state) => {
      state.visible = true;
    },
    hide: (state) => {
      state.visible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { show: showLoading, hide: hideLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
