import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MemberManagementDetail from "./MemberManagementDetail";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import Button from "../../components/button/Button";
import DefaultLayout from "../../components/layout/DefaultLayout";
import { RadioGroup } from "@progress/kendo-react-inputs";
import Flex from "../../components/layout/Flex";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { objToQueryStr, formatTime, numberWithCommas } from "../../libs/utils";
import { getter } from "@progress/kendo-react-common";
import esMessages from "../../libs/es.json";
import { createIndex } from "../../libs/utils";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

loadMessages(esMessages, "es-KR");
const DATA_ITEM_KEY = "id";
const idGetter = getter(DATA_ITEM_KEY);
const initialDataState = {
  skip: 0,
  take: 10,
};

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: 100px;
  font-size: 16px;
  font-family: "NotoSansKR-Bold";
  align-self: center;
`;

const Wrap = styled.div`
  min-width: 1112px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1050px;

  .k-grid .k-grid-container {
    height: 50px;
  }
`;

const MemberManagement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state?.member || {};
  const navigate = useNavigate();
  const page = initialDataState;
  // const [page, setPage] = useState(initialDataState);
  const [detailComponent, setDetailComponent] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [selectedState, setSelectedState] = useState({});

  const STATUS_CODE = {
    ACTIVE: "활성",
    DISABLED: "비활성",
    LEAVE: "탈퇴",
    LOCK: "사용정지",
    DORMANT: "휴면",
  };

  const pageChange = (event) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        member: {
          ...location.state?.member,
          page: event.page.skip / event.page.take,
          size: event.page.take,
        },
      },
    });
  };

  const handleValueChange = (key, value, more) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        member: { ...location.state?.member, [key]: value || "", ...more },
      },
    });
  };

  const initState = () => {
    navigate(location.pathname, {
      replace: true,
      state: {
        member: {
          amountType: "GOLD",
          status: "",
          page: page.skip,
          size: page.take,
        },
      },
    });
  };
  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };

  const handleClickDetail = (props) => {
    const newData = data.map((item) => ({
      ...item,
      selected: item.no === props.dataItem.no,
    }));
    setData(newData);

    setDetailComponent(() => {
      return null;
    });

    setDetailComponent(() => {
      return <MemberManagementDetail id={Object.keys(selectedState)} />;
    });
  };

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "no" ||
      props.field === "name" ||
      props.field === "createdAt" ||
      props.field === "status" ||
      props.field === "phone" ||
      props.field === "recommendShopName" ||
      props.field === "usingShopName" ||
      props.field === "id"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "gold" ||
      props.field === "silver" ||
      props.field === "krw" ||
      props.field === "point"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}>
          <div style={{ float: "right" }}>{props.dataItem[props.field]}</div>
        </td>
      );
    }
    return cell;
  };

  const clearDetail = () => {
    setDetailComponent(null);
  };
  const fetchList = () => {
    const param = { ...state };
    if (param.startDate) {
      param.startDate = `${param.startDate}T00:00:00`;
    }
    if (param.endDate) {
      param.endDate = `${param.endDate}T23:59:59`;
    }

    // 이상, 이하
    const { amountType, more, less } = param;
    param["amountAssetType"] = amountType;
    if (more) {
      param[`startAssetAmount`] = more;
    }

    if (less) {
      param[`endAssetAmount`] = less;
    }

    delete param.skip;
    delete param.take;
    delete param.more;
    delete param.less;
    delete param.amountType;

    if (!param.startAssetAmount && !param.endAssetAmount) {
      delete param["amountAssetType"];
    }

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(showLoading());
    APIS.searchMemberList(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setData(
            data.content.map((item, index) => {
              const { status, createdAt, gold, silver, krw, point } = item;
              return {
                ...item,
                gold: numberWithCommas(gold),
                silver: numberWithCommas(silver),
                krw: numberWithCommas(krw),
                point: numberWithCommas(point),
                status: STATUS_CODE[status] || status,
                createdAt: formatTime(createdAt),
                // no: data.content.length - index,
                no: createIndex(state, index),
              };
            })
          );
          setTotalCount(data.totalElements);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        dispatch(showAlert({ message: e.response.data.message }));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const statusRadioData = [
    {
      label: "전체",
      value: "",
    },
    {
      label: "활성",
      value: "ACTIVE",
    },
    {
      label: "사용정지",
      value: "LOCK",
    },
    {
      label: "휴면",
      value: "DORMANT",
    },
    {
      label: "탈퇴",
      value: "LEAVE",
    },
  ];

  useEffect(() => {
    if (Object.keys(state).length === 0) {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          member: {
            amountType: "GOLD",
            status: "",
            page: page.skip,
            size: page.take,
          },
        },
      });
    }
  }, []);
  useEffect(() => {
    // if (Object.keys(selectedState).length !== 0) {
    //   setDetailComponent(() => {
    //     return null;
    //   });
    //   setDetailComponent(() => {
    //     return <MemberManagementDetail id={Object.keys(selectedState)} />;
    //   });
    // }
  }, [selectedState]);

  useEffect(() => {
    if (Object.keys(state).length !== 0) {
      fetchList();
    }
  }, [state.page, state.size]);

  return (
    <DefaultLayout detail={detailComponent} clearDetail={clearDetail}>
      <Wrap>
        <Flex
          style={{
            width: "100%",
            display: "flex",
          }}>
          <PanelBar style={{ width: "100%", border: "1px solid #9e9e9e" }}>
            <PanelBarItem expanded={true} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>가입일</FiltersLabel>
                    <Flex style={{ alignItems: "center" }}>
                      <Input
                        style={{ width: 180 }}
                        type="date"
                        value={state?.startDate ? state.startDate : ""}
                        onChange={(e) => {
                          handleValueChange("startDate", e.target.value);
                        }}
                      />
                      &nbsp;~&nbsp;
                      <Input
                        style={{ width: 180 }}
                        type="date"
                        value={state?.endDate ? state?.endDate : ""}
                        onChange={(e) => {
                          handleValueChange("endDate", e.target.value);
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>회원 정보</FiltersLabel>
                    <Input
                      autoFocus={true}
                      style={{ width: 350, marginLeft: 11 }}
                      placeholder="이름, 휴대폰"
                      value={state?.keyword ? state?.keyword : ""}
                      onChange={(e) => {
                        handleValueChange("keyword", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>상태</FiltersLabel>
                    <RadioGroup
                      style={{ fontSize: 16, fontFamily: "NotoSansKR-Bold" }}
                      data={statusRadioData}
                      layout={"horizontal"}
                      value={state?.status}
                      onChange={(e) => {
                        handleValueChange("status", e.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // width: "50%",
                    }}>
                    {/* <FiltersLabel>보유량</FiltersLabel> */}
                    <Flex
                      style={{
                        fontSize: 16,
                        fontFamily: "NotoSansKR-Bold",
                        flexDirection: "row",
                        alignItems: "center",
                        display: "flex",
                        lineHeight: 2,
                      }}>
                      <select
                        style={{ width: 100, height: 35, marginRight: 11 }}
                        value={state?.amountType ? state?.amountType : ""}
                        onChange={(e) => {
                          handleValueChange("amountType", e.target.value);
                        }}>
                        <option value={"GOLD"}>금 보유량</option>
                        <option value={"SILVER"}>은 보유량</option>
                        <option value={"POINT"}>포인트 보유량</option>
                        <option value={"KRW"}>KRW 보유량</option>
                      </select>
                      &nbsp; &nbsp;
                      <Input
                        style={{ width: 150 }}
                        type="text"
                        value={state?.more ? state.more : ""}
                        onChange={(e) => {
                          const num = e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");
                          handleValueChange("more", num);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            fetchList();
                          }
                        }}
                      />
                      이상
                      <Input
                        type="text"
                        style={{ marginLeft: 5, width: 150 }}
                        value={state?.less ? state?.less : ""}
                        onChange={(e) => {
                          const num = e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");
                          handleValueChange("less", num);
                          // const num = e.target.value.replace(/[^\d.-]/g, "");
                          // if (isNaN(num)) {
                          //   if (num === ".") {
                          //     handleValueChange("less", "0.");
                          //   } else {
                          //     handleValueChange("less", e.target.value);
                          //   }
                          // } else {
                          //   const split = num.split(".");
                          //   if (split[0] !== "") {
                          //     split[0] = Number(split[0]) + "";
                          //   }
                          //   handleValueChange("less", split.join("."));
                          // }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            fetchList();
                          }
                        }}
                      />
                      이하
                    </Flex>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}>
                  <Button
                    className="buttons-container-button"
                    icon="search"
                    style={{ marginRight: 5 }}
                    onClick={fetchList}>
                    검색
                  </Button>
                  <Button
                    className="buttons-container-button"
                    icon="refresh"
                    onClick={initState}>
                    초기화
                  </Button>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </Flex>
        <LocalizationProvider language="es-KR">
          <IntlProvider locale="es">
            <Grid
              scrollable="scrollable"
              cellRender={cellRender}
              style={{
                cursor: "pointer",
                fontSize: "16px",
                height: "100%",
                maxHeight: "1050px",
              }}
              data={
                data.length !== 0
                  ? data?.map((item) => ({
                      ...item,
                      selected: selectedState[idGetter(item)],
                    }))
                  : []
              }
              resizable={true}
              skip={state.page * state.size}
              take={state.size}
              total={totalCount}
              pageable={{
                pageSizes: [10, 30, 50, 100],
                previousNext: true,
                info: true,
              }}
              navigatable={true}
              dataItemKey={DATA_ITEM_KEY}
              selectable={{
                enabled: true,
              }}
              onSelectionChange={onSelectionChange}
              onKeyDown={onKeyDown}
              selectedField="selected"
              onPageChange={pageChange}
              // onRowClick={handleClickDetail}
            >
              <Column
                field="no"
                title="번호"
                width={200}
                locked={true}
                width={80}
              />
              <Column field="createdAt" title="가입일" width={120} />
              <Column field="name" title="이름" width={100} />
              <Column field="id" title="고객번호" width={100} />
              <Column field="phone" title="휴대폰" width={100} />
              <Column
                field="recommendShopName"
                title="추천대리점"
                width={160}
              />
              <Column field="usingShopName" title="이용대리점" width={160} />
              {/* <Column field="gold" title="금(GOLD)" width={160} />
              <Column field="silver" title="은(SILVER)" width={160} />
              <Column field="point" title="포인트(POINT)" width={160} />
              <Column field="krw" title="보유원화(KRW)" width={160} /> */}
              <Column field="status" title="계정상태" width={100} />
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </Wrap>
    </DefaultLayout>
  );
};

export default MemberManagement;

//window 사용

/* {visible ? (
            <Window
              title={"Please confirm"}
              onClose={toggleDialog}
              top={0}
              width={"60%"}
              height={"100%"}
              style={{
                right: "0px",
                left: "unset",
              }}
            >
              <form className="k-form">
                <fieldset>
                  <legend>User Details</legend>

                  <label className="k-form-field">
                    <span>First Name</span>
                    <input className="k-input" placeholder="Your Name" />
                  </label>
                  <label className="k-form-field">
                    <span>Last Name</span>
                    <input className="k-input" placeholder="Your Last Name" />
                  </label>
                </fieldset>

                <div className="text-right">
                  <button
                    type="button"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleDialog}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={toggleDialog}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Window>
          ) : null} */
