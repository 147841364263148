import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import * as APIS from "../../libs/apis";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { formatTime, formatPhone } from "../../libs/utils";
import Flex from "../../components/layout/Flex";
import Button from "../../components/button/Button";
import ProductReceptionInfo from "./ProductReceptionInfo";
import ProductAppraisalInfo from "./ProductAppraisalInfo";
import ProductRegisterInfo from "./ProductRegisterInfo";
import ProductAdminMemo from "./ProductAdminMemo";
import AdminConfirmButton from "../../components/button/AdminConfirmButton";

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dbdada;
  height: 9%;
`;
const CellTitle = styled.div`
  font-weight: bold;
  width: 20%;
  padding: 10px;
`;
const CellContent = styled.div`
  width: 80%;
  padding: 10px;
`;
const Wrap = styled.div`
  .k-animation-container {
    width: 100%;
  }
`;

const InformationText = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 16px;
  font-family: NotoSansKR-Bold;
  min-width: 63px;
  margin-right: 20px;
`;

const InformationValue = styled.div`
  display: flex;
  font-size: 16px;
  font-family: NotoSansKR-Bold;
  height: fit-content;
`;

const Divider = styled.hr`
  width: 100%;
  margin: 5px 0px 0px 0px;
  border: none;
  height: 1px;
  flex-shrink: 0px;
  background-color: gray;
`;
const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const SafeTradeDetail = ({ id, fetchList, fetchSummaryList }) => {
  const detailId = id;
  const authReducer = useSelector((s) => s.auth);
  const { shopId, isKorda } = authReducer;
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state?.safeTrade || {};
  const [productHistoryList, setProductHistoryList] = useState([]);
  const [receptionInfo, setReceptionInfo] = useState({});
  const [appraisalInfo, setAppraisalInfo] = useState({});
  const [registerInfo, setRegisterInfo] = useState({});
  const [statusData, setStatusData] = useState([]);
  const [status, setStatus] = useState("");
  const [recentStatus, setRecentStatus] = useState({});
  const [historyChange, setHistoryChange] = useState("false");
  const [cancelReason, setCancelReason] = useState("");
  const navigate = useNavigate();

  const handleClickInfo = () => {
    dispatch(
      showAlert({
        title: "상품 처리 설명",
        message: <InfoProductAction />,
        onClick: () => {
          navigate(location.pathname, {
            replace: true,
            state: {
              safeTrade: { ...state, itemId: receptionInfo?.itemId },
            },
          });
        },
      })
      //   openModal({
      //     visiable: true,
      //     children: <InfoProductAction />,
      //     maskClosable: true,
      //   })
    );
  };

  //   const handleSelect = (e) => {
  //     setSelected(e.selected);
  //   };

  const onSaveAdmin = async (adminPassword, cancelReason) => {
    if (status === "") {
      dispatch(showAlert({ message: "변경할 상태를 선택하여 주세요." }));
    } else if (status === statusData[0].status) {
      dispatch(showAlert({ message: "현재 상태로 변경할 수 없습니다." }));
    } else if (
      (status === "INBOUND_CANCEL" || status === "COMPLETE_CLOSE") &&
      cancelReason === ""
    ) {
      dispatch(showAlert({ message: "취소 사유를 입력하여주세요." }));
    } else {
      const data = {
        status: status,
        cancelReason: cancelReason,
        managerPassword: adminPassword,
      };
      dispatch(showLoading());
      APIS.putChangeStatus(detailId, data)
        .then(({ data: { data, message, success } }) => {
          if (success) {
            dispatch(
              showAlert({
                title: "",
                message: "상태가 변경되었습니다.",
                onClick: () => {
                  navigate(location.pathname, {
                    replace: true,
                    state: {
                      safeTrade: { ...state, itemId: receptionInfo?.itemId },
                    },
                  });
                  fetchList(), fetchSummaryList();
                },
              })
            );
            setStatus("");
            setCancelReason("");
            setHistoryChange("true");
          } else {
            dispatch(
              showAlert({
                message: message,
                onClick: () => {
                  navigate(location.pathname, {
                    replace: true,
                    state: {
                      safeTrade: { ...state, itemId: receptionInfo?.itemId },
                    },
                  });
                },
              })
            );
          }
        })
        .catch((error) =>
          dispatch(
            showAlert({
              message: error.response.data.message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    safeTrade: { ...state, itemId: receptionInfo?.itemId },
                  },
                });
                fetchList(), fetchSummaryList();
              },
            })
          )
        )
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };

  useEffect(() => {
    //상품 이력 목록 조회
    dispatch(showLoading());
    const getProductHistoryList = APIS.getSafeTradeProductHistoryList(detailId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setProductHistoryList(data);
        } else {
          dispatch(
            showAlert({
              message: message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    safeTrade: { ...state, itemId: receptionInfo?.itemId },
                  },
                });
                fetchList(), fetchSummaryList();
              },
              f,
            })
          );
        }
      })
      .catch((err) =>
        dispatch(
          showAlert({
            message: err.response.data.message,
            onClick: () => {
              navigate(location.pathname, {
                replace: true,
                state: {
                  safeTrade: { ...state, itemId: receptionInfo?.itemId },
                },
              });
              fetchList(), fetchSummaryList();
            },
          })
        )
      );
    Promise.all([getProductHistoryList]).finally(() => {
      setHistoryChange("false");
      dispatch(hideLoading());
    });
  }, [historyChange, detailId]);

  useEffect(() => {
    dispatch(showLoading());
    //제품 상태 정보 리스트 조회
    const getProductStatusList = APIS.getSafeTradeProductStatusList(detailId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const status = {
            status: data.status,
            description: data.description,
          };
          const nextStatus = data.nextStep;
          if (nextStatus === null) {
            setStatusData([]);
            // setStatus(status.status);
            setRecentStatus(status);
          } else {
            setStatusData([{}, ...nextStatus]);
            // setStatus(status.status);
            setRecentStatus(status);
          }
        } else {
          dispatch(
            showAlert({
              message: message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    safeTrade: { ...state, itemId: receptionInfo?.itemId },
                  },
                });
                fetchList(), fetchSummaryList();
              },
            })
          );
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      );

    //제품 접수 정보 조회
    const getReceptionInfo = APIS.getSafeTradeReceptionInfo(detailId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setReceptionInfo(data);
          // const recentStatus = data.status;
          // const nextStatus = data.nextStatus;
          // if (nextStatus === null) {
          //   setStatusData([recentStatus]);
          //   setStatus(recentStatus);
          // } else {
          //   setStatusData([recentStatus, ...nextStatus]);
          //   setStatus(recentStatus);
          // }
        } else {
          dispatch(
            showAlert({
              message: message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    safeTrade: { ...state, itemId: receptionInfo?.itemId },
                  },
                });
                fetchList(), fetchSummaryList();
              },
            })
          );
        }
      })
      .catch((err) =>
        dispatch(
          showAlert({
            message: err.response.data.message,
            onClick: () => {
              navigate(location.pathname, {
                replace: true,
                state: {
                  safeTrade: { ...state, itemId: receptionInfo?.itemId },
                },
              });
              fetchList(), fetchSummaryList();
            },
          })
        )
      );

    //제품 감정 정보 조회
    const getAppraisalInfo = APIS.getSafeTradeAppraisalInfo(detailId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAppraisalInfo(data);
        } else {
          dispatch(
            showAlert({
              message: message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    safeTrade: { ...state, itemId: receptionInfo?.itemId },
                  },
                });
                fetchList(), fetchSummaryList();
              },
              f,
            })
          );
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      );

    //제품 등록 정보 조회
    const getRegisterInfo = APIS.getSafeTradeRegisterInfo(detailId)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setRegisterInfo(data);
        } else {
          dispatch(
            showAlert({
              message: message,
              onClick: () => {
                navigate(location.pathname, {
                  replace: true,
                  state: {
                    safeTrade: { ...state, itemId: receptionInfo?.itemId },
                  },
                });
                fetchList(), fetchSummaryList();
              },
            })
          );
        }
      })
      .catch((err) =>
        dispatch(
          showAlert({
            message: err.response.data.message,
            onClick: () => {
              navigate(location.pathname, {
                replace: true,
                state: {
                  safeTrade: { ...state, itemId: receptionInfo?.itemId },
                },
              });
              fetchList(), fetchSummaryList();
            },
          })
        )
      );

    Promise.all([
      getReceptionInfo,
      getAppraisalInfo,
      getRegisterInfo,
      getProductStatusList,
    ]).finally(() => {
      setHistoryChange("false");
      dispatch(hideLoading());
    });
  }, [historyChange, detailId]);

  return (
    <Wrap style={{ height: "97%" }}>
      <div
        style={{
          height: "100%",
          marginTop: 10,
          width: "100%",
        }}>
        <div style={{ width: "100%" }}>
          {/* <div> */}
          <div
            style={{
              margin: "30px 0px 10px 30px",
              width: "25%",
              float: "left",
            }}>
            <Flex
              style={{
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "row",
              }}>
              <Flex
                style={{
                  marginBottom: "5px",
                  fontSize: "large",
                  flexDirection: "column",
                }}>
                <Flex style={{ flexDirection: "row" }}>
                  <InformationText>카테고리</InformationText>
                  <InformationValue>
                    {appraisalInfo?.categoryInfo?.categoryName || "\u00A0"}
                  </InformationValue>
                </Flex>
                <Flex style={{ flexDirection: "row" }}>
                  <InformationText>상품명</InformationText>
                  <InformationValue>
                    {appraisalInfo?.name || "\u00A0"}
                  </InformationValue>
                </Flex>
                <Flex style={{ flexDirection: "row" }}>
                  <InformationText>판매번호</InformationText>
                  <InformationValue>
                    {receptionInfo?.itemId || "\u00A0"}
                  </InformationValue>
                </Flex>
                <Flex style={{ flexDirection: "row" }}>
                  <InformationText>출고상태</InformationText>
                  <InformationValue>
                    {receptionInfo?.deliveryType
                      ?.replace("RETURN", "반송")
                      ?.replace("PREVIEW", "프리뷰")
                      ?.replace("BUY", "판매") || "\u00A0"}
                  </InformationValue>
                </Flex>
                <Flex style={{ flexDirection: "row" }}>
                  <InformationText>현재상태</InformationText>
                  <InformationValue>
                    {recentStatus?.description || "\u00A0"}
                  </InformationValue>
                </Flex>
              </Flex>
            </Flex>
            <Divider />
            <Flex
              style={{
                marginTop: 5,
                fontWeight: "bold",
                fontFamily: "NotoSansKR-Bold",
                fontSize: "16px",
                background: "#f2f4f6",
                color: "#636a75",
              }}>
              진행 상태
            </Flex>
            <Flex style={{ flexDirection: "column" }}>
              {productHistoryList?.map((item) => {
                return (
                  <Flex style={{ flexDirection: "row" }}>
                    <Flex
                      style={{
                        minWidth: 130,
                        fontFamily: "NotoSansKR-Bold",
                        fontSize: "16px",
                      }}>
                      {item.description}
                    </Flex>
                    <Flex
                      style={{
                        fontFamily: "NotoSansKR-Bold",
                        fontSize: "16px",
                      }}>
                      {formatTime(item.createdAt, "YYYY-MM-DD HH:mm:ss")}
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </div>
          <div
            style={{
              margin: "0px 0px 0px 0px",
              width: "70%",
              float: "right",
            }}>
            <div style={{ display: "flex" }}>
              {receptionInfo?.sellerInfo?.shopId === shopId ? (
                <div>
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: "NotoSansKR-Bold",
                      fontWeight: "bold",
                    }}>
                    판매자
                  </div>
                  <Table>
                    <colgroup>
                      <col width="30%" />
                      <col width="20%" />
                      <col width="30%" />
                      <col width="20%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <Th>접수번호</Th>
                        <Td>{receptionInfo?.sellerInfo?.orderId || "-"}</Td>
                        <Th>고객</Th>
                        <Td>{receptionInfo?.sellerInfo?.userName || "-"}</Td>
                      </tr>
                      <tr>
                        <Th>핸드폰</Th>
                        <Td>
                          {formatPhone(
                            receptionInfo?.sellerInfo?.phoneNumber
                          ) || "-"}
                        </Td>
                        <Th>접수 대리점</Th>
                        <Td>{receptionInfo?.sellerInfo?.shopName || "-"}</Td>
                      </tr>
                      <tr>
                        <Th>대리점 방문 예약일</Th>
                        <Td>
                          {receptionInfo?.sellerInfo?.inStoreReserve || "-"}
                        </Td>
                        <Th>대리점 방문 예약시간</Th>
                        <Td>
                          {receptionInfo?.sellerInfo?.inStoreReserveTime || "-"}
                        </Td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                <></>
              )}
              {receptionInfo?.buyerInfo?.shopId === shopId ? (
                <div style={{ marginLeft: "5%" }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: "NotoSansKR-Bold",
                      fontWeight: "bold",
                    }}>
                    구매자
                  </div>
                  <Table>
                    <colgroup>
                      <col width="30%" />
                      <col width="20%" />
                      <col width="30%" />
                      <col width="20%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <Th>구매번호</Th>
                        <Td>
                          {receptionInfo?.deliveryType === "RETURN"
                            ? "-"
                            : receptionInfo?.buyerInfo?.orderId || "-"}
                        </Td>
                        <Th>고객</Th>
                        <Td>
                          {receptionInfo?.deliveryType === "RETURN"
                            ? "-"
                            : receptionInfo?.buyerInfo?.userName || "-"}
                        </Td>
                      </tr>
                      <tr>
                        <Th>핸드폰</Th>
                        <Td>
                          {receptionInfo?.deliveryType === "RETURN"
                            ? "-"
                            : formatPhone(
                                receptionInfo?.buyerInfo?.phoneNumber
                              ) || "-"}
                        </Td>
                        <Th>출고 대리점</Th>
                        <Td>
                          {receptionInfo?.deliveryType === "RETURN"
                            ? "-"
                            : receptionInfo?.buyerInfo?.shopName || "-"}
                        </Td>
                      </tr>
                      <tr>
                        <Th>대리점 방문 예약일</Th>
                        <Td>
                          {receptionInfo?.deliveryType === "RETURN"
                            ? "-"
                            : receptionInfo?.buyerInfo?.inStoreReserve || "-"}
                        </Td>
                        <Th>대리점 방문 예약 시간</Th>
                        <Td>
                          {receptionInfo?.deliveryType === "RETURN"
                            ? "-"
                            : receptionInfo?.buyerInfo?.inStoreReserveTime ||
                              "-"}
                        </Td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                <></>
              )}
            </div>

            {/* <table
              cellPadding={0}
              cellSpacing={0}
              style={{
                // borderLeft: "1px solid rgba(0,0,0,0.23)",
                // borderTop: "1px solid rgba(0,0,0,0.23)",
                margin: "30px 30px 0px 0px",
                "& th": {
                  backgroundColor: "#f5f5f5",
                  padding: 5,
                  borderRight: "1px solid rgba(0,0,0,0.23)",
                  borderBottom: "1px solid rgba(0,0,0,0.23)",
                },
                "& td": {
                  borderRight: "1px solid rgba(0,0,0,0.23)",
                  textAlign: "center",
                  padding: 8,
                  borderBottom: "1px solid rgba(0,0,0,0.23)",
                },
              }}
            >
              <thead>
                <tr>
                  {receptionInfo?.sellerInfo?.shopId === shopId ? (
                    <th
                      colSpan={6}
                      style={{ borderBottom: "1px solid rgba(0,0,0,0.23)" }}
                    >
                      판매자
                    </th>
                  ) : (
                    <></>
                  )}
                  {receptionInfo?.buyerInfo?.shopId === shopId ? (
                    <th
                      colSpan={6}
                      style={{ borderBottom: "1px solid rgba(0,0,0,0.23)" }}
                    >
                      구매자
                    </th>
                  ) : (
                    <></>
                  )}
                </tr>
                <tr>
                  {receptionInfo?.sellerInfo?.shopId === shopId ? (
                    <>
                      <th>접수번호</th>
                      <th>고객</th>
                      <th>핸드폰</th>
                      <th>접수 대리점</th>
                      <th>대리점 방문 예약일</th>
                      <th>대리점 방문 예약 시간</th>
                    </>
                  ) : (
                    <></>
                  )}
                  {receptionInfo?.buyerInfo?.shopId === shopId ? (
                    <>
                      <th>구매번호</th>
                      <th>고객</th>
                      <th>핸드폰</th>
                      <th>출고 대리점</th>
                      <th>대리점 방문 예약일</th>
                      <th>대리점 방문 예약 시간</th>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {receptionInfo?.sellerInfo?.shopId === shopId ? (
                    <>
                      <td>{receptionInfo?.sellerInfo?.orderId || "-"}</td>
                      <td>{receptionInfo?.sellerInfo?.userName || "-"}</td>
                      <td>
                        {formatPhone(receptionInfo?.sellerInfo?.phoneNumber) ||
                          "-"}
                      </td>
                      <td>{receptionInfo?.sellerInfo?.shopName || "-"}</td>
                      <td>
                        {receptionInfo?.sellerInfo?.inStoreReserve || "-"}
                      </td>
                      <td>
                        {receptionInfo?.sellerInfo?.inStoreReserveTime || "-"}
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                  {receptionInfo?.buyerInfo?.shopId === shopId ? (
                    <>
                      <td>
                        {receptionInfo?.deliveryType === "RETURN"
                          ? "-"
                          : receptionInfo?.buyerInfo?.orderId || "-"}
                      </td>
                      <td>
                        {receptionInfo?.deliveryType === "RETURN"
                          ? "-"
                          : receptionInfo?.buyerInfo?.userName || "-"}
                      </td>
                      <td>
                        {receptionInfo?.deliveryType === "RETURN"
                          ? "-"
                          : formatPhone(
                              receptionInfo?.buyerInfo?.phoneNumber
                            ) || "-"}
                      </td>
                      <td>
                        {receptionInfo?.deliveryType === "RETURN"
                          ? "-"
                          : receptionInfo?.buyerInfo?.shopName || "-"}
                      </td>
                      <td>
                        {receptionInfo?.deliveryType === "RETURN"
                          ? "-"
                          : receptionInfo?.buyerInfo?.inStoreReserve || "-"}
                      </td>
                      <td>
                        {receptionInfo?.deliveryType === "RETURN"
                          ? "-"
                          : receptionInfo?.buyerInfo?.inStoreReserveTime || "-"}
                      </td>
                    </>
                  ) : (
                    <></>
                  )}
                </tr>
              </tbody>
            </table> */}
            <Flex
              style={{
                fontFamily: "NotoSansKR-Bold",
                fontSize: "16px",
                alignItems: "center",
                "& select": {
                  margin: " 5px 10px 4px 10px",
                },
                margin: "20px 0px",
                flexDirection: "row",
              }}>
              상품 처리
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                style={{
                  width: 150,
                  height: 35,
                  marginLeft: 10,
                  marginRight: 10,
                }}>
                {statusData.map((item, index) => {
                  return (
                    <option
                      key={index}
                      label={item.description}
                      value={item.status}
                    />
                  );
                })}
              </select>
              {status === "INBOUND_CANCEL" || status === "COMPLETE_CLOSE" ? (
                <AdminConfirmButton
                  title="상품 처리 변경"
                  description={"취소 사유"}
                  callback={onSaveAdmin}
                />
              ) : (
                <AdminConfirmButton
                  title="상품 처리 변경"
                  callback={onSaveAdmin}
                />
              )}
              <Button
                onClick={() => {
                  handleClickInfo();
                }}
                style={{
                  margin: "0px 10px",
                  // fontFamily: fonts.notoSansKRBold,
                  "& span": { color: "#000" },
                  border: "1px solid black",
                }}>
                설명
              </Button>
            </Flex>
            <ProductReceptionInfo
              list={receptionInfo}
              recentStatus={recentStatus?.status}
              isKorda={isKorda}
              setHistory={(value) => setHistoryChange(value)}
            />
            <ProductAppraisalInfo
              list={appraisalInfo}
              recentStatus={recentStatus?.status}
              isKorda={isKorda}
              setList={(value) =>
                setAppraisalInfo({ ...appraisalInfo, ...value })
              }
            />
            <ProductRegisterInfo
              list={registerInfo}
              recentStatus={recentStatus?.status}
              isKorda={isKorda}
              authPrice={appraisalInfo?.authPrice}
              setList={(value) =>
                setRegisterInfo({ ...registerInfo, ...value })
              }
            />

            <ProductAdminMemo id={detailId} />
          </div>
          {/* </div> */}
        </div>
      </div>
    </Wrap>
  );
};

export default SafeTradeDetail;

const InfoProductAction = () => {
  return (
    <Flex
      style={{
        width: "850px",
        height: "420px",
        overflowY: "scroll",
        marginTop: 15,
        flexDirection: "column",
      }}>
      {/* <Flex className={classes.modal}>{"상품 처리 설명"}</Flex> */}
      <Cell>
        <CellTitle>상품 접수 확인(대리점)</CellTitle>
        <CellContent>
          판매자의 상품을 확인하고 접수를 진행하여 본사로 송부하기로 한 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 접수 반려(대리점)</CellTitle>
        <CellContent>
          판매자의 상품이 접수를 진행할 수 없는 감정불가 또는 가품 등인 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 인수</CellTitle>
        <CellContent>
          판매자의 상품을 대리점에서 송부하여 본사 관리자가 수령한 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 인수 거부</CellTitle>
        <CellContent>
          판매자의 상품 판매 신청이 접수 완료 된 후 다시 접수를 취소하는 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>반송</CellTitle>
        <CellContent>
          판매자의 상품을 돌려받기, 판매철회 등의 사유로 접수 대리점으로
          돌려보내야 하는 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>감정완료</CellTitle>
        <CellContent>
          판매자의 상품을 감정하여 감정결과를 입력 및 저장 한 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>거래종료</CellTitle>
        <CellContent>판매 절차를 강제로 종료하는 경우</CellContent>
      </Cell>
      <Cell>
        <CellTitle>반송 상품 출고</CellTitle>
        <CellContent>
          판매자의 반송 상품을 접수 대리점으로 발송한 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 출고 완료(대리점)</CellTitle>
        <CellContent>
          구매자의 구매 상품 또는 판매자의 반송 상품이 본사로부터 도착한 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>판매 목록 등록</CellTitle>
        <CellContent>
          판매자의 상품 등록을 위해 상세정보를 입력하고 판매 할 수 있는 상태로
          변경하는 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 판매 시작</CellTitle>
        <CellContent>
          판매 목록에 등록된 상품을 노출하여 판매될 수 있게 하는 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 판매 중지</CellTitle>
        <CellContent>
          판매를 위해 노출된 상품을 목록에서 제외하여 판매 대기 상태로 변경하는
          경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 출고</CellTitle>
        <CellContent>
          구매자가 구매한 상품을 수령대리점으로 발송한 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 출고 완료(대리점)</CellTitle>
        <CellContent>본사로부터 출고된 상품이 대리점에 도착한 경우</CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 전달(대리점)</CellTitle>
        <CellContent>상품을 구매자에게 전달한 경우</CellContent>
      </Cell>
      <Cell>
        <CellTitle>미방문 반송(대리점)</CellTitle>
        <CellContent>
          구매자가 대리점 미 방문 등으로 상품을 전달할 수 없게 된 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>구매 취소 확인(대리점)</CellTitle>
        <CellContent>
          구매자가 상품을 확인한 후 구매 취소한 것을 승인하고 본사로 반송하는
          경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>구매 취소 반려(대리점)</CellTitle>
        <CellContent>
          구매자가 상품을 확인한 후 구매 취소한 것을 반려하여 구매확정 처리하는
          경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 판매 시작</CellTitle>
        <CellContent>상품을 다시 판매중인 상태로 변경하는 경우</CellContent>
      </Cell>
    </Flex>
  );
};
