import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/button/Button";
import { LoginLayout } from "../../components/layout/LoginLayout";
import { STORAGE_KEY } from "../../libs/consts";
import { showAlert } from "../../store/alertReducer";
import { signIn } from "../../store/authReducer";
import * as APIS from "../../libs/apis";
import { hideLoading, showLoading } from "../../store/loadingReducer";

const Container = styled(LoginLayout)`
  min-width: 600px;

  .description {
    color: #333333;
    margin-top: 10px;
    font-weight: 500;
    font-size: 20px;
  }

  .sub {
    margin-top: 10px;
    font-size: 16px;
    color: #666666;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  input {
    color: black;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-top: 10px;
    height: 35px;
    padding: 0px 12px;
    // border: none;
    // background-color: #f2f2f2;
  }

  button {
    height: 35px;
    margin-top: 10px;
    font-weight: 700;
  }
`;

const TwoFactor = ({ onCheckPassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state || {};
  const [loginInfo, setLoginInfo] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    if (!loginInfo && !(locationState.account && locationState.password)) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    if (locationState.account && locationState.password) {
      setLoginInfo({ ...locationState });
      navigate(location.pathname, { replace: true });
    }
  }, [locationState]);

  const handleClickLogin = () => {
    if (
      verificationCode.length === 6 ||
      process.env.REACT_APP_SERVICE_TYPE === "DEVELOPMENT"
    ) {
      dispatch(showLoading());
      APIS.postLoginOtp({ ...loginInfo, verificationCode })
        .then(({ data: { success, message, data } }) => {
          const { authToken, passwordVerification, id } = data || {};
          if (success) {
            localStorage.setItem(STORAGE_KEY.accessToken, authToken);
            onCheckPassword(passwordVerification);
            localStorage.setItem("id", id);
            localStorage.setItem("userAccount", loginInfo.account);
            dispatch(signIn(authToken));
          } else {
            setVerificationCode("");
            const callback = data?.isOtpFailed ? () => navigate(-1) : () => {};
            dispatch(showAlert({ message: message, onClick: callback }));
          }
        })
        .finally(() => dispatch(hideLoading()));
    } else {
      dispatch(showAlert({ message: "OTP Code를 정확히 입력해주세요." }));
    }
  };

  const handleChangeVerificationCode = (e) => {
    const key = e.nativeEvent.data;
    if (key >= 0 && key <= 9) {
      setVerificationCode(e.target.value);
    }
  };
  return (
    <Container title="">
      <span className="description">OTP 코드를 입력하세요.</span>
      <span className="sub">OTP를 분실 시 본사에 문의해주세요.</span>
      <div className="input-wrapper">
        <input
          value={verificationCode}
          onChange={handleChangeVerificationCode}
          placeholder="Code"
          maxLength={6}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleClickLogin();
            }
          }}
          autoFocus
        />
        <Button onClick={handleClickLogin}>로그인</Button>
      </div>
    </Container>
  );
};

export default TwoFactor;
