import {
  PanelBar,
  PanelBarItem,
  Card,
  CardImage,
  CardActions,
} from "@progress/kendo-react-layout";
import React from "react";
import { useDispatch } from "react-redux";
import { numberWithCommas, formatTime } from "../../libs/utils";
import { showImageViewer } from "../../store/imageViewerReducer";
import consts from "../../libs/consts";
import Flex from "../../components/layout/Flex";
import Button from "../../components/button/Button";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const ProductReceptionInfo = (props) => {
  const { list } = props;
  const receptionInfo = list;
  const dispatch = useDispatch();
  let images = [];

  images.push(
    {
      src: receptionInfo?.frontImage ? receptionInfo?.frontImage : "",
      title: receptionInfo?.frontImage,
    },
    {
      src: receptionInfo?.sideImage ? receptionInfo?.sideImage : "",
      title: receptionInfo?.sideImage,
    },
    {
      src: receptionInfo?.backImage ? receptionInfo?.backImage : "",
      title: receptionInfo?.backImage,
    },
    {
      src: receptionInfo?.itemImage ? receptionInfo?.itemImage : "",
      title: receptionInfo?.itemImage,
    },
    {
      src: receptionInfo?.contractImage ? receptionInfo?.contractImage : "",
      title: receptionInfo?.contractImage,
    },
    {
      src: receptionInfo?.partsImage ? receptionInfo?.partsImage : "",
      title: receptionInfo?.partsImage,
    }
  );

  return (
    <PanelBar
      style={{ width: "100%", marginBottom: 20, border: "1px solid #9e9e9e" }}
    >
      <PanelBarItem expanded={false} title={"상품 접수 정보"}>
        <Flex
          style={{ width: "-webkit-fill-available", flexDirection: "column" }}
        >
          <Table>
            <colgroup>
              <col width="8%" />
              <col width="20%" />
              <col width="8%" />
              <col width="20%" />
            </colgroup>
            <tbody>
              <tr>
                <Th>접수번호</Th>
                <Td>{receptionInfo?.sellerInfo?.orderId || "-"}</Td>
                <Th>카테고리</Th>
                <Td>{receptionInfo?.categoryInfo?.categoryName || "-"}</Td>
              </tr>
              <tr>
                <Th>브랜드(제조사)</Th>
                <Td>{receptionInfo?.categoryInfo?.brand || "-"}</Td>
                <Th>제품명(모델명)</Th>
                <Td>{receptionInfo.name || "-"}</Td>
              </tr>
              <tr>
                <Th>제조출시년도</Th>
                <Td>
                  {receptionInfo.outDate
                    ? formatTime(receptionInfo.outDate, "YYYY") + "년"
                    : "-"}
                </Td>
                <Th>구매가격</Th>
                <Td>
                  {receptionInfo?.buyPrice
                    ? numberWithCommas(receptionInfo?.buyPrice) + " 원"
                    : "-"}
                </Td>
              </tr>
              <tr>
                <Th>구매처</Th>
                <Td>{receptionInfo.buyStore || "-"}</Td>
                <Th>구입년도</Th>
                <Td>
                  {receptionInfo.buyDate
                    ? formatTime(receptionInfo.buyDate, "YYYY") + "년"
                    : "-"}
                </Td>
              </tr>
              <tr>
                <Th>제품상태</Th>
                <Td>{receptionInfo?.state || "-"}</Td>
                <Th>판매 희망가격</Th>
                <Td>
                  {receptionInfo?.currentPrice
                    ? numberWithCommas(receptionInfo?.currentPrice) + " 원"
                    : "-"}
                </Td>
              </tr>
              <tr>
                <Th>보증서 여부</Th>
                <Td>{formatTime(receptionInfo.isContract) || "-"}</Td>
                <Th>구성품 여부</Th>
                <Td>{formatTime(receptionInfo?.isParts) || "-"}</Td>
              </tr>
              <tr>
                <Th>사용자 메모</Th>
                <Td>{receptionInfo?.memo || "-"}</Td>
                <Th>접수 취소 사유</Th>
                <Td>{receptionInfo?.cancelReason || "-"}</Td>
              </tr>
            </tbody>
          </Table>

          <div
            style={{
              justifyContent: "center",
              margin: "5px 50px",
              "& div": {
                display: "flex",
              },
            }}
          >
            <div style={{ display: "flex" }}>
              <Flex
                style={{
                  width: "57%",
                  marginRight: "1%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    // border: "1px solid rgb(220,220,220)",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById(`image_btn_front`).click();
                  }}
                >
                  {receptionInfo?.frontImage ? (
                    <Card style={{ height: "inherit" }}>
                      <CardImage
                        src={consts.s3BaseUrl + "/" + receptionInfo?.frontImage}
                        style={{
                          borderRadius: 10,
                          cursor: "pointer",
                          height: "100%",
                        }}
                      />
                      <CardActions style={{ alignSelf: "center" }}>
                        <Button
                          icon={"search"}
                          id={`image_btn_front`}
                          onClick={() => {
                            dispatch(
                              showImageViewer({
                                images: images ? images : "",
                                activeIndex: images.findIndex((item) =>
                                  item.title
                                    ? item.title === receptionInfo?.frontImage
                                    : 0
                                ),
                              })
                            );
                          }}
                        />
                      </CardActions>
                    </Card>
                  ) : (
                    <span
                      class="k-icon k-i-image"
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: 40,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  전면
                </div>
              </Flex>
              <Flex
                style={{
                  width: "57%",
                  marginRight: "1%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    // border: "1px solid rgb(220,220,220)",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById(`image_btn_side`).click();
                  }}
                >
                  {receptionInfo?.sideImage ? (
                    <Card style={{ height: "inherit" }}>
                      <CardImage
                        src={consts.s3BaseUrl + "/" + receptionInfo?.sideImage}
                        style={{
                          borderRadius: 10,
                          cursor: "pointer",
                          height: "100%",
                        }}
                      />
                      <CardActions style={{ alignSelf: "center" }}>
                        <Button
                          icon={"search"}
                          id={`image_btn_side`}
                          onClick={() => {
                            dispatch(
                              showImageViewer({
                                images: images ? images : "",
                                activeIndex: images.findIndex((item) =>
                                  item.title
                                    ? item.title === receptionInfo?.sideImage
                                    : 0
                                ),
                              })
                            );
                          }}
                        />
                      </CardActions>
                    </Card>
                  ) : (
                    <span
                      class="k-icon k-i-image"
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: 40,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  측면
                </div>
              </Flex>
              <Flex
                style={{
                  width: "57%",
                  marginRight: "1%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    // border: "1px solid rgb(220,220,220)",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById(`image_btn_back`).click();
                  }}
                >
                  {receptionInfo?.backImage ? (
                    <Card style={{ height: "inherit" }}>
                      <CardImage
                        src={consts.s3BaseUrl + "/" + receptionInfo?.backImage}
                        style={{
                          borderRadius: 10,
                          cursor: "pointer",
                          height: "100%",
                        }}
                      />
                      <CardActions style={{ alignSelf: "center" }}>
                        <Button
                          icon={"search"}
                          id={`image_btn_back`}
                          onClick={() => {
                            dispatch(
                              showImageViewer({
                                images: images ? images : "",
                                activeIndex: images.findIndex((item) =>
                                  item.title
                                    ? item.title === receptionInfo?.backImage
                                    : 0
                                ),
                              })
                            );
                          }}
                        />
                      </CardActions>
                    </Card>
                  ) : (
                    <span
                      class="k-icon k-i-image"
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: 40,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  뒷면
                </div>
              </Flex>
              <Flex
                style={{
                  width: "57%",
                  marginRight: "1%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    // border: "1px solid rgb(220,220,220)",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById(`image_btn_item`).click();
                  }}
                >
                  {receptionInfo?.itemImage ? (
                    <Card style={{ height: "inherit" }}>
                      <CardImage
                        src={consts.s3BaseUrl + "/" + receptionInfo?.itemImage}
                        style={{
                          borderRadius: 10,
                          cursor: "pointer",
                          height: "100%",
                        }}
                      />
                      <CardActions style={{ alignSelf: "center" }}>
                        <Button
                          icon={"search"}
                          id={`image_btn_item`}
                          onClick={() => {
                            dispatch(
                              showImageViewer({
                                images: images ? images : "",
                                activeIndex: images.findIndex((item) =>
                                  item.title
                                    ? item.title === receptionInfo?.itemImage
                                    : 0
                                ),
                              })
                            );
                          }}
                        />
                      </CardActions>
                    </Card>
                  ) : (
                    <span
                      class="k-icon k-i-image"
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: 40,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  모델명
                </div>
              </Flex>
              <Flex
                style={{
                  width: "57%",
                  marginRight: "1%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    // border: "1px solid rgb(220,220,220)",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById(`image_btn_contract`).click();
                  }}
                >
                  {receptionInfo?.contractImage ? (
                    <Card style={{ height: "inherit" }}>
                      <CardImage
                        src={
                          consts.s3BaseUrl + "/" + receptionInfo?.contractImage
                        }
                        style={{
                          borderRadius: 10,
                          cursor: "pointer",
                          height: "100%",
                        }}
                      />
                      <CardActions style={{ alignSelf: "center" }}>
                        <Button
                          icon={"search"}
                          id={`image_btn_contract`}
                          onClick={() => {
                            dispatch(
                              showImageViewer({
                                images: images ? images : "",
                                activeIndex: images.findIndex((item) =>
                                  item.title
                                    ? item.title ===
                                      receptionInfo?.contractImage
                                    : 0
                                ),
                              })
                            );
                          }}
                        />
                      </CardActions>
                    </Card>
                  ) : (
                    <span
                      class="k-icon k-i-image"
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: 40,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  보증서
                </div>
              </Flex>
              <Flex
                style={{
                  width: "57%",
                  marginRight: "1%",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    height: "100%",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById(`image_btn_parts`)?.click();
                  }}
                >
                  {receptionInfo?.partsImage ? (
                    <Card style={{ height: "inherit" }}>
                      <CardImage
                        src={consts.s3BaseUrl + "/" + receptionInfo?.partsImage}
                        style={{
                          borderRadius: 10,
                          cursor: "pointer",
                          height: "100%",
                        }}
                      />
                      <CardActions style={{ alignSelf: "center" }}>
                        <Button
                          icon={"search"}
                          id={`image_btn_parts`}
                          onClick={() => {
                            dispatch(
                              showImageViewer({
                                images: images ? images : "",
                                activeIndex: images.findIndex((item) =>
                                  item.title
                                    ? item.title === receptionInfo?.partsImage
                                    : 0
                                ),
                              })
                            );
                          }}
                        />
                      </CardActions>
                    </Card>
                  ) : (
                    <span
                      class="k-icon k-i-image"
                      style={{
                        alignSelf: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: 40,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  구성품
                </div>
              </Flex>
            </div>
          </div>
        </Flex>
      </PanelBarItem>
    </PanelBar>
  );
};

export default ProductReceptionInfo;
