const STORAGE_PREPARE = "kumbang.manager.";
const STORAGE_KEYS = {
  accessToken: "accessToken",
  tableSize: "tableSize",
};

export const STORAGE_KEY = {};
for (let key in STORAGE_KEYS) {
  STORAGE_KEY[key] = STORAGE_PREPARE + STORAGE_KEYS[key];
}

export default {
  s3BaseUrl: process.env.REACT_APP_S3_BASE_URL,
};
