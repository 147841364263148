import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Barcode from "react-barcode";
import { formatTime, numberWithCommas } from "../../libs/utils";

const Table = styled.table`
  width: 100%;
  margin: 0px 0px 10px 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.23);
  border-top: 1px solid rgba(0, 0, 0, 0.23);
`;
const Th = styled.th`
  text-align: center;
  background-color: #f5f5f5;
  padding: 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.23);
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
`;
const Td = styled.td`
  border-right: 1px solid rgba(0, 0, 0, 0.23);
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  text-align: right;
  padding: 8px;
`;

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { tableData, goldProduct, silverProduct, type, appraisalWeightGram } =
    props;
  const [appraisalList, setAppraisalList] = useState(
    type === "GOLD" ? goldProduct : silverProduct
  );
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  let today = new Date();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  const productWeightGramSum = appraisalList?.reduce(
    (accumulator, currentObject) => {
      return Number(accumulator) + Number(currentObject.productWeightGram);
    },
    0
  );
  const appraisalWeightGramSum = appraisalList?.reduce(
    (accumulator, currentObject) => {
      return Number(accumulator) + Number(currentObject.appraisalWeightGram);
    },
    0
  );

  return (
    <div ref={ref} style={{ border: "1px solid #d9d9d9", padding: 30 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div style={{ display: "flex", fontSize: "x-large" }}>
          <div>일시 : </div>
          <div style={{ fontWeight: "bold" }}>
            {formatTime(new Date(), "YYYY년 MM월 DD일") +
              `(${week[new Date().getDay()]}요일)`}
          </div>
        </div>
        <div style={{ fontWeight: "bold", fontSize: "x-large" }}>
          금방금방 감정평가 계산표
        </div>
      </div>
      <div
        style={{
          borderBottomStyle: "double",
        }}
      />
      <div
        style={{
          display: "flex",
          marginTop: 40,
          justifyContent: "space-between",
        }}>
        <div style={{ fontSize: "x-large" }}>
          대리점명 : {tableData?.receptionStore}
        </div>
        <div style={{ fontSize: "x-large" }}>
          {hours <= 12 ? "오전 " : "오후 "}
          {hours + " : " + minutes + " : " + seconds}
        </div>
      </div>
      <div
        style={{
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        }}
      />
      <div
        style={{
          display: "flex",
          marginTop: 10,
          justifyContent: "space-between",
        }}>
        <div
          style={{
            alignSelf: "flex-end",
            marginRight: 30,
            fontSize: "x-large",
          }}>
          회원명(번호) : {`${tableData?.memberName} (${tableData?.memberId})`}
        </div>
        <div>
          <Barcode
            width={0.8}
            height={40}
            format={"CODE128"}
            displayValue={false}
            value={tableData?.receptionNumber}
          />
          <div style={{ display: "flex", alignSelf: "flex-end" }}>
            <div style={{ fontSize: "large" }}>접수번호 : </div>
            <div style={{ fontSize: "large", alignSelf: "center" }}>
              {tableData?.receptionNumber}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottomStyle: "solid",
          borderBottomWidth: 1,
        }}
      />
      <div style={{ marginTop: 15 }}>
        <Table cellPadding={0} cellSpacing={0}>
          <tr>
            <Th colSpan={2}>{type === "GOLD" ? "금(GOLD)" : "은(SILVER)"}</Th>
            <Th colSpan={3}></Th>
          </tr>
          <tr>
            <Th>구분</Th>
            <Th>품목</Th>
            <Th>기준비율</Th>
            <Th>측정 실중량(g)</Th>
            <Th>자산교환 중량(g)</Th>
          </tr>
          {appraisalList?.map((item) => {
            return (
              <tr>
                <Td>{item.purityType}</Td>
                <Td>{item.name}</Td>
                <Td>{`${item.basicPct}%`}</Td>
                <Td>{numberWithCommas(item.productWeightGram)}</Td>
                <Td>{numberWithCommas(item.appraisalWeightGram)}</Td>
              </tr>
            );
          })}
          <tr>
            <Td colSpan={3} style={{ textAlign: "center" }}>
              합계(본사 송부 중량)
            </Td>
            <Td>
              {productWeightGramSum
                ? numberWithCommas(productWeightGramSum)
                : "-"}
            </Td>
            <Td>
              {appraisalWeightGram
                ? numberWithCommas(appraisalWeightGram)
                : numberWithCommas(appraisalWeightGramSum)}
            </Td>
          </tr>
          <tr>
            <Td
              colSpan={4}
              style={{
                whiteSpace: "pre-line",
                textAlign: "initial",
              }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>{`1) 위와 같이 감정평가 되었음을 확인합니다. 
                2) 감정평가 대상 제품이 장물로 판단될 경우 경찰서에 통보됨을 확인합니다.`}</div>
                <div style={{ alignSelf: "flex-end" }}>{`(인)`}</div>
              </div>
            </Td>
            <Td></Td>
          </tr>
        </Table>
      </div>
    </div>
  );
});

export default ComponentToPrint;
