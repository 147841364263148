import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  GridColumn,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import Button from "../../components/button/Button";
import DefaultLayout from "../../../src/components/layout/DefaultLayout";
import Flex from "../../components/layout/Flex";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useDispatch, useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { objToQueryStr, formatTime, numberWithCommas } from "../../libs/utils";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import * as APIS from "../../libs/apis";
import { getter } from "@progress/kendo-react-common";
import esMessages from "../../libs/es.json";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import SafeTradeDetail from "./SafeTradeDetail";
import { createIndex } from "../../libs/utils";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

loadMessages(esMessages, "es-KR");
const DATA_ITEM_KEY = "outBoundOrderBookId";
const DETAIL_ID = "itemId";
const idGetter = getter(DETAIL_ID);
const createdAtGetter = getter(DATA_ITEM_KEY);

const initialDataState = {
  skip: 0,
  take: 100,
};

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dbdada;
  height: 9%;
`;
const CellTitle = styled.div`
  font-weight: bold;
  width: 20%;
  padding: 10px;
`;
const CellContent = styled.div`
  width: 80%;
  padding: 10px;
`;

const FiltersLabel = styled.label`
  margin-left: 10px;
  width: 100px;
  align-self: center;
  font-family: NotoSansKR-Bold;
  font-size: 16px;
`;

const Wrap = styled.div`
  position: relative;
  height: 100%;
  .k-animation-container {
    width: 100%;
  }
  .k-animation-container-relative {
    height: 100%;
  }
  .k-child-animation-container {
    height: 100%;
  }
  .k-grid-container {
    height: 50px;
    overflow: "scroll";
  }
`;

const TotalWrap = styled.div`
  min-width: 1112px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1050px;

  .k-grid .k-grid-container {
    height: 50px;
  }
`;

const SafeTrade = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = location.state?.safeTrade || {};
  const [page, setPage] = useState(initialDataState);
  const [totalCount, setTotalCount] = useState(0);
  const [filterExpanded, setFilterExpanded] = useState(true);
  const [selected, setSelected] = useState(0);
  const [summary, setSummary] = useState([]);
  const [progressStatusList, setProgressStatusList] = useState([]);
  const [searchReception, setSearchReception] = useState();
  const [selectedState, setSelectedState] = useState({});
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [registerList, setRegisterList] = useState([]);
  const [releaseList, setReleaseList] = useState([]);
  const [saleList, setSaleList] = useState([]);
  const [registerTotalCount, setRegisterTotalCount] = useState("");
  const [releaseTotalCount, setReleaseTotalCount] = useState("");
  const [saleTotalCount, setSaleTotalCount] = useState("");
  const [tab, setTab] = useState("");
  const [detailComponent, setDetailComponent] = useState(null);
  const [detailId, setDetailId] = useState("");
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  const initState = () => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        safeTrade: {
          registerPage: page.skip,
          registerSize: page.take,
          salePage: page.skip,
          saleSize: page.take,
          releasePage: page.skip,
          releaseSize: page.take,
          startDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          dateType: "CREATED",
          tab: "REGISTER",
          numberType: "ORDER_ID",
          userType: "ALL",
          shopType: "ALL",
          numberKeyword: "",
          categoryId: "",
          name: "",
          inventoryLocation: "",
          userKeyword: "",
          shopKeyword: "",
          statusLevel: "",
          status: "",
        },
      },
    });
  };

  const handleValueChange = (key, value, more) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        safeTrade: {
          ...location.state?.safeTrade,
          [key]: value || "",
          ...more,
        },
      },
    });
  };

  const summaryFetchList = (type) => {
    let statusData = "";

    if (type) {
      statusData = progressStatusList?.find((item) => item.status === type);
    }

    const progressParam = {
      page:
        selected === 0
          ? state.registerPage
          : selected === 1
          ? state.salePage
          : state.releasePage,
      size:
        selected === 0
          ? state.registerSize
          : selected === 1
          ? state.saleSize
          : state.releaseSize,
      status: type,
      statusLevel: statusData?.statusLevel,
    };

    const progressParamQuery = objToQueryStr(progressParam);
    const registerArr = [];
    const saleArr = [];
    const releaseArr = [];
    if (Object.keys(progressParam).length !== 0) {
      dispatch(showLoading());
      APIS.getSafeTradeProductList(progressParamQuery)
        .then(
          ({
            data: {
              success,
              message,
              data: { registerPage, releasePage, sellPage, statusLevel },
            },
          }) => {
            if (success) {
              return (
                statusLevel === "REGISTER" ||
                statusLevel === "SALE" ||
                statusLevel === "RELEASE"
                  ? (setTab(statusLevel),
                    setSelected(
                      statusLevel === "REGISTER"
                        ? 0
                        : statusLevel === "SALE"
                        ? 1
                        : statusLevel === "RELEASE"
                        ? 2
                        : 0
                    ),
                    setRegisterList(registerPage?.content),
                    setRegisterTotalCount(registerPage?.totalElements),
                    setReleaseList(releasePage?.content),
                    setReleaseTotalCount(releasePage?.totalElements),
                    setSaleList(sellPage?.content),
                    setSaleTotalCount(sellPage?.totalElements))
                  : (registerPage?.content.find((item) => {
                      progressStatusList.forEach((progressStatus) => {
                        if (progressStatus.status === item.status) {
                          if (progressStatus.statusLevel === "REGISTER") {
                            registerArr.push(item);
                          }
                        }
                      });
                    }),
                    sellPage?.content.find((item) => {
                      progressStatusList.forEach((progressStatus) => {
                        if (progressStatus.status === item.status) {
                          if (progressStatus.statusLevel === "SALE") {
                            saleArr.push(item);
                          }
                        }
                      });
                    }),
                    releasePage?.content.find((item) => {
                      progressStatusList.forEach((progressStatus) => {
                        if (progressStatus.status === item.status) {
                          if (progressStatus.statusLevel === "RELEASE") {
                            releaseArr.push(item);
                          }
                        }
                      });
                    }),
                    setRegisterList(registerArr),
                    setSaleList(saleArr),
                    setReleaseList(releaseArr),
                    setRegisterTotalCount(registerArr.length || "0"),
                    setSaleTotalCount(saleArr.length || "0"),
                    setReleaseTotalCount(releaseArr.length || "0")),
                setSearchReception(type)
              );
            } else {
              dispatch(showAlert({ message: message }));
            }
          }
        )
        .catch((err) =>
          dispatch(showAlert({ message: err.response.data.message }))
        )
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };
  const fetchList = () => {
    let statusData = "";

    if (state.status) {
      statusData = progressStatusList?.find(
        (item) => item.status === state.status
      );
    } else {
      statusData = tab;
    }

    const progressParam = {
      ...state,
      page:
        selected === 0
          ? state.registerPage
          : selected === 1
          ? state.salePage
          : state.releasePage,
      size:
        selected === 0
          ? state.registerSize
          : selected === 1
          ? state.saleSize
          : state.releaseSize,
      statusLevel: state.status ? statusData?.statusLevel : statusData,
    };

    delete progressParam["registerList"];
    delete progressParam["releaseList"];
    delete progressParam["saleList"];
    delete progressParam["registerTotal"];
    delete progressParam["releaseTotal"];
    delete progressParam["saleTotal"];

    delete progressParam["registerPage"];
    delete progressParam["registerSize"];
    delete progressParam["salePage"];
    delete progressParam["saleSize"];
    delete progressParam["releasePage"];
    delete progressParam["releaseSize"];

    const progressParamQuery = objToQueryStr(progressParam);
    const registerArr = [];
    const saleArr = [];
    const releaseArr = [];
    if (Object.keys(progressParam).length !== 0) {
      dispatch(showLoading());
      APIS.getSafeTradeProductList(progressParamQuery)
        .then(
          ({
            data: {
              success,
              message,
              data: { registerPage, releasePage, sellPage, statusLevel },
            },
          }) => {
            if (success) {
              return (
                statusLevel === "REGISTER" ||
                statusLevel === "SALE" ||
                statusLevel === "RELEASE"
                  ? (setTab(statusLevel),
                    setSelected(
                      statusLevel === "REGISTER"
                        ? 0
                        : statusLevel === "SALE"
                        ? 1
                        : statusLevel === "RELEASE"
                        ? 2
                        : 0
                    ),
                    setRegisterList(registerPage?.content),
                    setRegisterTotalCount(registerPage?.totalElements),
                    setReleaseList(releasePage?.content),
                    setReleaseTotalCount(releasePage?.totalElements),
                    setSaleList(sellPage?.content),
                    setSaleTotalCount(sellPage?.totalElements))
                  : (registerPage?.content.find((item) => {
                      progressStatusList.forEach((progressStatus) => {
                        if (progressStatus.status === item.status) {
                          if (progressStatus.statusLevel === "REGISTER") {
                            registerArr.push(item);
                          }
                        }
                      });
                    }),
                    sellPage?.content.find((item) => {
                      progressStatusList.forEach((progressStatus) => {
                        if (progressStatus.status === item.status) {
                          if (progressStatus.statusLevel === "SALE") {
                            saleArr.push(item);
                          }
                        }
                      });
                    }),
                    releasePage?.content.find((item) => {
                      progressStatusList.forEach((progressStatus) => {
                        if (progressStatus.status === item.status) {
                          if (progressStatus.statusLevel === "RELEASE") {
                            releaseArr.push(item);
                          }
                        }
                      });
                    }),
                    setTab(statusLevel),
                    setRegisterList(registerArr),
                    setSaleList(saleArr),
                    setReleaseList(releaseArr),
                    setRegisterTotalCount(registerArr.length || "0"),
                    setSaleTotalCount(saleArr.length || "0"),
                    setReleaseTotalCount(releaseArr.length || "0")),
                setSearchReception("")
              );
            } else {
              dispatch(showAlert({ message: message }));
            }
          }
        )
        .catch((err) =>
          dispatch(showAlert({ message: err.response.data.message }))
        )
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };

  const pageChange = (event) => {
    selected === 0 //Register
      ? (navigate(location.pathname, {
          replace: true,
          state: {
            safeTrade: {
              ...location.state?.safeTrade,
              registerPage: event.page.skip / event.page.take,
              registerSize: event.page.take,
            },
          },
        }),
        setRegisterList(event.target.props.data))
      : selected === 1 //Sale
      ? (navigate(location.pathname, {
          replace: true,
          state: {
            safeTrade: {
              ...location.state?.safeTrade,
              salePage: event.page.skip / event.page.take,
              saleSize: event.page.take,
            },
          },
        }),
        setSaleList(event.target.props.data))
      : (navigate(location.pathname, {
          //RELEASE
          replace: true,
          state: {
            safeTrade: {
              ...location.state?.safeTrade,
              releasePage: event.page.skip / event.page.take,
              releaseSize: event.page.take,
            },
          },
        }),
        setReleaseList(event.target.props.data));
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: selected === 2 ? DATA_ITEM_KEY : DETAIL_ID,
    });
    selected === 2 &&
      setDetailId(
        event.dataItems.filter(
          (item) =>
            String(item.outBoundOrderBookId) ===
            Object.keys(newSelectedState)[0]
        )[0].itemId
      );
    setSelectedState(newSelectedState);
  };

  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: selected === 2 ? DATA_ITEM_KEY : DETAIL_ID,
    });
    selected === 2 &&
      setDetailId(
        event.dataItems.filter(
          (item) =>
            String(item.outBoundOrderBookId) ===
            Object.keys(newSelectedState)[0]
        )[0].itemId
      );
    setSelectedState(newSelectedState);
  };

  const handleClickInfo = (type) => {
    dispatch(
      showAlert({
        title: "상품 처리 설명",
        message: type === "filtersState" ? <InfoState /> : <InfoSummary />,
        onClick: () => {
          navigate(location.pathname, {
            replace: true,
            state: {
              safeTrade: {
                ...location.state?.safeTrade,
              },
            },
          });
        },
      })
    );
  };

  const clearDetail = () => {
    setDetailComponent(null);
  };

  const handleClickDetail = (props) => {
    const newData = (
      tab === "REGISTER"
        ? registerList
        : tab === "SALE"
        ? saleList
        : tab === "RELEASE"
        ? releaseList
        : []
    ).map((item) => ({
      ...item,
      selected:
        selected === 2
          ? item.outBoundOrderBookId === props.dataItem.outBoundOrderBookId
          : item.itemId === props.dataItem.itemId,
    }));
    if (tab === "REGISTER") {
      setRegisterList(newData);
    } else if (tab === "SALE") {
      setSaleList(newData);
    } else if (tab === "RELEASE") {
      setReleaseList(newData);
    }
    const itemId = props.dataItem.itemId;
    setDetailComponent(() => {
      return null;
    });

    setDetailComponent(() => {
      return (
        <SafeTradeDetail
          id={selected === 2 ? [String(itemId)] : Object.keys(selectedState)}
          fetchList={fetchList}
          fetchSummaryList={fetchSummaryList}
        />
      );
    });
  };

  useEffect(() => {
    // Filters 카테고리 조회
    dispatch(showLoading());
    APIS.getSafeTradeFitersList()
      .then(
        ({
          data: {
            success,
            message,
            data: { categoryList, statusList },
          },
        }) => {
          if (success) {
            setCategory([
              ...categoryList.map(({ categoryId, name }) => ({
                label: name,
                value: categoryId,
              })),
            ]);
            setProgressStatusList(statusList);
            const searchTypeList = statusList.map((item) => {
              return { statusLevel: item.statusLevel };
            });
            const deduplicationType = searchTypeList.filter(
              (dayName, idx, arr) => {
                return (
                  arr.findIndex(
                    (item) => item.statusLevel === dayName.statusLevel
                  ) === idx
                );
              }
            );
            let parseData = {};
            let arrayData = [];

            deduplicationType.forEach((type) => {
              statusList.forEach((item) => {
                if (item.statusLevel === type.statusLevel) {
                  arrayData.push(item);
                  parseData[type.statusLevel] = [...arrayData];
                }
              });
              arrayData = [];
            });
            setStatus(parseData);
          } else {
            dispatch(showAlert({ message: message }));
          }
        }
      )
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  }, []);

  useEffect(() => {
    if (Object.keys(state).length === 0) {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          safeTrade: {
            // page: page.skip,
            // size: page.take,
            registerPage: page.skip,
            registerSize: page.take,
            salePage: page.skip,
            saleSize: page.take,
            releasePage: page.skip,
            releaseSize: page.take,
            startDate: formatTime(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7
              ),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(),
            dateType: "CREATED",
            tab: "REGISTER",
            numberType: "ORDER_ID",
            userType: "ALL",
            shopType: "ALL",
            numberKeyword: "",
            categoryId: "",
            name: "",
            inventoryLocation: "",
            userKeyword: "",
            shopKeyword: "",
            statusLevel: "",
            status: "",
          },
        },
      });
    }
  }, []);
  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "no" ||
      props.field === "orderId" ||
      props.field === "seller" ||
      props.field === "sellerUserId" ||
      props.field === "sellShop" ||
      props.field === "itemId" ||
      props.field === "category" ||
      props.field === "status" ||
      props.field === "outBoundOrderBookId" ||
      props.field === "buyerUserId" ||
      props.field === "buyer" ||
      props.field === "buyShop" ||
      props.field === "inStoreReserve"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "orderPrice" ||
      props.field === "authPrice" ||
      props.field === "currentPrice"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {numberWithCommas(props.dataItem[props.field])}
        </td>
      );
    } else if (
      props.field === "orderId" ||
      props.field === "itemId" ||
      props.field === "sellerUserId"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (props.field === "createdAt" || props.field === "updatedAt") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {formatTime(props.dataItem[props.field], "YYYY-MM-DD HH:mm")}
        </td>
      );
    }
    return cell;
  };

  const fetchSummaryList = () => {
    dispatch(showLoading());
    APIS.getSafeTradeSummary()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const changeData = data?.map((item) => ({
            title: "건수",
            inboundWaitCount:
              data?.find((item) => item.status === "INBOUND_WAIT")?.count || 0,
            sellCount: data?.find((item) => item.status === "SELL")?.count || 0,
            deliveryCount:
              data?.find((item) => item.status === "DELIVERY")?.count || 0,
            transferWaitCount:
              data?.find((item) => item.status === "TRANSFER_WAIT")?.count || 0,
            transferCancelCount:
              data?.find((item) => item.status === "TRANSFER_CANCEL")?.count ||
              0,
          }));
          setSummary([changeData[0]]);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };

  useLayoutEffect(() => {
    // 접수현황 summary 조회
    // if (state.page || state.size) {
    fetchSummaryList();
    // }
    // }, [state.page, state.size]);
  }, []);
  useEffect(() => {
    if (!searchReception) {
      fetchList();
    } else {
      summaryFetchList(searchReception);
    }
  }, [
    state.registerPage,
    state.registerSize,
    state.salePage,
    state.saleSize,
    state.releaseSize,
    state.releasePage,
  ]);

  useEffect(() => {
    if (Object.keys(selectedState).length !== 0) {
      setDetailComponent(() => {
        return null;
      });
      setDetailComponent(() => {
        return (
          <SafeTradeDetail
            id={selected === 2 ? detailId : Object.keys(selectedState)}
            fetchList={fetchList}
            fetchSummaryList={fetchSummaryList}
          />
        );
      });
    }
  }, [selectedState]);

  // useEffect(() => {
  //   if (state.statusLevel) {
  //     handleValueChange(
  //       "status",
  //       status[`${state.statusLevel.value}`]?.[0].status
  //     );
  //   }
  // }, [state.statusLevel]);
  return (
    <DefaultLayout detail={detailComponent} clearDetail={clearDetail}>
      <TotalWrap>
        <Grid
          style={{
            width: "100%",
          }}
          scrollable={"none"}
          data={summary ? summary : []}>
          <GridColumn field="title" title="구분" width="40px" />
          <GridColumn
            field="inboundWaitCount"
            title="접수대기"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setSearchReception("INBOUND_WAIT");
                        summaryFetchList("INBOUND_WAIT");
                      }}>
                      {numberWithCommas(dataItem?.inboundWaitCount) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="sellCount"
            title="판매 중"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setSearchReception("SELL");
                        summaryFetchList("SELL");
                      }}>
                      {numberWithCommas(dataItem?.sellCount) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="deliveryCount"
            title="출고 중"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setSearchReception("DELIVERY");
                        summaryFetchList("DELIVERY");
                      }}>
                      {numberWithCommas(dataItem?.deliveryCount) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="transferWaitCount"
            title="인도 대기"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setSearchReception("TRANSFER_WAIT");
                        summaryFetchList("TRANSFER_WAIT");
                      }}>
                      {numberWithCommas(dataItem?.transferWaitCount) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="transferCancelCount"
            title="구매자 구매 취소"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setSearchReception("TRANSFER_CANCEL");
                        summaryFetchList("TRANSFER_CANCEL");
                      }}>
                      {numberWithCommas(dataItem?.transferCancelCount) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
        </Grid>
        <div
          style={{
            marginTop: 10,
            width: "100%",
          }}>
          <PanelBar style={{ border: "1px solid #9e9e9e" }}>
            <PanelBarItem expanded={filterExpanded} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      marginLeft: 10,
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                    }}>
                    <select
                      value={state?.dateType ? state?.dateType : ""}
                      onChange={(e) => {
                        handleValueChange("dateType", e.target.value);
                      }}>
                      <option value={"CREATED"}>요청일</option>
                      <option value={"UPDATED"}>수정일</option>
                    </select>
                    &nbsp; &nbsp;
                    <input
                      type="date"
                      value={state?.startDate ? state.startDate : ""}
                      onChange={(e) => {
                        handleValueChange("startDate", e.target.value);
                      }}
                    />
                    &nbsp;~&nbsp;
                    <input
                      type="date"
                      value={state?.endDate ? state?.endDate : ""}
                      onChange={(e) => {
                        handleValueChange("endDate", e.target.value);
                      }}
                    />
                  </Flex>
                  <Flex
                    style={{
                      marginLeft: 10,
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                    }}>
                    <select
                      value={state?.numberType ? state?.numberType : ""}
                      onChange={(e) => {
                        handleValueChange("numberType", e.target.value);
                      }}>
                      <option value={"ORDER_ID"}>접수번호</option>
                      <option value={"ITEM_ID"}>판매번호</option>
                      <option value={"OUTBOUND_ORDER_BOOK_ID"}>구매번호</option>
                    </select>
                    &nbsp; &nbsp;
                    <Input
                      autoFocus={true}
                      style={{ width: 310 }}
                      value={state?.numberKeyword}
                      placeholder={"접수번호, 판매번호, 구매번호"}
                      onChange={(e) => {
                        handleValueChange("numberKeyword", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      marginLeft: 10,
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                    }}>
                    <select
                      value={state?.userType ? state?.userType : ""}
                      onChange={(e) => {
                        handleValueChange("userType", e.target.value);
                      }}>
                      <option value={"ALL"}>회원검색</option>
                      <option value={"SELLER"}>판매자</option>
                      <option value={"BUYER"}>구매자</option>
                    </select>
                    &nbsp; &nbsp;
                    <Input
                      style={{ width: 300 }}
                      value={state?.userKeyword}
                      placeholder="고객명, 연락처, 고객번호"
                      onChange={(e) => {
                        handleValueChange("userKeyword", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                  <div
                    style={{
                      display: "flex",
                    }}>
                    <select
                      value={state?.statusLevel}
                      style={{ marginRight: 10, width: 157 }}
                      onChange={(e) => {
                        handleValueChange("statusLevel", e.target.value);
                      }}>
                      <option value="">{"상품처리 단계"}</option>
                      {Object.keys(status)?.map((type, index) => {
                        return (
                          <option value={type} key={index}>
                            {type === "REGISTER"
                              ? "판매 요청 대기"
                              : type === "SALE"
                              ? "판매 중"
                              : type === "RELEASE"
                              ? "출고 및 판매 반송"
                              : ""}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      value={state?.status}
                      style={{ marginRight: 10, width: 157 }}
                      onChange={(e) => {
                        handleValueChange("status", e.target.value);
                      }}>
                      {status[`${state.statusLevel}`]?.map((item) => {
                        return (
                          item.statusLevel === state.statusLevel && (
                            <option value={item.status}>
                              {item.description}
                            </option>
                          )
                        );
                      })}
                    </select>
                    <Button
                      onClick={() => {
                        handleClickInfo("filtersState");
                      }}
                      style={{
                        margin: "0px 10px",
                        // fontFamily: fonts.notoSansKRBold,
                        "& span": { color: "#000" },
                        border: "1px solid black",
                      }}>
                      설명
                    </Button>
                    {/* <Button
                      onClick={() => {
                        handleClickInfo("filtersState");
                      }}
                      label="설명"
                      style={{ width: "70px" }}
                    /> */}
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>카테고리</FiltersLabel>
                    <select
                      style={{
                        width: "280px",
                      }}
                      value={state?.categoryId}
                      onChange={(e) => {
                        handleValueChange("categoryId", e.target.value);
                      }}>
                      <option value="">{"전체"}</option>
                      {category?.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>상품검색</FiltersLabel>
                    &nbsp; &nbsp;
                    <Input
                      style={{ width: 280 }}
                      type="text"
                      autocomplete="off"
                      placeholder="상품명"
                      value={state?.name}
                      onChange={(e) => {
                        handleValueChange("name", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                    <input
                      id="fakeProductName"
                      name="fakeProductName"
                      autoComplete="new-password"
                      style={{
                        display: "absolute",
                        width: 0,
                        height: 0,
                        zIndex: -1,
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}>
                  <Button
                    className="buttons-container-button"
                    icon="search"
                    style={{ marginRight: 5 }}
                    onClick={fetchList}>
                    검색
                  </Button>
                  <Button
                    className="buttons-container-button"
                    icon="refresh"
                    onClick={initState}>
                    초기화
                  </Button>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
        <div
          style={{
            marginTop: 10,
            width: "100%",
            height: "100%",
          }}>
          <Wrap>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              style={{
                height: "100%",
                fontSize: 16,
                fontFamily: "NotoSansKR-Bold",
              }}>
              <TabStripTab
                title={`판매 요청 대기 (${
                  registerTotalCount !== ""
                    ? registerTotalCount === 0
                      ? 0
                      : registerTotalCount
                    : "-"
                })`}
                style={{
                  width: "100%",
                  height: "100%",
                  background: "blue",
                }}>
                <div style={{ height: "100%" }}>
                  <LocalizationProvider
                    language="es-KR"
                    style={{ height: "100%" }}>
                    <IntlProvider locale="es" style={{ height: "100%" }}>
                      <Grid
                        style={{
                          width: "100%",
                          marginTop: 5,
                          cursor: "pointer",
                          fontSize: "16px",
                          height: "100%",
                        }}
                        data={
                          registerList?.length !== 0
                            ? registerList?.map((item, index) => ({
                                ...item,
                                // no:
                                //   registerTotalCount -
                                //   parseInt(state.registerPage) *
                                //     parseInt(state.registerSize) -
                                //   index,
                                no: createIndex(
                                  {
                                    page: state.registerPage,
                                    size: state.registerSize,
                                  },
                                  index
                                ),
                                selected: selectedState[idGetter(item)],
                              }))
                            : []
                        }
                        cellRender={cellRender}
                        resizable={true}
                        skip={state.registerPage * state.registerSize}
                        take={state.registerSize}
                        total={registerTotalCount}
                        pageable={{
                          pageSizes: [10, 30, 50, 100],
                          previousNext: true,
                          info: true,
                        }}
                        navigatable={true}
                        dataItemKey={DETAIL_ID}
                        selectable={{
                          enabled: true,
                        }}
                        onSelectionChange={onSelectionChange}
                        onKeyDown={onKeyDown}
                        scrollable
                        selectedField="selected"
                        onPageChange={pageChange}
                        onRowClick={handleClickDetail}>
                        <GridColumn field="no" title="번호" width={80} />
                        <GridColumn
                          field="createdAt"
                          title="접수일"
                          width={180}
                        />
                        <GridColumn
                          field="orderId"
                          title="접수번호"
                          width={100}
                        />
                        <GridColumn field="seller" title="고객명" width={100} />
                        <GridColumn
                          field="sellerUserId"
                          title="고객번호"
                          width={100}
                        />
                        <GridColumn
                          field="sellShop"
                          title="접수대리점"
                          width={140}
                        />
                        <GridColumn
                          field="itemId"
                          title="판매번호"
                          width={100}
                        />
                        <GridColumn
                          field="category"
                          title="카테고리"
                          width={120}
                        />
                        <GridColumn field="name" title="상품명" width={180} />
                        <GridColumn
                          field="orderPrice"
                          title="접수가격"
                          width={140}
                        />
                        <GridColumn
                          field="authPrice"
                          title="감정가격"
                          width={140}
                        />
                        <GridColumn
                          field="status"
                          title="진행상태"
                          width={120}
                        />
                        <GridColumn
                          field="updatedAt"
                          title="최종처리일시"
                          width={180}
                        />
                      </Grid>
                    </IntlProvider>
                  </LocalizationProvider>
                </div>
              </TabStripTab>
              <TabStripTab
                title={`판매 중 (${
                  saleTotalCount !== ""
                    ? saleTotalCount === 0
                      ? 0
                      : saleTotalCount
                    : "-"
                })`}
                style={{
                  width: "100%",
                  height: "100%",
                }}>
                <div style={{ height: "100%", width: "100%" }}>
                  <LocalizationProvider
                    language="es-KR"
                    style={{ height: "100%" }}>
                    <IntlProvider locale="es" style={{ height: "100%" }}>
                      <Grid
                        style={{
                          width: "100%",
                          marginTop: 5,
                          cursor: "pointer",
                          fontSize: "16px",
                          height: "100%",
                        }}
                        scrollable
                        data={
                          saleList?.length !== 0
                            ? saleList?.map((item, index) => ({
                                ...item,
                                // no:
                                //   saleTotalCount -
                                //   parseInt(state.salePage) *
                                //     parseInt(state.saleSize) -
                                //   index,
                                no: createIndex(
                                  {
                                    page: state.salePage,
                                    size: state.saleSize,
                                  },
                                  index
                                ),
                                selected: selectedState[idGetter(item)],
                              }))
                            : []
                        }
                        resizable={true}
                        cellRender={cellRender}
                        skip={state.salePage * state.saleSize}
                        take={state.saleSize}
                        total={saleTotalCount}
                        pageable={{
                          pageSizes: [10, 30, 50, 100],
                          previousNext: true,
                          info: true,
                        }}
                        navigatable={true}
                        dataItemKey={DETAIL_ID}
                        selectable={{
                          enabled: true,
                        }}
                        onSelectionChange={onSelectionChange}
                        onKeyDown={onKeyDown}
                        selectedField="selected"
                        onPageChange={pageChange}
                        onRowClick={handleClickDetail}>
                        <GridColumn
                          field="no"
                          title="번호"
                          width={80}
                          // cell={(props) => {
                          //   const { dataItem } = props;
                          //   const navigationAttributes =
                          //     useTableKeyboardNavigation(props.id);
                          //   return (
                          //     <td
                          //       colSpan={props.colSpan}
                          //       role={"gridcell"}
                          //       aria-colindex={props.ariaColumnIndex}
                          //       aria-selected={props.isSelected}
                          //       {...{
                          //         [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          //       }}
                          //       {...navigationAttributes}
                          //     >
                          //       <div style={{ textAlign: "-webkit-center" }}>
                          //         {dataItem.no}
                          //       </div>
                          //     </td>
                          //   );
                          // }}
                        />
                        <GridColumn
                          field="createdAt"
                          title="접수일"
                          width={180}
                        />
                        <GridColumn
                          field="orderId"
                          title="접수번호"
                          width={100}
                        />
                        <GridColumn field="seller" title="고객명" width={100} />
                        <GridColumn
                          field="sellerUserId"
                          title="고객번호"
                          width={100}
                        />
                        <GridColumn
                          field="sellShop"
                          title="접수대리점"
                          width={140}
                        />
                        <GridColumn
                          field="itemId"
                          title="판매번호"
                          width={100}
                        />
                        <GridColumn
                          field="category"
                          title="카테고리"
                          width={120}
                        />
                        <GridColumn field="name" title="상품명" width={180} />
                        <GridColumn
                          field="currentPrice"
                          title="판매가격"
                          width={140}
                        />
                        <GridColumn
                          field="authPrice"
                          title="감정가격"
                          width={140}
                        />
                        <GridColumn
                          field="status"
                          title="진행상태"
                          width={120}
                        />
                        <GridColumn
                          field="updatedAt"
                          title="최종처리일시"
                          width={180}
                        />
                      </Grid>
                    </IntlProvider>
                  </LocalizationProvider>
                </div>
              </TabStripTab>
              <TabStripTab
                title={`출고 및 판매 반송 (${
                  releaseTotalCount !== ""
                    ? releaseTotalCount === 0
                      ? 0
                      : releaseTotalCount
                    : "-"
                })`}
                style={{
                  width: "100%",
                  height: "100%",
                }}>
                <div style={{ height: "100%" }}>
                  <LocalizationProvider
                    language="es-KR"
                    style={{ height: "100%" }}>
                    <IntlProvider locale="es" style={{ height: "100%" }}>
                      <Grid
                        style={{
                          width: "100%",
                          marginTop: 5,
                          cursor: "pointer",
                          fontSize: "16px",
                          height: "100%",
                        }}
                        scrollable
                        data={
                          releaseList?.length !== 0
                            ? releaseList?.map((item, index) => ({
                                ...item,
                                // no:
                                //   releaseTotalCount -
                                //   parseInt(state.releasePage) *
                                //     parseInt(state.releaseSize) -
                                //   index,
                                no: createIndex(
                                  {
                                    page: state.releasePage,
                                    size: state.releaseSize,
                                  },
                                  index
                                ),
                                selected: selectedState[createdAtGetter(item)],
                              }))
                            : []
                        }
                        resizable={true}
                        skip={state.releasePage * state.releaseSize}
                        take={state.releaseSize}
                        total={releaseTotalCount}
                        pageable={{
                          pageSizes: [10, 30, 50, 100],
                          previousNext: true,
                          info: true,
                        }}
                        navigatable={true}
                        dataItemKey={DATA_ITEM_KEY}
                        selectable={{
                          enabled: true,
                        }}
                        onSelectionChange={onSelectionChange}
                        onKeyDown={onKeyDown}
                        selectedField="selected"
                        onPageChange={pageChange}
                        onRowClick={handleClickDetail}
                        cellRender={cellRender}>
                        <GridColumn
                          field="no"
                          title="번호"
                          width={80}
                          // cell={(props) => {
                          //   const { dataItem } = props;
                          //   const navigationAttributes =
                          //     useTableKeyboardNavigation(props.id);
                          //   return (
                          //     <td
                          //       colSpan={props.colSpan}
                          //       role={"gridcell"}
                          //       aria-colindex={props.ariaColumnIndex}
                          //       aria-selected={props.isSelected}
                          //       {...{
                          //         [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          //       }}
                          //       {...navigationAttributes}
                          //     >
                          //       <div style={{ textAlign: "-webkit-center" }}>
                          //         {dataItem.no}
                          //       </div>
                          //     </td>
                          //   );
                          // }}
                        />
                        <GridColumn
                          field="createdAt"
                          title="접수일"
                          width={180}
                        />
                        <GridColumn
                          field="outBoundOrderBookId"
                          title="구매번호"
                          width={100}
                        />
                        <GridColumn field="buyer" title="고객명" width={100} />
                        <GridColumn
                          field="buyerUserId"
                          title="고객번호"
                          width={100}
                        />
                        <GridColumn
                          field="buyShop"
                          title="출고대리점"
                          width={140}
                        />
                        <GridColumn
                          field="itemId"
                          title="판매번호"
                          width={100}
                        />
                        <GridColumn
                          field="category"
                          title="카테고리"
                          width={120}
                        />
                        <GridColumn field="name" title="상품명" width={180} />
                        <GridColumn
                          field="inStoreReserve"
                          title="예약일시"
                          width={140}
                        />
                        <GridColumn
                          field="currentPrice"
                          title="판매금액"
                          width={140}
                        />
                        <GridColumn
                          field="status"
                          title="진행상태"
                          width={120}
                        />
                        <GridColumn
                          field="updatedAt"
                          title="최종처리일시"
                          width={180}
                        />
                      </Grid>
                    </IntlProvider>
                  </LocalizationProvider>
                </div>
              </TabStripTab>
            </TabStrip>
          </Wrap>
        </div>
      </TotalWrap>
    </DefaultLayout>
  );
};

export default SafeTrade;

const InfoState = () => {
  return (
    <Flex
      style={{
        width: "860px",
        height: "450px",
        overflowY: "scroll",
        marginTop: 15,
        display: "block",
      }}>
      <CellTitle style={{ rowSpan: 10 }}>*판매 요청 대기</CellTitle>
      <Cell>
        <CellTitle>접수 대기</CellTitle>
        <CellContent>
          판매자가 판매 신청하고 대리점 관리자의 상품 접수를 기다리는 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>접수 완료</CellTitle>
        <CellContent>
          판매자가 접수 대리점을 방문하고 대리점에서 상품 접수를 확인한 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>접수 취소</CellTitle>
        <CellContent>
          판매자가 판매 신청을 취소하거나 접수 대리점에서 상품 접수를 거절한
          상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>감정 대기 중</CellTitle>
        <CellContent>
          대리점에서 판매자의 상품을 송부하여 본사 관리자가 수령한 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>감정 완료</CellTitle>
        <CellContent>
          본사 관리자가 상품 감정을 마치고 판매자의 판매신청을 대기하는 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>판매 요청 대기</CellTitle>
        <CellContent>
          판매자가 감정결과를 확인하고 판매신청을 한 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>판매 대기</CellTitle>
        <CellContent>
          본사 관리자가 상품을 등록하고 판매 노출 대기 중인 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>구매 취소 본사 반송</CellTitle>
        <CellContent>
          구매자가 구매를 취소하여 상품을 본사로 반송하는 경우
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>거래 종료</CellTitle>
        <CellContent>
          본사 관리자에 의해 판매절차가 강제로 종료된 상태
        </CellContent>
      </Cell>
      <CellTitle></CellTitle>
      <CellTitle style={{ rowSpan: 2 }}>*판매 중</CellTitle>
      <Cell>
        <CellTitle>판매 진행 중</CellTitle>
        <CellContent>상품목록에서 판매되고 있는 경우</CellContent>
      </Cell>
      <CellTitle></CellTitle>
      <CellTitle style={{ rowSpan: 12 }}>*출고 및 판매 반송</CellTitle>
      <Cell>
        <CellTitle>반송</CellTitle>
        <CellContent>판매자가 판매 철회 또는 돌려받기를 한 상태</CellContent>
      </Cell>
      <Cell>
        <CellTitle>결제 대기 중</CellTitle>
        <CellContent>
          결제가 완료되지 않고 구매 과정에서 이탈하여 결제를 기다리는 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>구매 취소</CellTitle>
        <CellContent>
          결제 대기 상태에서 결제 가능 시간이 경과하여 구매가 취소된 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>결제 완료</CellTitle>
        <CellContent>구매자가 결제하였을 때 출고되기 전까지의 상태</CellContent>
      </Cell>
      <Cell>
        <CellTitle>결제 취소</CellTitle>
        <CellContent>
          구매자가 결제 완료 이후 상품을 인도 받기 전에 결제를 취소한 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>출고 중</CellTitle>
        <CellContent>본사 관리자가 상품을 대리점으로 발송한 상태</CellContent>
      </Cell>
      <Cell>
        <CellTitle>인도 대기</CellTitle>
        <CellContent>
          상품이 본사로부터 대리점에 도착하여 구매자에게 전달을 위해 대기중인
          상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>반송 완료</CellTitle>
        <CellContent>
          판매자가 돌려받기 또는 판매철회를 신청하고, 사용자 화면에서 반송
          완료를 누른 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>상품 인도</CellTitle>
        <CellContent>대리점에서 구매자에게 상품을 전달한 상태</CellContent>
      </Cell>
      <Cell>
        <CellTitle>구매 완료</CellTitle>
        <CellContent>
          구매자가 상품을 확인하고 구매확정을 눌러 구매절차가 종료된 상태
        </CellContent>
      </Cell>
      <Cell>
        <CellTitle>구매자 구매 취소</CellTitle>
        <CellContent>구매자가 상품을 확인하고 구매를 취소한 상태</CellContent>
      </Cell>
      {/* </Cell> */}
    </Flex>
  );
};
