import { createSlice } from "@reduxjs/toolkit";

const INIT = {
  open: false,
  images: [],
};
export const imageViewerSlice = createSlice({
  name: "imageViewer",
  initialState: INIT,
  reducers: {
    open: (state, { payload }) => {
      return { ...state, open: true, ...payload };
    },
    close: (state) => {
      state.open = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { open: showImageViewer, close: hideImageViewer } = imageViewerSlice.actions;

export default imageViewerSlice.reducer;
