import React from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/button/Button";
import * as APIS from "../../../libs/apis";
import { showLoading, hideLoading } from "../../../store/loadingReducer";
import { showAlert } from "../../../store/alertReducer";

const RequestAuth = ({ account, setAccount, step, setStep }) => {
  const dispatch = useDispatch();

  const handleClickResetRequest = () => {
    dispatch(showLoading());
    const param = {
      managerAccount: account,
    };

    APIS.postResetRequest(param)
      .then(({ data: { success, message } }) => {
        if (success) {
          setStep(step + 1);
        } else {
          dispatch(showAlert({ message }));
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  return (
    <div className="input-wrapper">
      <input
        value={account}
        onChange={(e) => {
          setAccount(e.target.value);
        }}
        placeholder="ID"
        autoFocus
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClickResetRequest();
          }
        }}
      />
      <Button onClick={handleClickResetRequest}>인증번호 요청</Button>
    </div>
  );
};

export default RequestAuth;
