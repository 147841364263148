import { useLocation, useNavigate } from "react-router-dom";
// import * as APIS from "../libs/apis";
import { useDispatch } from "react-redux";
import { showAlert } from "store/alertReducer";
import { useEffect } from "react";

export default function AuthGateway() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));
  const code = params.get("code") || "";
  const type = params.get("type") || "";
  const purpose = params.get("purpose") || "";
  const name = params.get("name") || "";
  const requestId = params.get("requestId") || "";
  const requireCompleteAction = params.get("requireCompleteAction") || "";
  const error = params.get("error") || "";
  const message = params.get("message") || "";
  const errorType = params.get("errorType") || "";
  const leaveDate = params.get("leaveDate") || "";
  const reSignupDate = params.get("reSignupDate") || "";
  const phone = params.get("phone") || "";
  console.log(params, "params");
  console.log(name, "name");
  console.log(type, "type");
  console.log(purpose, "purpose");
  useEffect(() => {
    if (error === "true") {
      if (errorType === "CANNOT_RESIGNUP") {
        sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        navigate(routes.main, {
          state: {
            errorType,
            leaveDate,
            reSignupDate,
          },
          replace: true,
        });
        return;
      }

      message && dispatch(showAlert({ message }));
      navigate(routes.main, { replace: true });
      return;
    }
    switch (type) {
      // 본인 인증 완료
      case "SELF_VERIFICATION_COMPLETED":
        window.opener.postMessage(
          {
            action: "closeAuthWindow",
            name: name,
            phone: phone,
            requestId: requestId,
          },
          "*"
        );
        window.close(); // 자식 창 닫기
        break;

      default:
        break;
    }
  }, []);

  return null;
}
