import React, { useState } from "react";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "store/loadingReducer";
import { objToQueryStr } from "libs/utils";
import Button from "../../components/button/Button";
import styled from "styled-components";

const PhoneNumberStep = ({ onNext, setStatusCustomer, setUserId }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");

  const handleClickPhone = () => {
    const param = { phone: phone };
    dispatch(showLoading());
    APIS.getFindPhone(objToQueryStr(param))
      .then(({ data: { success, data } }) => {
        if (success) {
          setStatusCustomer("current");
          setUserId(data);
          onNext();
        } else {
          setStatusCustomer("new");
          onNext();
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}>
      <RowKey>전화번호</RowKey>
      <div>
        <input
          placeholder="숫자만 입력해주세요."
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <Button style={{ marginLeft: 5 }} onClick={handleClickPhone}>
          확인
        </Button>
      </div>
    </div>
  );
};

export default PhoneNumberStep;

const RowKey = styled.div`
font-weight : bold;
display: flex;
align-items: center;
margin-right: 10px;
`;
