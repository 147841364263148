import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { Button as KendoButton } from "@progress/kendo-react-buttons";

const InputFile = ({
  label,
  accept,
  multiple = false,
  callback,
  buttonStyles,
}) => {
  const inputRef = useRef(null);

  const handleChangeFile = (e) => {
    if (e.target.files.length) {
      multiple ? callback(e.target.files) : callback(e.target.files[0]);
    }
  };

  return (
    <>
      <HiddenInput
        type="file"
        ref={inputRef}
        onChange={handleChangeFile}
        accept={accept}
        multiple={multiple}
      />
      <StyledButton
        buttonStyles={buttonStyles}
        onClick={() => inputRef.current.click()}
      >
        {label}
      </StyledButton>
    </>
  );
};

export default InputFile;

const StyledButton = styled(KendoButton)`
  width: 150px;
  cursor: pointer;

  ${({ buttonStyles }) =>
    buttonStyles &&
    css`
      ${buttonStyles}
    `}
`;

const HiddenInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
`;
