// import { Grid, Divider, CardMedia, } from "@material-ui/core";
import {
  PanelBar,
  PanelBarItem,
  Card,
  CardImage,
  CardActions,
} from "@progress/kendo-react-layout";
import Flex from "../../components/layout/Flex";
import Button from "../../components/button/Button";
// import Text from "../../components/text/Text";
// import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState, useRef, Fragment } from "react";
// import AdminConfirmButton from "../../components/admin-confirm-button/AdminConfirmButton";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../libs/apis";
// import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
// import colors from "../../libs/colors";
// import fonts from "../../libs/fonts";
import consts from "../../libs/consts";
// import { actionOpen, actionError } from "../../redux/action/ActionActions";
import { formatTime, numberWithCommas } from "../../libs/utils";
import { showImageViewer } from "../../store/imageViewerReducer";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableRow from "@material-ui/core/TableRow";
// import ConfirmButton from "../../components/button/Button";
// import SimpleTable from "../../components/simple-table/SimpleTable";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import styled from "styled-components";

const Divider = styled.hr`
  width: 100%;
  margin: 5px 0px 0px 0px;
  border: none;
  height: 1px;
  flex-shrink: 0px;
  background-color: gray;
`;

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const ProductRegisterInfo = (props) => {
  const { list, setList, recentStatus, isKorda, authPrice } = props;
  const dispatch = useDispatch();
  const firstRef = useRef(true);
  const moveRef = useRef(true);
  const registerInfo = list;
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [saleStartDate, setSaleStartDate] = useState("");
  const [saleEndDate, setSaleEndDate] = useState("");
  const [inventoryLocation, setInventoryLocation] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [activeImageList, setActiveImageList] = useState([]);
  const [hideImageList, setHideImageList] = useState([]);
  const editAbleStatus = [
    "INBOUND_WAIT",
    "INBOUND_COMPLETE",
    // "INBOUND_CANCEL",
    "VERIFY_WAIT",
    "RETURN",
    "VERIFY_COMPLETE",
    "SELL_CONFIRM_WAIT",
    "SELL_WAIT",
  ];

  //grid-dnd
  const [items, setItems] = useState({
    activeImageList: activeImageList,
    hideImageList: hideImageList,
  });

  useEffect(() => {
    Promise.all([
      setSaleStartDate(registerInfo?.tradeFee?.startDate),
      setSaleEndDate(registerInfo?.tradeFee?.endDate),
      setInventoryLocation(registerInfo?.tradeFee?.inventoryLocation),
      setAdditionalInfo(registerInfo?.infoList),
      setActiveImageList(registerInfo?.imageActiveList),
      setHideImageList(registerInfo?.imageHideList),
      setProductInfo(registerInfo?.info),
    ]).finally(() => dispatch(hideLoading()));
  }, [list]);

  useEffect(() => {
    setItems({
      activeImageList: activeImageList,
      hideImageList: hideImageList,
    });
  }, [activeImageList, hideImageList]);

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current = false;
    } else {
      setTimeout(() => {
        moveRef.current = true;
      }, 500);
    }
  }, [items]);
  // console.log(additionalInfo, "additionalInfo");
  return (
    <PanelBar
      style={{
        width: "100%",
        marginBottom: "20px",
        border: "1px solid #9e9e9e",
      }}>
      <PanelBarItem expanded={false} title={"상품 등록 정보"}>
        <Flex
          style={{
            width: "-webkit-fill-available",
            flexDirection: "column",
            // padding: "0px 10px",
          }}>
          <Flex style={{ marginBottom: 30 }}>
            <Table>
              <colgroup>
                <col width="14%" />
                <col width="20%" />
                <col width="14%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr>
                  <Th>판매가격</Th>
                  <Td>
                    {registerInfo?.tradeFee?.price
                      ? numberWithCommas(registerInfo?.tradeFee?.price) + " 원"
                      : "-"}
                  </Td>
                  <Th>
                    <tr>판매 수수료 금액</tr>
                    <tr>구매 수수료 금액</tr>
                  </Th>
                  <Td>
                    <tr>
                      {registerInfo?.tradeFee?.sellFee
                        ? numberWithCommas(registerInfo?.tradeFee?.sellFee) +
                          " 원"
                        : "-"}
                    </tr>
                    <tr>
                      {registerInfo?.tradeFee?.buyFee
                        ? numberWithCommas(registerInfo?.tradeFee?.buyFee) +
                          " 원"
                        : "-"}
                    </tr>
                  </Td>
                </tr>
                <tr>
                  <Th>판매 추천 대리점 수수료</Th>
                  <Td>
                    {registerInfo?.tradeFee?.sellRecommendShopFee
                      ? numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.sellFee / 100) *
                            registerInfo?.tradeFee?.sellRecommendShopFee
                          ).toFixed()
                        ) +
                        ` 원    (판매 수수료의 ${registerInfo?.tradeFee?.sellRecommendShopFee}%)`
                      : "-"}
                  </Td>
                  <Th>구매 추천 대리점 수수료</Th>
                  <Td>
                    {registerInfo?.tradeFee?.buyRecommendShopFee
                      ? numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.buyFee / 100) *
                            registerInfo?.tradeFee?.buyRecommendShopFee
                          ).toFixed()
                        ) +
                        ` 원    (구매 수수료의 ${registerInfo?.tradeFee?.buyRecommendShopFee}%)`
                      : "-"}
                  </Td>
                </tr>
                <tr>
                  <Th>판매 접수 대리점 수수료</Th>
                  <Td>
                    {registerInfo?.tradeFee?.sellShopFee
                      ? numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.sellFee / 100) *
                            registerInfo?.tradeFee?.sellShopFee
                          ).toFixed()
                        ) +
                        ` 원    (판매 수수료의 ${registerInfo?.tradeFee?.sellShopFee}%)`
                      : "-"}
                  </Td>
                  <Th>구매 출고 대리점 수수료</Th>
                  <Td>
                    {registerInfo?.tradeFee?.buyShopFee
                      ? numberWithCommas(
                          (
                            (registerInfo?.tradeFee?.buyFee / 100) *
                            registerInfo?.tradeFee?.buyShopFee
                          ).toFixed()
                        ) +
                        ` 원     (구매 수수료의 ${registerInfo?.tradeFee?.buyShopFee}%)`
                      : "-"}
                  </Td>
                </tr>
                <tr>
                  <Th>판매철회 위약금</Th>
                  <Td>
                    {registerInfo?.tradeFee?.sellerPenalty
                      ? numberWithCommas(
                          registerInfo?.tradeFee?.sellerPenaltyFee.toFixed()
                        ) +
                        `원    (감정가의 ${registerInfo?.tradeFee?.sellerPenalty}%)`
                      : "-"}
                  </Td>
                  <Th>구매취소 위약금</Th>
                  <Td>
                    {registerInfo?.tradeFee?.buyerPenaltyFee
                      ? numberWithCommas(
                          registerInfo?.tradeFee?.buyerPenaltyFee.toFixed()
                        ) +
                        `원    (판매가격의 ${registerInfo?.tradeFee?.buyerPenalty}%)`
                      : "-"}
                  </Td>
                </tr>
                <tr>
                  <Th>판매 기간</Th>
                  <Td>
                    {saleStartDate ? saleStartDate : "-"} ~
                    {saleEndDate ? saleEndDate : "-"}
                  </Td>
                  <Th>재고위치</Th>
                  <Td> {inventoryLocation || "-"}</Td>
                </tr>
              </tbody>
            </Table>
          </Flex>
          <Flex style={{ marginBottom: 30, flexDirection: "column" }}>
            <div
              style={{
                fontWeight: "bold",
                marginLeft: 10,
                fontFamily: "NotoSansKR-Bold",
                fontSize: "16px",
              }}>
              상품이미지
            </div>
            <Divider style={{ width: "100%", marginBottom: 20 }} />
            <div
              style={{
                justifyContent: "center",
                "& div": {
                  display: "flex",
                },
                height: 150,
              }}>
              <div style={{ display: "flex", maxHeight: 120 }}>
                {items.activeImageList?.map((item, index) => (
                  <Flex
                    style={{
                      width: "57%",
                      alignItems: "center",
                      flexDirection: "column",
                      maxWidth: 190,
                    }}>
                    <div
                      style={{
                        width: "60%",
                        height: "100%",
                        borderRadius: 10,
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        document.getElementById(`image_btn_${index}`).click();
                      }}>
                      {item?.imageUrl ? (
                        <Card style={{ height: "inherit" }}>
                          <CardImage
                            src={consts.s3BaseUrl + "/" + item?.imageUrl}
                            style={{
                              borderRadius: 10,
                              cursor: "pointer",
                              height: "100%",
                            }}
                          />
                          <CardActions style={{ alignSelf: "center" }}>
                            <Button
                              icon={"search"}
                              id={`image_btn_${index}`}
                              onClick={() => {
                                // console.log(
                                //   items.activeImageList,
                                //   "items.activeImageList"
                                // );
                                dispatch(
                                  showImageViewer({
                                    images: items.activeImageList
                                      ? items.activeImageList?.map((item) => {
                                          return {
                                            src: item?.imageUrl,
                                          };
                                        })
                                      : "",
                                    activeIndex: index,
                                  })
                                );
                              }}
                            />
                          </CardActions>
                        </Card>
                      ) : (
                        <span
                          class="k-icon k-i-image"
                          style={{
                            alignSelf: "center",
                            width: "100%",
                            height: "100%",
                            fontSize: 40,
                          }}
                        />
                      )}
                    </div>
                    <div style={{ fontWeight: "bold", alignSelf: "center" }}>
                      {item.imageName}
                    </div>
                  </Flex>
                ))}
              </div>
            </div>
          </Flex>
          <Flex style={{ marginBottom: 30, flexDirection: "column" }}>
            <div
              style={{
                fontWeight: "bold",
                marginLeft: 10,
                fontFamily: "NotoSansKR-Bold",
                fontSize: "16px",
              }}>
              추가정보
            </div>
            <Divider style={{ width: "100%" }} />
            <Table>
              <colgroup>
                <col width="200px" height="100px" />
                <col width="auto" height="auto" />
              </colgroup>
              <tbody>
                {additionalInfo?.map((item) => {
                  return (
                    <tr>
                      <Th>{item.key}</Th>
                      <Td>{item.value}</Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {/* <Grid
              item
              xs={12}
              style={{ flexDirection: "column", display: "flex" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                  textAlign: "left",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                <div style={{ fontWeight: "bold" }}>추가정보</div>
              </Grid>
              <Divider style={{ width: "100%", marginBottom: 20 }} />
              <Grid
                item
                xs={12}
                style={{
                  flexDirection: "column",
                  display: "flex",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingBottom: "10px",
                  marginBottom: "15px",
                  "& .ck-editor__editable_inline": {
                    minHeight: "300px",
                  },
                }}
              >
                {additionalInfo?.map((item, i) => {
                  return (
                    <tr key={i.toString()} style={{ marginBottom: 10 }}>
                      <td colSpan={5} style={{ padding: "0px 20px" }}>
                        <input
                          disabled={
                            isKorda &&
                            editAbleStatus.includes(recentStatus) === true
                              ? false
                              : true
                          }
                          placeholder="항목명"
                          autoComplete="off"
                          value={item?.key}
                          style={{
                            flex: 1,
                            marginRight: "20px",
                            paddingLeft: "16px",
                            width: "800px",
                            height: 40,
                            border: "1px solid rgb(224,224,224)",
                          }}
                          onChange={(e) => {
                            const { value } = e.target;
                            const copyList = [...additionalInfo];
                            copyList[i] = {
                              ...copyList[i],
                              key: value,
                              sort: i,
                            };
                            setAdditionalInfo(copyList);
                          }}
                        />
                      </td>
                      <td colSpan={5} style={{ padding: "0px 20px" }}>
                        <input
                          style={{
                            flex: 1,
                            marginRight: "20px",
                            paddingLeft: "16px",
                            width: "800px",
                            height: 40,
                            border: "1px solid rgb(224,224,224)",
                          }}
                          disabled={
                            isKorda &&
                            editAbleStatus.includes(recentStatus) === true
                              ? false
                              : true
                          }
                          placeholder="내용"
                          autoComplete="off"
                          value={item?.value}
                          onChange={(e) => {
                            const { value } = e.target;
                            const copyList = [...additionalInfo];
                            copyList[i] = {
                              ...copyList[i],
                              value: value,
                            };
                            setAdditionalInfo(copyList);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Grid>
            </Grid> */}
          </Flex>
          <Flex style={{ marginBottom: 30, flexDirection: "column" }}>
            <div
              style={{
                fontWeight: "bold",
                marginLeft: 10,
                fontFamily: "NotoSansKR-Bold",
                fontSize: "16px",
              }}>
              상품설명
            </div>
            <Divider style={{ width: "100%", marginBottom: 20 }} />
            <div
              style={{
                fontFamily: "NotoSansKR-Bold",
                fontSize: "16px",
                padding: "0px 10px",
              }}>
              {productInfo}
            </div>
            {/* <Grid
              item
              xs={12}
              style={{ flexDirection: "column", display: "flex" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                  textAlign: "left",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                <div style={{ fontWeight: "bold" }}>상품설명</div>
              </Grid>

              <Divider style={{ width: "100%", marginBottom: 20 }} />
              <Grid
                item
                xs={12}
                style={{
                  flexDirection: "column",
                  display: "flex",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  paddingBottom: "10px",
                  marginBottom: "15px",
                  "& .ck-editor__editable_inline": {
                    minHeight: "300px",
                  },
                }}
              >
                <textarea
                  disabled={
                    isKorda && editAbleStatus.includes(recentStatus) === true
                      ? false
                      : true
                  }
                  name="content"
                  rows="10"
                  placeholder="내용을 입력하세요"
                  value={productInfo}
                  onChange={(e) => {
                    setProductInfo(e.target.value);
                  }}
                />
              </Grid>
            </Grid> */}
          </Flex>
        </Flex>
      </PanelBarItem>
    </PanelBar>
  );
};
export default ProductRegisterInfo;
