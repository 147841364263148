import React from "react";
import Modal from "./Modal";
// import styled from "styled-components";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";

const Loading = () => {
  const visible = useSelector((state) => state.loading.visible);
  return (
    <Modal visible={visible}>
      <Loader type="converging-spinner" size="large" />
    </Modal>
  );
};

export default Loading;
