import React from "react";
import styled from "styled-components";

const FlexLayout = styled.div`
  display: flex;
`;

const Flex = ({ children, onClick, className, style }) => {
  return (
    <FlexLayout onClick={onClick} style={style} className={className}>
      {children}
    </FlexLayout>
  );
};

export default Flex;
