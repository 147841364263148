import React, { useState } from "react";
import { LoginLayout } from "../../components/layout/LoginLayout";
import styled from "styled-components";
import RequestAuth from "./components/RequestAuth";
import Reset from "./components/Reset";

const stepList = [{ step: "REQUEST_AUTH" }, { step: "RESET" }];

const ResetAccount = () => {
  const [account, setAccount] = useState("");
  const [step, setStep] = useState(0);

  return (
    <Container title="OTP 초기화 / 비밀번호 변경 ">
      {stepList[step].step === "REQUEST_AUTH" && (
        <RequestAuth
          account={account}
          setAccount={setAccount}
          step={step}
          setStep={setStep}
        />
      )}
      {stepList[step].step === "RESET" && (
        <Reset account={account} step={step} setStep={setStep} />
      )}
    </Container>
  );
};

export default ResetAccount;

const Container = styled(LoginLayout)`
  min-width: 600px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  input {
    color: black;
    background: white;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-top: 10px;
    height: 35px;
    padding: 0px 12px;
  }

  button {
    height: 35px;
    margin-top: 10px;
    font-weight: 700;
  }
`;
