import { STORAGE_KEY } from "../libs/consts";
import jwtDecode from "jwt-decode";
import axios from "axios";

// Actions
const SIGN_IN = "auth/SIGN_IN";
const SIGN_OUT = "auth/SIGN_OUT";
const UPDATE_SCREEN = "auth/UPDATE_SCREEN";

const INIT_STATE = {
  isSigned: false,
  name: "",
  sns: "",
  socialId: "",
  isKorda: false,
  screenType: "SHOP",
};

const fnSignIn = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const tokenData = jwtDecode(token) || {};
  const { roles, sub } = tokenData;
  const ids = sub.split(":");
  return {
    ...INIT_STATE,
    isSigned: true,
    role: roles,
    managerId: +ids[2] || 0,
    shopId: +ids[3] || 0,
  };
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case SIGN_IN:
      return fnSignIn(action.payload);

    case SIGN_OUT:
      localStorage.removeItem(STORAGE_KEY.accessToken);
      // localStorage.removeItem("passwordVerification");
      return { ...INIT_STATE };

    case UPDATE_SCREEN:
      return { ...state, screenType: action.payload };

    default:
      return state;
  }
}

// Action 생성자
export function signIn(payload) {
  return { type: SIGN_IN, payload };
}

export function signOut() {
  return { type: SIGN_OUT };
}

export function updateScreen(newType) {
  return { type: UPDATE_SCREEN, payload: newType };
}
