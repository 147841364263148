import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../../libs/utils";
import Flex from "../../components/layout/Flex";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const ProductAppraisalInfo = (props) => {
  const { list } = props;
  const appraisalInfo = list;
  const [category, setCategory] = useState("");
  const [productName, setProductName] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [productPrice, setProductPrice] = useState(""); //시중 신품 가격
  const [estimatedSalePrice, setEstimatedSalePrice] = useState(""); //예상판매가격
  const [manufacturingReleaseYear, setManufacturingReleaseYear] = useState(""); //제조 출시년도
  const [productStatus, setProductStatus] = useState("");
  const [isContract, setIsContract] = useState("");

  useEffect(() => {
    setIsContract(list?.isContract); //보증서
    setCategory(list?.categoryInfo); //카테고리
    setProductName(list?.name); //제품명
    setModelNumber(list?.modelNumber); //시리얼 번호
    setProductPrice(list?.productPrice); //시중신품가격
    setManufacturingReleaseYear(list.outDate); //제조 출시년도
    setProductStatus(list.state); //제품 상태
    setEstimatedSalePrice(list.authPrice); //예상판매가격
  }, [list]);

  return (
    <PanelBar
      style={{ marginBottom: 20, width: "100%", border: "1px solid #9e9e9e" }}
    >
      <PanelBarItem expanded={false} title={"상품 감정 정보"}>
        <Flex style={{ flexDirection: "column" }}>
          <Table>
            <colgroup>
              <col width="8%" />
              <col width="20%" />
              <col width="8%" />
              <col width="20%" />
            </colgroup>
            <tbody>
              <tr>
                <Th>카테고리</Th>
                <Td>{category?.categoryName || "-"}</Td>
                <Th>브랜드(제조사)</Th>
                <Td>{category?.brand || "-"}</Td>
              </tr>
              <tr>
                <Th>제품명(모델명)</Th>
                <Td>{productName || "-"}</Td>
                <Th>모델번호</Th>
                <Td>{modelNumber || "-"}</Td>
              </tr>
              <tr>
                <Th>시중신품가격</Th>
                <Td>
                  {productPrice ? numberWithCommas(productPrice) + "원" : "-"}
                </Td>
                <Th>제조출시년도</Th>
                <Td>{manufacturingReleaseYear || "-"}</Td>
              </tr>
              <tr>
                <Th>보증서</Th>
                <Td>
                  {isContract === false
                    ? "무"
                    : isContract === true
                    ? "유"
                    : "-"}
                </Td>
                <Th>제품상태</Th>
                <Td>{productStatus || "-"}</Td>
              </tr>
              <tr>
                <Th>예상 판매가격</Th>
                <Td>
                  {estimatedSalePrice
                    ? numberWithCommas(estimatedSalePrice) + "원"
                    : "-"}
                </Td>
                <Th>감정비용</Th>
                <Td>
                  {appraisalInfo
                    ? numberWithCommas(appraisalInfo.authPricePenalty) + "원"
                    : "-"}
                </Td>
              </tr>
            </tbody>
          </Table>
        </Flex>
      </PanelBarItem>
    </PanelBar>
  );
};

export default ProductAppraisalInfo;
