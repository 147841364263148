import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Splitter } from "@progress/kendo-react-layout";
import Button from "../../components/button/Button";
const DefaultWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 20px 20px 13px;
  overflow-y: hidden;
  overflow-x: auto;
  height: 100%;
`;
const DefaultLayout = (props) => {
  const [panes, setPanes] = useState([
    { scrollable: true },
    {
      size: "78%",
      min: "50%",
      max: "85%",
      collapsible: true,
      // collapsed: true,
      resizable: true,
      scrollable: true,
    },
  ]);
  const [expansion, setExpansion] = useState(false);
  const onChange = (event) => {
    setPanes(event.newState);
  };

  const onClickExpansion = () => {
    setExpansion(!expansion);
  };

  useEffect(() => {
    if (props.detail) {
      const [pane1, pane2] = panes;
      setPanes([pane1, { ...pane2, collapsed: false }]);
    }
  }, [props.detail]);

  useEffect(() => {
    const [pane1, pane2] = panes;
    if (expansion === true) {
      setPanes([pane1, { ...pane2, size: "100%" }]);
    } else if (expansion === false) {
      setPanes([pane1, { ...pane2, size: "78%" }]);
    }
  }, [expansion]);

  return (
    <Splitter panes={panes} onChange={onChange} style={{ height: "100%" }}>
      {/* List View */}
      <DefaultWrapper>{props.children}</DefaultWrapper>

      {/* Detail View */}
      {props.detail ? (
        props.detail && panes[1].size !== "100%" ? (
          <DefaultWrapper
            style={{ width: "100%", position: "absolute", overflow: "scroll" }}
          >
            <div style={{ textAlignLast: "right" }}>
              {expansion === false ? (
                <Button
                  className="buttons-container-button"
                  icon="fullscreen"
                  style={{ marginRight: 5 }}
                  onClick={onClickExpansion}
                />
              ) : (
                <Button
                  className="buttons-container-button"
                  icon="fullscreen-exit"
                  style={{ marginRight: 5 }}
                  onClick={onClickExpansion}
                />
              )}

              <Button
                className="buttons-container-button"
                icon="x"
                onClick={props.clearDetail}
              />
            </div>
            {props.detail}
          </DefaultWrapper>
        ) : (
          <DefaultWrapper
            style={{ width: "100%", position: "absolute", overflow: "scroll" }}
          >
            <div style={{ textAlignLast: "right" }}>
              {expansion === false ? (
                <Button
                  className="buttons-container-button"
                  icon="fullscreen"
                  style={{ marginRight: 5 }}
                  onClick={onClickExpansion}
                />
              ) : (
                <Button
                  className="buttons-container-button"
                  icon="fullscreen-exit"
                  style={{ marginRight: 5 }}
                  onClick={onClickExpansion}
                />
              )}

              <Button
                className="buttons-container-button"
                icon="x"
                onClick={props.clearDetail}
              />
            </div>
            {props.detail}
          </DefaultWrapper>
        )
      ) : null}
    </Splitter>
  );
};

export default DefaultLayout;
