import styled from "styled-components";
import banner from "../../assets/images/banner.png";
import logo from "../../assets/images/logo_new.png";

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
`;
const ContentContainer = styled.div`
  display: flex;
  background-color: #fff;
  padding: 50px 50px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .title {
    font-size: 24px;
    margin-top: 10px;
    font-weight: 600;
  }
`;

export const LoginLayout = ({ children, className, title }) => {
  return (
    <LoginContainer>
      <ContentContainer className={className}>
        <img src={logo} alt="logo" width={240} />
        <span className="title">{title}</span>
        {children}
      </ContentContainer>
    </LoginContainer>
  );
};
