import React from "react";
import Button from "../components/button/Button";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "80vh",
      }}
    >
      <div>
        <span
          class="k-icon k-i-warning"
          style={{
            alignSelf: "center",
            width: "100%",
            height: "100%",
            fontSize: 100,
            color: "#a09f9f",
          }}
        />
        <h1 style={{ color: "#a09f9f", textAlign: "center" }}>
          요청하신 페이지를 찾을 수 없습니다.
        </h1>
        <h4 style={{ color: "#a09f9f", textAlign: "center" }}>
          입력한 주소가 잘못되었거나, 사용이 일시 중단되어 요청하신 페이지를
          찾을 수 없습니다. <br /> 서비스 이용에 불편을 드려 죄송합니다.
        </h4>
      </div>
      <div>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          이전 페이지로
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
