import React, { useEffect, useState } from "react";
// import Button from "../../components/button/Button";
import styled from "styled-components";
import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
// import Table from "../../components/table/Table";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import { formatTime, numberWithCommas } from "../../libs/utils";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

const Wrap = styled.div`
  .k-animation-container {
    width: 100%;
  }
`;

const MemberManagementDetail = ({ id }) => {
  const [selected, setSelected] = useState(0);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [assetData, setAssetData] = useState([]);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const reformData = (data) => {
    const parseData = [];
    const lockDetailData = data?.lockDetail.map((item) => {
      return {
        type:
          item.tradeType === "GOODS"
            ? "실물인출대기"
            : item.tradeType === "APPRAISAL"
            ? "감정평가대기"
            : item.tradeType === "BUY"
            ? "매수대기"
            : item.tradeType === "SELL"
            ? "매도대기"
            : item.tradeType === "DEPOSIT"
            ? "입금대기"
            : item.tradeType === "WITHDRAW"
            ? "출금대기"
            : item.tradeType === "SAFE_DIRECT"
            ? "안심직거래대기"
            : "-",
        krw: numberWithCommas(item.krw) || 0,
        gold: numberWithCommas(item.gold) || 0,
        silver: numberWithCommas(item.silver) || 0,
        point: numberWithCommas(item.point) || 0,
      };
    });
    parseData.push(
      data?.asset.krw !== null
        ? {
            type: data?.asset.krw !== null ? "총 보유" : "",
            krw: data?.asset.krw ? numberWithCommas(data?.asset.krw) : "0",
            gold: data?.asset.gold ? numberWithCommas(data?.asset.gold) : "0",
            silver: data?.asset.silver
              ? numberWithCommas(data?.asset.silver)
              : "0",
            point: data?.asset.point
              ? numberWithCommas(data?.asset.point)
              : "0",
          }
        : {},
      data?.asset.acceptableKrw !== null
        ? {
            type: data?.asset.acceptableKrw !== null ? "사용가능" : "",
            krw: data?.asset.acceptableKrw
              ? numberWithCommas(data?.asset.acceptableKrw)
              : 0,
            gold: data?.asset.acceptableGold
              ? numberWithCommas(data?.asset.acceptableGold)
              : 0,
            silver: data?.asset.acceptableSilver
              ? numberWithCommas(data?.asset.acceptableSilver)
              : 0,
            point: data?.asset.acceptablePoint
              ? numberWithCommas(data?.asset.acceptablePoint)
              : 0,
          }
        : {},
      ...lockDetailData
    );
    return parseData;
  };
  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (props.field === "type") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "krw" ||
      props.field === "gold" ||
      props.field === "silver" ||
      props.field === "point"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}>
          {props.dataItem[props.field]}
        </td>
      );
    }
    return cell;
  };

  const fetchList = () => {
    dispatch(showLoading());
    APIS.searchMemberDetail(id.isArray === true ? id[0] : id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setData(data);
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        dispatch(showAlert({ message: e.response.data.message }));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };
  const userAssetInfo = () => {
    dispatch(showLoading());
    APIS.searchMemberDetailAsset(id.isArray === true ? id[0] : id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAssetData(reformData(data));
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((e) => {
        dispatch(showAlert({ message: e.response.data.message }));
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    if (id.isArray === true ? id[0] : id) {
      fetchList();
      userAssetInfo();
    }
  }, [id.isArray === true ? id[0] : id]);

  return (
    <Wrap style={{ height: "97%" }}>
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ height: "100%", fontSize: 16, fontFamily: "NotoSansKR-Bold" }}>
        <TabStripTab
          title="회원 상세정보"
          style={{
            width: "100%",
            height: "100%",
          }}>
          {/* <div style={{ height: "100%" }}> */}
          <div style={{ height: "100%" }}>
            <div>기본정보</div>
            <Table style={{ fontSize: 16, fontFamily: "NotoSansKR-Bold" }}>
              <colgroup>
                <col width="8%" />
                <col width="20%" />
                <col width="8%" />
                <col width="20%" />
              </colgroup>
              <tbody>
                <tr>
                  <Th>이름</Th>
                  <Td>{data?.name || "-"}</Td>
                  <Th>휴대폰</Th>
                  <Td>{data?.phone || "-"}</Td>
                </tr>
                <tr>
                  <Th>나이/성별</Th>
                  <Td>
                    {data?.age
                      ? `${data?.age}/${data?.gender ? "남자" : "여자"}`
                      : "-"}
                  </Td>
                  <Th>가입일</Th>
                  <Td>{formatTime(data?.createdAt)}</Td>
                </tr>
                <tr>
                  <Th>상태</Th>
                  <Td>{data?.status || "-"}</Td>
                  <Th>이용 대리점</Th>
                  <Td>{data?.usingShopName || "-"}</Td>
                </tr>
                <tr>
                  <Th>입금 계좌</Th>
                  <Td>
                    {data?.depositBankAccountNumber
                      ? `${data?.depositBankAccountNumber} 
                         (가상 ${data?.virtualAccountAccountNumber})
                        `
                      : "-"}
                  </Td>
                  <Th>출금 계좌</Th>
                  <Td>{data?.bankAccountNumber || "-"}</Td>
                </tr>
                <tr>
                  <Th>가입 경로</Th>
                  <Td>{data?.sns || "-"}</Td>
                  <Th>로그인 ID</Th>
                  <Td>{data?.loginId || "-"}</Td>
                </tr>
                <tr>
                  <Th>수정일</Th>
                  <Td>{formatTime(data?.updatedAt) || "-"}</Td>
                  <Th>재가입 가능일</Th>
                  <Td>{formatTime(data?.canReSignupDate) || "-"}</Td>
                </tr>
                <tr>
                  <Th>이메일</Th>
                  <Td>{data?.email || "-"}</Td>
                  <Th>주소</Th>
                  <Td>{data?.address || "-"}</Td>
                </tr>
              </tbody>
            </Table>
            <Grid
              style={{
                width: "100%",
                marginTop: 5,
              }}
              scrollable={"none"}
              data={assetData ? assetData : []}
              cellRender={cellRender}>
              <GridColumn field="type" title="자산현황" width={140} />
              <GridColumn field="krw" title="원화" />
              <GridColumn field="point" title="포인트" />
              <GridColumn field="gold" title="금" />
              <GridColumn field="silver" title="은" />
            </Grid>
          </div>
        </TabStripTab>
      </TabStrip>
    </Wrap>
  );
};

export default MemberManagementDetail;
