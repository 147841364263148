import React, { useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
  getSelectedStateFromKeyDown,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import styled from "styled-components";
import Button from "../../components/button/Button";
import DefaultLayout from "../../../src/components/layout/DefaultLayout";
import Flex from "../../components/layout/Flex";
import { hideLoading, showLoading } from "../../store/loadingReducer";
import { showAlert } from "../../store/alertReducer";
import * as APIS from "../../libs/apis";
import { useDispatch, useSelector } from "react-redux";
import {
  numberWithCommas,
  objToQueryStr,
  numFormat,
  formatTime,
  formatPhone,
} from "../../libs/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { RadioGroup, Checkbox } from "@progress/kendo-react-inputs";
import esMessages from "../../libs/es.json";
import { getter } from "@progress/kendo-react-common";
import {
  IntlProvider,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import GoodsWithdrawalDetail from "./GoodsWithdrawalDetail";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { createIndex } from "../../libs/utils";

const initialDataState = {
  skip: 0,
  take: 10,
};

const DATA_ITEM_KEY = "transId";
const idGetter = getter(DATA_ITEM_KEY);
loadMessages(esMessages, "es-KR");

const FiltersLabel = styled.label`
  font-family: NotoSansKR-Bold;
  font-size: 16px;
  margin-left: 10px;
  width: 100px;
  align-self: center;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const Wrap = styled.div`
  min-width: 1112px;
  max-width: 1740px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 1050px;

  .k-grid .k-grid-container {
    height: 50px;
  }
`;

const GoodsWithdrawal = () => {
  const dispatch = useDispatch();
  const authReducer = useSelector((s) => s.auth);

  const location = useLocation();
  const state = location.state?.goodsWithdrawal || {};
  const navigate = useNavigate();
  const [tab, setTab] = useState("signed");
  const page = initialDataState;
  const [currentGoldPrice, setCurrentGoldPrice] = useState("");
  const [currentSilverPrice, setCurrentSilverPrice] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [tradeList, setTradeList] = useState([]);
  const [tradeListTotal, setTradeListTotal] = useState({ grams: 0, amount: 0 });
  const [selectedState, setSelectedState] = useState({});

  const [summary, setSummary] = useState({});
  const [categories, setCategories] = useState([]);
  const [goldWeight, setGoldWeight] = useState(0);
  const [silverWeight, setSilverWeight] = useState(0);
  const [detailComponent, setDetailComponent] = useState(null);
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [searchReception, setSearchReception] = useState();
  const [statusType, setStatusType] = useState("");
  const [assetType, setAssetType] = useState("");
  const statusCheckedList = location.state?.goodsWithdrawal?.status || [];
  const shipmentStatusCheckedList =
    location.state?.goodsWithdrawal?.shipmentStatus || [];
  const checkWithdrawalData = [
    { label: "결제", value: "APPROVAL" },
    { label: "결제취소", value: "CANCEL" },
    { label: "인출완료", value: "TAKEOUT" },
    { label: "인출확정", value: "CONFIRM" },
  ];
  const checkShipmentData = [
    { label: "미출고", value: "NOT_SHIPPED" },
    { label: "제작중", value: "MAKE" },
    { label: "출고중", value: "SHIPMENT" },
    { label: "출고완료", value: "DELIVERY" },
  ];
  const sortOrderRadioData = [
    {
      label: "최근 순서로",
      value: "DESC",
    },
    {
      label: "오래된 순서로",
      value: "ASC",
    },
  ];

  const clearDetail = () => {
    setDetailComponent(null);
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
  };
  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    // const data = Object.values(newSelectedState);
    // let key = data;
    // let obj = {};
    // obj[key] = true;

    setSelectedState(newSelectedState);
  };
  const handleValueChange = (key, value, more) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        goodsWithdrawal: {
          ...location.state.goodsWithdrawal,
          [key]: value || "",
          ...more,
        },
      },
    });
  };

  const pageChange = (event) => {
    const pageIndex = event.page.skip / event.page.take;
    navigate(location.pathname, {
      replace: true,
      state: {
        goodsWithdrawal: {
          ...location.state.goodsWithdrawal,
          page: pageIndex,
          size: event.page.take,
        },
      },
    });
  };
  const initState = () => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        goodsWithdrawal: {
          page: page.skip,
          size: page.take,
          beginDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(new Date(), "YYYY-MM-DD"),
          userCondition: "",
          tradeType: "",
          assetType: "GOLD",
          sortType: "DESC",
          productName: "",
          dateType: "CREATED",
          status: ["APPROVAL", "TAKEOUT", "CONFIRM"],
        },
      },
    });
  };
  const searchTotalWeight = (param, type) => {
    dispatch(showLoading());
    APIS.getGoodsWithdrawalTotalWeight(param)
      .then((res) => {
        const { data, success, message } = res.data;
        if (success) {
          if (data.length > 0) {
            const goldTotal = data.find(
              (item) => item.assetType === "GOLD"
            )?.total;
            const silverTotal = data.find(
              (item) => item.assetType === "SILVER"
            )?.total;
            if (type) {
              type === "GOLD"
                ? (setGoldWeight(goldTotal), setSilverWeight(0))
                : (setSilverWeight(silverTotal), setGoldWeight(0));
            } else {
              setGoldWeight(goldTotal || 0);
              setSilverWeight(silverTotal || 0);
            }
          } else {
            setGoldWeight(0);
            setSilverWeight(0);
          }
        } else {
          dispatch(showAlert({ message: message }));
        }
      })
      .catch((err) => {
        setGoldWeight(0);
        setSilverWeight(0);
        dispatch(
          showAlert({
            title: "",
            message: err.response.data.message,
          })
        );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const searchSummary = () => {
    dispatch(showLoading());
    APIS.getOrderSummary()
      .then((res) => {
        try {
          const {
            data: { data },
          } = res;
          if (data) {
            let parseData = {};
            Object.keys(data).forEach((item) => {
              data[item].forEach((el) => {
                let type = el.goldOrSilver;
                let key = el.status;
                parseData[item] = {
                  ...parseData[item],
                };
                parseData[item][type] = {
                  ...parseData[item][type],
                  [key]: {
                    count: el.count,
                  },
                };
              });
            });
            setSummary(parseData);
          }
        } catch (err) {
          dispatch(
            showAlert({
              title: "",
              message: err.response.data.message,
            })
          );
        }
      })
      .finally(() => dispatch(hideLoading()));
  };

  const handleClickDetail = (props) => {
    if (
      authReducer?.role &&
      typeof authReducer?.role === "string" &&
      authReducer?.role.includes("SHOP")
    ) {
      const newData = withdrawalList?.map((item) => ({
        ...item,
        selected: item.transId === props.dataItem.transId,
      }));
      setWithdrawalList(newData);

      const transId = props.dataItem.transId;

      setDetailComponent(() => {
        return null;
      });

      setDetailComponent(() => {
        return (
          <GoodsWithdrawalDetail
            id={Object.keys(selectedState)}
            transId={transId}
            fetchList={fetchList}
            searchSummary={searchSummary}
          />
        );
      });
    }
  };

  const summaryFetchList = (statusType, type, searchReception) => {
    dispatch(showLoading());
    const param =
      statusType === "status"
        ? {
            page: state.page,
            size: state.size,
            goldOrSilver: type,
            status: searchReception,
          }
        : {
            page: state.page,
            size: state.size,
            goldOrSilver: type,
            shipmentStatus: searchReception,
          };
    const paramQuery = objToQueryStr(param);

    APIS.getOrderList(paramQuery)
      .then(({ data: { data } }) => {
        setWithdrawalList(
          data.content.map((item) => {
            return {
              ...item,
              // visitDate: `${item.visitDate}  (${item.visitTime})`,
            };
          })
        );
        setTotalCount(data.totalElements);
        searchTotalWeight(paramQuery, type);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  const fetchList = () => {
    dispatch(showLoading());
    const param = {
      ...state,
      page: state.page,
      size: state.size,
      sortType: state.sortType,
      dateType: state.dateType,
    };

    const paramQuery = objToQueryStr(param);

    APIS.getOrderList(paramQuery)
      .then(({ data: { data } }) => {
        setWithdrawalList(
          data.content.map((item) => {
            return {
              ...item,
              // visitDate: `${item.visitDate}  (${item.visitTime})`,
            };
          })
        );
        setTotalCount(data.totalElements);
        searchTotalWeight(paramQuery);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  // const cellRender = (cell, props) => {
  //   if (props.field === "name") {
  //     if (props.dataItem.name % 2 !== 0) {
  //       return <td rowSpan={2}>{props.dataItem[props.field].toString()}</td>;
  //     } else {
  //       return null;
  //     }
  //   }
  //   return cell;
  // };
  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "index" ||
      props.field === "qty" ||
      props.field === "categoryName"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}>
          {props.dataItem[props.field]}
        </td>
      );
    } else if (props.field === "no") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}>
            <div style={{ lineHeight: "40px" }}>{props.dataItem["no"]}</div>
            <br />
            <div>
              {formatTime(props.dataItem["createdAt"], "YYYY-MM-DD HH:mm") ||
                "-"}
            </div>
          </div>
        </td>
      );
    } else if (props.field === "name") {
      if (props.dataItem.name % 2 !== 0) {
        return (
          <td
            rowSpan={2}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
              [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}>
            {props.dataItem[props.field].toString()}
          </td>
        );
      } else {
        return null;
      }
    } else if (props.field === "userId") {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}>
          <div style={{ textAlign: "-webkit-center", lineHeight: "60px" }}>
            {props.dataItem[props.field]}
          </div>
        </td>
      );
    }

    return cell;
  };

  useEffect(() => {
    if (Object.keys(state).length === 0) {
      navigate(location.pathname, {
        replace: true,
        state: {
          ...location.state,
          goodsWithdrawal: {
            page: page.skip,
            size: page.take,
            beginDate: formatTime(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 14
              ),
              "YYYY-MM-DD"
            ),
            endDate: formatTime(new Date(), "YYYY-MM-DD"),
            sortType: "DESC",
            dateType: "CREATED",
            status: ["APPROVAL", "TAKEOUT", "CONFIRM"],
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      authReducer?.role &&
      typeof authReducer?.role === "string" &&
      authReducer?.role.includes("SHOP")
    ) {
      if (Object.keys(selectedState).length !== 0) {
        setDetailComponent(() => {
          return null;
        });
        setDetailComponent(() => {
          return (
            <GoodsWithdrawalDetail
              id={Object.keys(selectedState)}
              fetchList={fetchList}
              searchSummary={searchSummary}
            />
          );
        });
      }
    }
  }, [selectedState]);

  useEffect(() => {
    if (Object.keys(state).length !== 0) {
      if (!searchReception) {
        fetchList();
      } else {
        summaryFetchList(statusType, assetType, searchReception);
      }
    }
  }, [state.page, state.size, state.sortType]);

  useEffect(() => {
    // dispatch(showLoading());
    APIS.getGoodsCategoryList().then(({ data }) => {
      if (data.success) {
        var cates = data.data;
        var categorys = [];
        for (let i in cates) {
          categorys.push({ label: cates[i].kind, value: cates[i].id });
        }
        setCategories(categorys);
      } else {
        dispatch(
          showAlert({
            title: "",
            message: data.message,
          })
        );
      }
    });
    searchSummary();
  }, []);

  return (
    <DefaultLayout detail={detailComponent} clearDetail={clearDetail}>
      <Wrap>
        <Grid
          style={{
            width: "100%",
          }}
          scrollable={"none"}
          data={[
            {
              title: "금",
              approval:
                numberWithCommas(
                  summary?.["status"]?.["GOLD"]?.["APPROVAL"]?.count
                ) || "-",
              approvalStatusType: "APPROVAL",
              approvalAssetType: "GOLD",
              approvalType: "status",
              takeout:
                numberWithCommas(
                  summary?.["status"]?.["GOLD"]?.["TAKEOUT"]?.count
                ) || "-",
              takeoutStatusType: "TAKEOUT",
              takeoutAssetType: "GOLD",
              takeoutType: "status",
              confirm:
                numberWithCommas(
                  summary?.["status"]?.["GOLD"]?.["CONFIRM"]?.count
                ) || "-",
              confirmStatusType: "CONFIRM",
              confirmAssetType: "GOLD",
              confirmType: "status",
              cancel:
                numberWithCommas(
                  summary?.["status"]?.["GOLD"]?.["CANCEL"]?.count
                ) || "-",
              cancelStatusType: "CANCEL",
              cancelAssetType: "GOLD",
              cancelType: "status",
              notShipped:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["GOLD"]?.["NOT_SHIPPED"]?.count
                ) || "-",
              notShippedStatusType: "NOT_SHIPPED",
              notShippedAssetType: "GOLD",
              notShippedType: "shipmentStatus",
              make:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["GOLD"]?.["MAKE"]?.count
                ) || "-",
              makeStatusType: "MAKE",
              makeAssetType: "GOLD",
              makeType: "shipmentStatus",
              shipment:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["GOLD"]?.["SHIPMENT"]?.count
                ) || "-",
              shipmentStatusType: "SHIPMENT",
              shipmentAssetType: "GOLD",
              shipmentType: "shipmentStatus",
              delivery:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["GOLD"]?.["DELIVERY"]?.count
                ) || "-",
              deliveryStatusType: "DELIVERY",
              deliveryAssetType: "GOLD",
              deliveryType: "shipmentStatus",
            },
            {
              title: "은",
              approval:
                numberWithCommas(
                  summary?.["status"]?.["SILVER"]?.["APPROVAL"]?.count
                ) || "-",
              approvalStatusType: "APPROVAL",
              approvalAssetType: "SILVER",
              approvalType: "status",
              takeout:
                numberWithCommas(
                  summary?.["status"]?.["SILVER"]?.["TAKEOUT"]?.count
                ) || "-",
              takeoutStatusType: "TAKEOUT",
              takeoutAssetType: "SILVER",
              takeoutType: "status",
              confirm:
                numberWithCommas(
                  summary?.["status"]?.["SILVER"]?.["CONFIRM"]?.count
                ) || "-",
              confirmStatusType: "CONFIRM",
              confirmAssetType: "SILVER",
              confirmType: "status",
              cancel:
                numberWithCommas(
                  summary?.["status"]?.["SILVER"]?.["CANCEL"]?.count
                ) || "-",
              cancelStatusType: "CANCEL",
              cancelAssetType: "SILVER",
              cancelType: "status",
              notShipped:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["SILVER"]?.["NOT_SHIPPED"]
                    ?.count
                ) || "-",
              notShippedStatusType: "NOT_SHIPPED",
              notShippedAssetType: "SILVER",
              notShippedType: "shipmentStatus",
              make:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["SILVER"]?.["MAKE"]?.count
                ) || "-",
              makeStatusType: "MAKE",
              makeAssetType: "SILVER",
              makeType: "shipmentStatus",
              shipment:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["SILVER"]?.["SHIPMENT"]?.count
                ) || "-",
              shipmentStatusType: "SHIPMENT",
              shipmentAssetType: "SILVER",
              shipmentType: "shipmentStatus",
              delivery:
                numberWithCommas(
                  summary?.["shipmentStatus"]?.["SILVER"]?.["DELIVERY"]?.count
                ) || "-",
              deliveryStatusType: "DELIVERY",
              deliveryAssetType: "SILVER",
              deliveryType: "shipmentStatus",
            },
          ]}>
          <GridColumn
            field="title"
            title="구분"
            width="40px"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr>
                    <td>{dataItem.title || "-"}</td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            // field="approval"
            title="결제"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.approvalType);
                        setAssetType(dataItem.approvalAssetType);
                        setSearchReception(dataItem.approvalStatusType);
                        summaryFetchList(
                          dataItem.approvalType,
                          dataItem.approvalAssetType,
                          dataItem.approvalStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.approval) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="takeout"
            title="인출완료"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.takeoutType);
                        setAssetType(dataItem.takeoutAssetType);
                        setSearchReception(dataItem.takeoutStatusType);
                        summaryFetchList(
                          dataItem.takeoutType,
                          dataItem.takeoutAssetType,
                          dataItem.takeoutStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.takeout) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="confirm"
            title="인출확정"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.confirmType);
                        setAssetType(dataItem.confirmAssetType);
                        setSearchReception(dataItem.confirmStatusType);
                        summaryFetchList(
                          dataItem.confirmType,
                          dataItem.confirmAssetType,
                          dataItem.confirmStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.confirm) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="cancel"
            title="결제취소"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.cancelType);
                        setAssetType(dataItem.cancelAssetType);
                        setSearchReception(dataItem.cancelStatusType);
                        summaryFetchList(
                          dataItem.cancelType,
                          dataItem.cancelAssetType,
                          dataItem.cancelStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.cancel) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="notShipped"
            title="미출고"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.notShippedType);
                        setAssetType(dataItem.notShippedAssetType);
                        setSearchReception(dataItem.notShippedStatusType);
                        summaryFetchList(
                          dataItem.notShippedType,
                          dataItem.notShippedAssetType,
                          dataItem.notShippedStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.notShipped) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="make"
            title="제작중"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.makeType);
                        setAssetType(dataItem.makeAssetType);
                        setSearchReception(dataItem.makeStatusType);
                        summaryFetchList(
                          dataItem.makeType,
                          dataItem.makeAssetType,
                          dataItem.makeStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.make) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            title="출고중"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        color: "red",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.shipmentType);
                        setAssetType(dataItem.shipmentAssetType);
                        setSearchReception(dataItem.shipmentStatusType);
                        summaryFetchList(
                          dataItem.shipmentType,
                          dataItem.shipmentAssetType,
                          dataItem.shipmentStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.shipment) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
          <GridColumn
            field="delivery"
            title="출고완료"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td>
                  <tr style={{ float: "right" }}>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "blue",
                      }}
                      onClick={() => {
                        setStatusType(dataItem.deliveryType);
                        setAssetType(dataItem.deliveryAssetType);
                        setSearchReception(dataItem.deliveryStatusType);
                        summaryFetchList(
                          dataItem.deliveryType,
                          dataItem.deliveryAssetType,
                          dataItem.deliveryStatusType
                        );
                      }}>
                      {numberWithCommas(dataItem.delivery) || "-"}
                    </td>
                  </tr>
                </td>
              );
            }}
          />
        </Grid>
        <div
          style={{
            marginTop: 10,
            width: "100%",
          }}>
          <PanelBar style={{ border: "1px solid #9e9e9e" }}>
            <PanelBarItem expanded={true} title={"검색 조건"}>
              <div style={{ padding: "0.5%" }}>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      paddingLeft: 10,
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                      alignItems: "center",
                    }}>
                    <select
                      style={{ width: 90, height: 35 }}
                      value={state?.dateType ? state?.dateType : ""}
                      onChange={(e) => {
                        handleValueChange("dateType", e.target.value);
                      }}>
                      <option value={"CREATED"}>요청일</option>
                      <option value={"CONFIRM"}>확정일</option>
                      <option value={"SHIPMENT"}>출고일</option>
                      <option value={"VISITED"}>예약일</option>
                    </select>
                    &nbsp; &nbsp;
                    <Input
                      style={{ width: 160 }}
                      type="date"
                      value={state?.beginDate ? state.beginDate : ""}
                      onChange={(e) => {
                        handleValueChange("beginDate", e.target.value);
                      }}
                    />
                    &nbsp;~&nbsp;
                    <Input
                      style={{ width: 160 }}
                      type="date"
                      value={state?.endDate ? state?.endDate : ""}
                      onChange={(e) => {
                        handleValueChange("endDate", e.target.value);
                      }}
                    />
                  </Flex>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>회원 정보</FiltersLabel>
                    <Input
                      autoFocus={true}
                      style={{ width: 320 }}
                      type="text"
                      placeholder="고객명, 연락처, 고객번호"
                      value={state?.userSearch ? state?.userSearch : ""}
                      onChange={(e) => {
                        handleValueChange("userSearch", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                  {/* <Flex style={{ width: "50%" }}>
                  <FiltersLabel>현황상태</FiltersLabel>
                  <RadioGroup
                    //   data={statusRadioData}
                    layout={"horizontal"}
                    value={state?.status}
                    onChange={(e) => {
                      handleValueChange("status", e.value);
                    }}
                  />
                </Flex> */}
                  {/* <Flex style={{ width: "50%" }}>
                  <FiltersLabel>자산타입</FiltersLabel>
                  <RadioGroup
                    data={[
                      {
                        label: "금",
                        value: "GOLD",
                      },
                      {
                        label: "은",
                        value: "SILVER",
                      },
                    ]}
                    layout={"horizontal"}
                    value={state?.assetType}
                    onChange={(e) => {
                      handleValueChange("assetType", e.value);
                    }}
                  />
                </Flex> */}
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>인출상태</FiltersLabel>
                    {checkWithdrawalData.map((item, index) => {
                      const { value: checkValue, label } = item;
                      return (
                        <div
                          style={{
                            marginRight: 20,
                            fontFamily: "NotoSansKR-Bold",
                            fontSize: 16,
                          }}>
                          <Checkbox
                            key={index.toString()}
                            // style={{ marginRight: 5 }}
                            defaultChecked={state.status}
                            checked={statusCheckedList.includes(checkValue)}
                            onChange={(e) => {
                              if (e.target.value) {
                                handleValueChange("status", [
                                  ...statusCheckedList,
                                  checkValue,
                                ]);
                              } else {
                                handleValueChange(
                                  "status",
                                  statusCheckedList.filter(
                                    (e) => e !== checkValue
                                  )
                                );
                              }
                            }}
                            label={label}
                          />
                        </div>
                      );
                    })}
                  </Flex>
                  <Flex style={{ width: "50%" }}>
                    <FiltersLabel>출고상태</FiltersLabel>
                    {checkShipmentData.map((item, index) => {
                      const { value: checkValue, label } = item;
                      return (
                        <div
                          style={{
                            marginRight: 20,
                            fontFamily: "NotoSansKR-Bold",
                            fontSize: 16,
                          }}>
                          <Checkbox
                            key={index.toString()}
                            checked={shipmentStatusCheckedList.includes(
                              checkValue
                            )}
                            onChange={(e) => {
                              if (e.target.value) {
                                handleValueChange("shipmentStatus", [
                                  ...shipmentStatusCheckedList,
                                  checkValue,
                                ]);
                              } else {
                                handleValueChange(
                                  "shipmentStatus",
                                  shipmentStatusCheckedList.filter(
                                    (e) => e !== checkValue
                                  )
                                );
                              }
                            }}
                            label={label}
                          />
                        </div>
                      );
                    })}
                  </Flex>
                  {/* <Flex style={{ width: "50%" }}>
                  <FiltersLabel>기간</FiltersLabel>
                  <Flex>
                    <input
                      type="date"
                      value={state?.beginDate ? state.beginDate : ""}
                      onChange={(e) => {
                        handleValueChange("beginDate", e.target.value);
                      }}
                    />
                    ~
                    <input
                      type="date"
                      value={state?.endDate ? state?.endDate : ""}
                      onChange={(e) => {
                        handleValueChange("endDate", e.target.value);
                      }}
                    />
                  </Flex>
                </Flex> */}
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      // marginLeft: 10,
                      fontSize: 15,
                      fontFamily: "NotoSansKR-Bold",
                      flexDirection: "row",
                      display: "flex",
                      lineHeight: 2,
                      width: "50%",
                    }}>
                    <FiltersLabel>카테고리</FiltersLabel>
                    <select
                      style={{ width: 337 }}
                      value={state?.categoryId ? state?.categoryId : ""}
                      onChange={(e) => {
                        handleValueChange("categoryId", e.target.value);
                      }}>
                      <option value={""}>전체</option>;
                      {categories.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </Flex>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>상품검색</FiltersLabel>
                    <Input
                      style={{ width: 320 }}
                      type="text"
                      placeholder="상품명"
                      value={state?.productName ? state?.productName : ""}
                      onChange={(e) => {
                        handleValueChange("productName", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    marginTop: 10,
                  }}>
                  <Flex
                    style={{
                      display: "flex",
                      width: "50%",
                    }}>
                    <FiltersLabel>주문번호</FiltersLabel>
                    <Input
                      style={{ width: 337 }}
                      type="text"
                      placeholder="주문번호"
                      value={state?.no ? state?.no : ""}
                      onChange={(e) => {
                        handleValueChange("no", e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          fetchList();
                        }
                      }}
                    />
                    <input
                      id="fakeNumber"
                      name="fakeNumber"
                      autoComplete="new-password"
                      style={{
                        display: "absolute",
                        width: 0,
                        height: 0,
                        zIndex: -1,
                      }}
                    />
                  </Flex>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}>
                  <Button
                    className="buttons-container-button"
                    icon="search"
                    style={{ marginRight: 5 }}
                    onClick={fetchList}>
                    검색
                  </Button>
                  <Button
                    className="buttons-container-button"
                    icon="refresh"
                    onClick={initState}>
                    초기화
                  </Button>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 16,
            fontFamily: "NotoSansKR-Bold",
            alignItems: "center",
          }}>
          <div
            style={{
              display: "flex",
              fontSize: 16,
              fontFamily: "NotoSansKR-Bold",
            }}>
            <div
              style={{ display: "flex", marginRight: 10, fontWeight: "bold" }}>
              금 중량 합계 : {numFormat(goldWeight) || 0} g
            </div>
            <div style={{ display: "flex", fontWeight: "bold" }}>
              은 중량 합계 : {numFormat(silverWeight) || 0} g
            </div>
          </div>
          <RadioGroup
            data={sortOrderRadioData}
            layout={"horizontal"}
            value={state?.sortType}
            onChange={(e) => {
              handleValueChange("sortType", e.value);
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            overflowY: "hidden",
          }}>
          <LocalizationProvider language="es-KR" style={{ height: "100%" }}>
            <IntlProvider locale="es" style={{ height: "100%" }}>
              <Grid
                style={{
                  marginTop: 5,
                  cursor: "pointer",
                  fontSize: "16px",
                  height: "100%",
                  // maxHeight: "670px",
                }}
                data={
                  withdrawalList?.length !== 0
                    ? withdrawalList.map((item, index) => ({
                        ...item,
                        // index:
                        //   totalCount -
                        //   parseInt(state.page) * parseInt(state.size) -
                        //   index,
                        index: createIndex(state, index),
                        selected: selectedState[idGetter(item)],
                      }))
                    : []
                }
                // data={
                //   withdrawalList?.length !== 0
                //     ? withdrawalList
                //         ?.slice(state.page, state.size + state.page)
                //         ?.map((item, index) => ({
                //           ...item,
                //           index:
                //             totalCount -
                //             parseInt(state.page) * parseInt(state.size) -
                //             index,
                //           selected: selectedState[idGetter(item)],
                //         }))
                //     : []
                // }
                skip={state.page * state.size}
                take={state.size}
                total={totalCount}
                resizable={true}
                pageable={{
                  pageSizes: [10, 30, 50, 100],
                  previousNext: true,
                  info: true,
                }}
                selectable={{
                  enabled: true,
                }}
                selectedField="selected"
                onSelectionChange={onSelectionChange}
                onKeyDown={onKeyDown}
                scrollable
                navigatable={true}
                dataItemKey={DATA_ITEM_KEY}
                onPageChange={pageChange}
                onRowClick={handleClickDetail}
                cellRender={cellRender}>
                <GridColumn
                  field="index"
                  title="번호"
                  width={80}
                  // cell={(props) => {
                  //   const { dataItem } = props;
                  //   return (
                  //     <td style={{ textAlign: "-webkit-center" }}>
                  //       <tr>
                  //         <td>{dataItem.index}</td>
                  //       </tr>
                  //     </td>
                  //   );
                  // }}
                />
                <GridColumn field="no" title="주문번호">
                  <GridColumn
                    title="주문일시"
                    field="createdAt"
                    width={350}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {dataItem.no || "-"}
                            </div>
                            <br />
                            <div>
                              {formatTime(
                                dataItem.createdAt,
                                "YYYY-MM-DD HH:mm"
                              ) || "-"}
                            </div>
                          </div>

                          {/* <tr>
                          <td>{dataItem.no || "-"}</td>
                        </tr>
                        <tr>
                          <td>
                            {formatTime(
                              dataItem.createdAt,
                              "YYYY-MM-DD HH:mm"
                            ) || "-"}
                          </td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn
                  field="userId"
                  title="회원번호"
                  width={100}
                  // cell={(props) => {
                  //   const { dataItem } = props;
                  //   const navigationAttributes = useTableKeyboardNavigation(
                  //     props.id
                  //   );
                  //   return (
                  //     <td
                  //       colSpan={props.colSpan}
                  //       role={"gridcell"}
                  //       aria-colindex={props.ariaColumnIndex}
                  //       aria-selected={props.isSelected}
                  //       {...{
                  //         [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                  //       }}
                  //       {...navigationAttributes}
                  //     >
                  //       <div style={{ float: "right", lineHeight: "60px" }}>
                  //         {dataItem.userId || "-"}
                  //       </div>
                  //     </td>
                  //   );
                  // }}
                />
                <GridColumn field="userName" title="회원명">
                  <GridColumn
                    field="phone"
                    title="연락처"
                    width={90}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            textAlign: "center",
                            width: "100% !important",
                            height: "100% !important",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {dataItem.userName || "-"}
                            </div>
                            <br />
                            <div>{formatPhone(dataItem.phone) || "-"}</div>
                          </div>
                          {/* <tr>
                          <td>{dataItem.userName || "-"}</td>
                        </tr>
                        <tr>
                          <td>{formatPhone(dataItem.phone) || "-"}</td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn field="name" title="상품명">
                  <GridColumn
                    field="optionValue"
                    title="선택 옵션"
                    width={220}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                            textAlign: "center",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {dataItem.name || "-"}
                            </div>
                            <br />
                            <div>{dataItem.optionValue || "-"}</div>
                          </div>
                          {/* <tr>
                          <td>{dataItem.name || "-"}</td>
                        </tr>
                        <tr>
                          <td>{dataItem.optionValue || "-"}</td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn field="qty" title="수량" width={100} />
                <GridColumn field="totalGram" title="결제중량">
                  <GridColumn
                    field="totalFee"
                    title="결제수수료"
                    width={120}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                            textAlign: "right",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {`${numberWithCommas(dataItem.totalGram)}g` ||
                                "-"}
                            </div>
                            <br />
                            <div>
                              {numberWithCommas(dataItem.totalFee) || "-"}
                            </div>
                          </div>
                          {/* <tr>
                          <td>
                            {`${numberWithCommas(dataItem.totalGram)}g` ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {numberWithCommas(dataItem.totalFee) || "-"}
                          </td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn field="delivery" title="수령방법">
                  <GridColumn
                    field="outShopName"
                    title="수령 대리점"
                    width={120}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                            textAlign: "center",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {dataItem.delivery || "-"}
                            </div>
                            <br />
                            <div>{dataItem.outShopName || "-"}</div>
                          </div>
                          {/* <tr>
                          <td>{dataItem.delivery || "-"}</td>
                        </tr>
                        <tr>
                          <td>{dataItem.outShopName || "-"}</td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn field="statusTxt" title="인출 상태">
                  <GridColumn
                    field="confirmDate"
                    title="인출 확정일"
                    width={180}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                            textAlign: "center",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {dataItem.status
                                .replace("APPROVAL", "결제")
                                .replace("CANCEL", "취소")
                                .replace("TAKEOUT", "인출완료")
                                .replace("CONFIRM", "인출확정") || "-"}
                            </div>
                            <br />
                            <div>
                              {formatTime(
                                dataItem.confirmDate,
                                "YYYY-MM-DD HH:mm:SS"
                              ) || "-"}
                            </div>
                          </div>
                          {/* <tr>
                          <td>
                            {dataItem.status
                              .replace("APPROVAL", "결제")
                              .replace("CANCEL", "취소")
                              .replace("TAKEOUT", "인출완료")
                              .replace("CONFIRM", "인출확정") || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {formatTime(
                              dataItem.confirmDate,
                              "YYYY-MM-DD HH:mm:SS"
                            ) || "-"}
                          </td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn
                  title="방문예약일"
                  width={120}
                  // field="visitDate"
                  cell={(props) => {
                    const { dataItem } = props;
                    const navigationAttributes = useTableKeyboardNavigation(
                      props.id
                    );
                    return (
                      <td
                        style={{
                          width: "100% !important",
                          height: "100% !important",
                          textAlign: "center",
                        }}
                        colSpan={props.colSpan}
                        role={"gridcell"}
                        aria-colindex={props.ariaColumnIndex}
                        aria-selected={props.isSelected}
                        {...{
                          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                        }}
                        {...navigationAttributes}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                          }}>
                          <div style={{ lineHeight: "40px" }}>
                            {dataItem.visitDate || "-"}
                          </div>
                          <br />
                          <div>{dataItem.visitTime || "-"}</div>
                        </div>
                      </td>
                    );
                  }}
                />
                <GridColumn field="shipmentTxt" title="출고 상태">
                  <GridColumn
                    field="shipmentDate"
                    title="출고일"
                    width={180}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                            textAlign: "center",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {dataItem.shipmentStatus
                                .replace("NOT_SHIPPED", "미출고")
                                .replace("MAKE", "제작중")
                                .replace("SHIPMENT", "출고중")
                                .replace("DELIVERY", "출고완료") || "-"}
                            </div>
                            <br />
                            <div>
                              {formatTime(
                                dataItem.shipmentDate,
                                "YYYY-MM-DD HH:mm:SS"
                              ) || "-"}
                            </div>
                          </div>
                          {/* <tr>
                          <td>
                            {dataItem.shipmentStatus
                              .replace("NOT_SHIPPED", "미출고")
                              .replace("MAKE", "제작중")
                              .replace("SHIPMENT", "출고중")
                              .replace("DELIVERY", "출고완료") || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {formatTime(
                              dataItem.shipmentDate,
                              "YYYY-MM-DD HH:mm:SS"
                            ) || "-"}
                          </td>
                        </tr> */}
                        </td>
                      );
                    }}
                  />
                </GridColumn>
                <GridColumn field="categoryName" title="카테고리" width={120} />
                <GridColumn field="gram" title="단위중량">
                  <GridColumn
                    field="fee"
                    title="단위 수수료"
                    width={120}
                    cell={(props) => {
                      const { dataItem } = props;
                      const navigationAttributes = useTableKeyboardNavigation(
                        props.id
                      );
                      return (
                        <td
                          style={{
                            width: "100% !important",
                            height: "100% !important",
                            textAlign: "right",
                          }}
                          colSpan={props.colSpan}
                          role={"gridcell"}
                          aria-colindex={props.ariaColumnIndex}
                          aria-selected={props.isSelected}
                          {...{
                            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                          }}
                          {...navigationAttributes}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                            }}>
                            <div style={{ lineHeight: "40px" }}>
                              {`${numberWithCommas(dataItem.gram)}g` || "-"}
                            </div>
                            <br />
                            <div>{numberWithCommas(dataItem.fee) || "-"}</div>
                          </div>
                        </td>
                      );
                    }}
                  />
                </GridColumn>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </Wrap>
    </DefaultLayout>
  );
};

export default GoodsWithdrawal;
