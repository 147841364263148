import React from "react";
import Viewer from "react-viewer";
import { useDispatch, useSelector } from "react-redux";
import { hideImageViewer } from "../../store/imageViewerReducer";
import consts from "../../libs/consts";

export default function ImageViewer() {
  const dispatch = useDispatch();
  const { open, images, activeIndex } = useSelector((s) => s.imageViewer);
  const handleClose = () => {
    dispatch(hideImageViewer());
  };
  let copyImages = images ? [...images] : [];
  let resultImages = [];
  copyImages.map((item) => {
    return item.src
      ? resultImages.push({
          ...item,
          src: consts.s3BaseUrl + "/" + item?.src,
          downloadUrl: consts.s3BaseUrl + "/" + item?.src,
        })
      : "";
  });

  return (
    <>
      <Viewer
        visible={open}
        onClose={handleClose}
        onMaskClick={handleClose}
        zoomSpeed={0.2}
        // images={[{ src: src, downloadUrl: src }]}
        images={images ? resultImages : []}
        activeIndex={activeIndex}
        downloadable
      />
    </>
  );
}
